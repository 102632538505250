<template>
  <v-btn
    color="#F4F4F5"
    elevation="0"
    height="40px"
    style="border: 1px solid #edf3fb"
    variant="flat">
    <v-icon color="primary">mdi-filter-variant</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ToggleFilterButton',
}
</script>

<style lang="scss" scoped></style>
