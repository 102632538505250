export default {
  task: {
    typeId: 'Тип',
    topic: 'Тема',
    description: 'Опис',
    attachment: 'Вкладені файли',
    authorId: 'Автор',
    executorId: 'Виконавець',
    deadline: 'Термін виконання',
    contractor: 'Контрагент',
  },
  correspondence: {
    related_correspondences: "Пов'язана кореспонденція",
    subject: 'Тема',
    description: 'Опис',
    isOrigin: 'Оригінал документів',
    typeId: 'Тип кореспонденції',
    documentTypeId: 'Тип документу',
    documentPriorityTypeId: 'Приорітет',
    documentDeliveryTypeId: 'Тип доставлення/Тип відправлення',
    deliveryCost: 'Ціна доставки',
    ttn: 'ТТН (Товарно-транспортна накладна)',
    registrationNumber: 'Номер',
    registrationDate: 'Дата реєстрації',
    departmentId: 'Департамент',
    contractorId: 'Кореспондент',
    contractor: 'Кореспондент',
    addressId: 'Адреса',
    documents: 'Документи',
    isSecretFinancial: 'Таємниця фінансових послуг',
  },
}
