<template>
  <div>
    <b-server-autocomplete
      v-model="delegateTask.executorId"
      :error-messages="getErrorMessages(v$.executorId)"
      :url="urlAllUsers()"
      item-title="name"
      item-value="id"
      label="Новий виконавець"
      placeholder="Введіть мін. 3 символи"
      @blur="v$.executorId.$touch()">
    </b-server-autocomplete>
  </div>
</template>

<script lang="ts">
import { reactive } from 'vue'
import { getErrorMessages } from 'best-modules/utils'
import { urlAllUsers, urlTaskDelegate } from '@/utils/urls'
import { axios, required, useVuelidate } from '@/plugins'
import { setSnackbar } from 'best-modules/plugins'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  name: 'DelegateTask',
  components: {
    BServerAutocomplete,
  },
  props: {
    dialog: { type: Object },
  },
  methods: { urlAllUsers, getErrorMessages },
  setup(props) {
    const delegateTask = reactive({
      executorId: null,
    })

    const v$ = useVuelidate(
      {
        executorId: { required },
      },
      delegateTask
    )

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return axios
          .post(urlTaskDelegate(props.dialog.dialogData.taskId), {
            executorId: delegateTask?.executorId,
          })
          .then(res => {
            setSnackbar({ text: 'Завдання делеговане', color: 'success' })
            return res.data
          })
      }
    }

    return {
      delegateTask,
      submit,
      v$,
    }
  },
}
</script>

<style lang="scss" scoped></style>
