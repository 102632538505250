<template>
  <div :key="componentKey">
    <DocumentList
      v-if="
        [
          'absence-document-signature-all-list',
          'absence-document-agreement-me-list',
        ].includes(route.name)
      " />
    <router-view v-else></router-view>
  </div>
</template>

<script lang="ts">
import { useRoute } from 'vue-router'
import { computed, provide, shallowRef, watch } from 'vue'
import { documentActionKey } from '@/pages/documents/injectionKeys'
import DocumentList from '@/pages/documents/DocumentList.vue'

export default {
  name: 'DocumentIndex',
  components: { DocumentList },
  setup() {
    const route = useRoute()
    const componentKey = shallowRef(0)

    const documentAction = computed<'signature' | 'agreement'>(() => {
      if (
        route.matched.some(r =>
          [
            'document-signature-list',
            'absence-document-signature-all-list',
          ].includes(r?.name)
        )
      ) {
        return 'signature'
      }
      if (
        route.matched.some(r =>
          [
            'document-agreement-list',
            'absence-document-agreement-me-list',
          ].includes(r?.name)
        )
      ) {
        return 'agreement'
      }

      throw new Error('DocumentIndex - invalid route')
    })

    watch(
      () => route.params.absenceId,
      () => {
        componentKey.value++
      }
    )

    provide(documentActionKey, documentAction)

    return {
      route,
      componentKey,
    }
  },
}
</script>

<style lang="scss" scoped></style>
