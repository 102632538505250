<template>
  <div>
    <b-section-loader :loading="$loading.isLoading('getCorrespondence')">
      <div class="cursor-default">
        <v-row>
          <v-col
            :class="!$vuetify.display.smAndUp ? null : 'pr-0'"
            cols="12"
            md="8"
            sm="12">
            <v-card class="shadow-1" rounded="lg">
              <v-card-title class="mt-1 mb-2">
                <div
                  :class="
                    $vuetify.display.smAndUp
                      ? 'align-center'
                      : 'flex-column align-start'
                  "
                  class="d-flex justify-space-between">
                  <h4>Загальна інформація</h4>
                  <v-btn-group
                    color="surface"
                    density="compact"
                    divided
                    rounded="xs"
                    variant="flat">
                    <v-btn
                      class="mr-3"
                      color="primary"
                      rounded="lg"
                      @click="createCorrespondenceTask">
                      Створити доручення
                      <v-icon class="ml-2" color="white">mdi-plus</v-icon>
                    </v-btn>
                    <v-tooltip text="Завдання">
                      <template #activator="{ props }">
                        <v-btn
                          size="x-small"
                          v-bind="props"
                          @click="openDialogHandler('tasks')">
                          <v-icon>mdi-file-tree</v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                    <v-tooltip
                      :text="
                        correspondence.typeId === 2
                          ? 'Параметри відправлення'
                          : 'Додаткова інформація'
                      ">
                      <template #activator="{ props }">
                        <v-btn
                          size="x-small"
                          v-bind="props"
                          @click="openDialogHandler('delivery')">
                          <v-icon>mdi-truck-delivery-outline</v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                    <v-tooltip text="Історія">
                      <template #activator="{ props }">
                        <v-btn
                          size="x-small"
                          v-bind="props"
                          @click="
                            () =>
                              setSnackbar({
                                text: 'Функціонал в розробці',
                                color: 'warning',
                              })
                          ">
                          <v-icon>mdi-history</v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                  </v-btn-group>
                </div>
              </v-card-title>
              <v-card-text class="gap-2 d-flex flex-column">
                <div>
                  <span class="label">Кореспондент</span>
                  <b-autocomplete
                    v-model="correspondence.contractor"
                    v-model:search="search"
                    :hide-no-data="search.length < 3"
                    :item-title="c => `${c.shortName || c.name} (${c.code})`"
                    :items="
                      search.length < 3
                        ? [correspondence.contractor].filter(c => {
                            return correspondence.contractorId ? c : false
                          })
                        : $directory.get(
                            'contractorList',
                            correspondence.contractor
                          )
                    "
                    :loading="$loading.isLoading('contractorList')"
                    hide-details
                    placeholder="Введіть назву контрагента або ЄДРПОУ"
                    return-object
                    @update:model-value="
                      event => {
                        console.log(event)
                        correspondence.contractorId = event?.id || null
                        $event && getAddressList()
                      }
                    "
                    @update:search="
                      $event?.length === 3 && $directory.fill('contractorList')
                    ">
                    <template #append-item>
                      <div
                        class="d-flex align-center append-item-create"
                        @click="createContractor">
                        <v-btn
                          density="comfortable"
                          icon="mdi-plus"
                          variant="plain">
                        </v-btn>
                        <span>Створити нового</span>
                      </div>
                    </template>
                    <template v-if="correspondence.contractorId" #append>
                      <v-btn
                        color="primary"
                        density="comfortable"
                        icon
                        rounded="lg"
                        size="small"
                        @click="
                          () => {
                            correspondence.contractorId = null
                            correspondence.contractor = null
                          }
                        ">
                        <v-icon color="white">mdi-delete-forever</v-icon>
                      </v-btn>
                    </template>
                  </b-autocomplete>
                </div>
                <div v-if="correspondence.contractorId">
                  <span class="label">Адреса</span>
                  <b-select
                    v-model="correspondence.addressId"
                    :item-title="a => `${a.address} (${a.type.name})`"
                    :items="addressList"
                    :loading="$loading.isLoading('getContractorSingle')"
                    hide-details
                    placeholder="Оберіть адресу"
                    @focus="getAddressList">
                    <template #append>
                      <v-tooltip text="Створити адресу">
                        <template #activator="{ props }">
                          <v-btn
                            color="primary"
                            density="comfortable"
                            icon
                            rounded="lg"
                            size="small"
                            v-bind="props"
                            @click="createAddress">
                            <v-icon color="white">mdi-plus</v-icon>
                          </v-btn>
                        </template>
                      </v-tooltip>
                    </template>
                  </b-select>
                </div>
                <div>
                  <span class="label">Тема</span>
                  <b-input
                    v-model="correspondence.subject"
                    :error-messages="getErrorMessages(v$.subject)"
                    hide-details
                    placeholder="Введіть текст"
                    @blur="v$.subject.$touch()">
                  </b-input>
                </div>
                <div>
                  <span class="label">Опис</span>
                  <b-textarea
                    v-model="correspondence.description"
                    hide-details
                    placeholder="Введіть текст">
                  </b-textarea>
                </div>
                <div>
                  <span class="label">
                    {{
                      correspondence.typeId === 2
                        ? 'Готував підрозділ'
                        : 'Підрозділ'
                    }}
                  </span>
                  <b-select
                    v-model="correspondence.departmentId"
                    :error-messages="getErrorMessages(v$.departmentId)"
                    :items="
                      $directory.get(
                        'departmentList',
                        correspondence.department
                      )
                    "
                    :loading="$loading.isLoading('departmentList')"
                    hide-details
                    placeholder="Оберіть департамент"
                    @blur="v$.departmentId.$touch()"
                    @focus="$directory.fill('departmentList')"></b-select>
                </div>
                <div>
                  <v-divider class="py-1"></v-divider>
                  <files-uploader
                    v-model="correspondence.documents"
                    :name-folder="
                      correspondence?.created_at?.substring(6, 10) || '0000'
                    "
                    :prefix-folder="
                      correspondence?.created_at?.substring(3, 5) || '00'
                    "
                    file-list
                    label="Документи"
                    main-folder="Корреспонденция"
                    noCard>
                    <template #append-action>
                      <v-checkbox
                        v-model="correspondence.isOrigin"
                        hide-details>
                        <template #label>
                          <span class="label mb-0">Оригінал</span>
                        </template>
                      </v-checkbox>
                    </template>
                  </files-uploader>
                </div>
              </v-card-text>
            </v-card>
            <PerformerUsers
              :correspondence="correspondence"
              :typeId="correspondence.typeId" />
            <RelatedCorrespondence :correspondence="correspondence" />
            <Comments
              :comments="correspondence.comments"
              :disabled="false"
              :entityId="correspondence.id"
              :entityTypeId="4"
              :hide-delete="false" />
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <CorrespondenceSidebar
              v-model:correspondence="correspondence"
              :v$="v$">
              <template #action-buttons>
                <SubmitButtons
                  v-if="correspondence.$hasChanges"
                  :disabled="!correspondence.$hasChanges"
                  :submit="updateCorrespondence"
                  class="mt-3 d-flex justify-end"
                  compact
                  submitLabel="Оновити"
                  @cancel="correspondence.$reset()" />
              </template>
            </CorrespondenceSidebar>
          </v-col>
        </v-row>
      </div>
    </b-section-loader>
  </div>
</template>

<script>
import {
  urlCorrespondenceSingle,
  urlCorrespondenceUpdate,
} from '@/utils/urls.js'
import axios from '@/plugins/axios/index.js'
import { computed, ref } from 'vue'
import {
  addressList,
  correspondence,
  getAddressList,
  v$,
} from './correspondence.ts'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { useRoute } from 'vue-router'
import { getErrorMessages } from 'best-modules/utils'
import { navigateToItem, validateAlert } from '@/utils/helpers'
import { useBreadcrumb } from '@/plugins'
import { openDialog } from '@/dialog'
import { pushDirectoryItem } from '@/directory'
import { BTextarea } from 'best-modules/components/index'
import CorrespondenceSidebar from '@/pages/correspondence/single/components/CorrespondenceSidebar.vue'
import SubmitButtons from '@/components/SubmitButtons.vue'
import { BSectionLoader } from 'best-modules/components'
import PerformerUsers from '@/pages/correspondence/single/components/PerformerUsers.vue'
import Comments from '@/components//comments/Comments.vue'
import RelatedCorrespondence from './components/RelatedCorrespondence.vue'

export default {
  name: 'CorrespondenceSingle',
  components: {
    RelatedCorrespondence,
    Comments,
    PerformerUsers,
    SubmitButtons,
    CorrespondenceSidebar,
    BTextarea,
    BSectionLoader,
  },
  methods: { setSnackbar, getAddressList, navigateToItem, getErrorMessages },
  setup() {
    const route = useRoute()
    const breadcrumb = useBreadcrumb()
    const search = ref('')

    const correspondenceId = computed(() => route.params.id)

    const createCorrespondenceTask = () => {
      openDialog({
        name: 'CreateTask',
        dialogData: {
          taskData: {
            typeId: 3,
            entityTypeId: 4,
            entityId: correspondenceId,
            name: correspondence.subject || correspondence.registrationNumber,
          },
        },
        params: {
          cb(res) {
            console.log(res)
          },
        },
      })
    }
    const taskData = computed(() => {
      return {
        name: correspondence.subject || correspondence.registrationNumber,
        id: correspondence.id,
        document: correspondence.documents,
        contractorId: correspondence.contractor?.id,
      }
    })

    const getCorrespondence = () => {
      return handleAsync('getCorrespondence', () => {
        return axios
          .get(urlCorrespondenceSingle(correspondenceId.value))
          .then(res => {
            correspondence.$set(res.data)
            res.data.address && addressList.value.push(res.data.address)
            breadcrumb.set([
              {
                title: correspondence.registrationNumber,
                index: 1,
              },
            ])
          })
      })
    }

    const updateCorrespondence = () => {
      v$.value.$touch()
      if (!v$.value.$invalid) {
        return axios
          .post(urlCorrespondenceUpdate(correspondenceId.value), {
            ...correspondence.$object,
          })
          .then(res => {
            correspondence.$set(res.data)
          })
      } else {
        validateAlert(v$.value, 'correspondence')
      }
    }

    const createContractor = () => {
      openDialog({
        name: 'Contractor',
        params: {
          cb: contractor => {
            pushDirectoryItem('contractorList', contractor)
          },
        },
      })
    }

    const createAddress = () => {
      openDialog({
        name: 'ContractorContact',
        action: 'create',
        dialogData: {
          type: 'address',
          contractorId: correspondence.contractorId,
          title: 'Адреса',
        },
        params: {
          cb: address => {
            addressList.value.push(address)
          },
        },
      })
    }

    const openDialogHandler = action => {
      openDialog({
        name:
          action === 'tasks'
            ? 'CorrespondenceTasks'
            : 'CorrespondenceDeliveryInfo',
        dialogData: {
          correspondence: correspondence,
          title:
            correspondence.typeId === 2
              ? 'Параметри відправлення'
              : 'Додаткова інформація',
        },
        params: {
          cb: data => {
            correspondence.deliveryCost = data?.deliveryCost
            correspondence.ttn = data?.ttn
          },
          hideActionButtons: action === 'tasks',
          submitLabel: 'Зберегти',
        },
      })
    }

    getCorrespondence()

    return {
      route,
      taskData,
      updateCorrespondence,
      createCorrespondenceTask,
      openDialogHandler,
      createContractor,
      createAddress,
      search,
      correspondence,
      addressList,
      v$,
    }
  },
}
</script>
