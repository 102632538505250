<template>
  <div>
    <span class="label">Ціна доставки</span>
    <b-input-number
      v-model="correspondenceData.deliveryCost"
      currency="uah"
      placeholder="Введіть суму"
      rankSpacing>
    </b-input-number>

    <span class="label">ТТН</span>
    <b-input
      v-model="correspondenceData.ttn"
      :mask="'#'.repeat(20)"
      placeholder="Введіть ТТН">
    </b-input>
  </div>
</template>

<script lang="ts">
import { BInputNumber } from 'best-modules/components/index'
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'DeliveryInfo',
  components: { BInputNumber },
  props: {
    dialog: { type: Object },
  },
  setup(props) {
    const correspondenceData = reactive({
      deliveryCost: null,
      ttn: null,
    })
    Object.assign(
      correspondenceData,
      props.dialog.dialogData?.correspondence.$object
    )

    const rules = {
      deliveryCost: {},
      ttn: {},
    }

    const v$ = useVuelidate(rules, correspondenceData)

    const submit = () => {
      return Promise.resolve(correspondenceData)
    }

    return {
      v$,
      submit,
      correspondenceData,
    }
  },
}
</script>

<style lang="scss" scoped></style>
