<template>
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3827 1.75139C10.2717 1.66482 10.1351 1.6178 9.99434 1.6178C9.85362 1.6178 9.71694 1.66482 9.606 1.75139L0.625 8.75489L1.40169 9.7372L2.5 8.88083V16.6178C2.50068 16.9491 2.63259 17.2666 2.86686 17.5009C3.10114 17.7352 3.41869 17.8671 3.75 17.8678H16.25C16.5813 17.8672 16.8989 17.7353 17.1332 17.501C17.3675 17.2667 17.4994 16.9491 17.5 16.6178V8.88651L18.5983 9.74276L19.375 8.76039L10.3827 1.75139ZM11.25 16.6178H8.75V11.6178H11.25V16.6178ZM12.5 16.6178V11.6178C12.4996 11.2864 12.3678 10.9686 12.1335 10.7343C11.8991 10.5 11.5814 10.3681 11.25 10.3678H8.75C8.41858 10.3681 8.10083 10.4999 7.86648 10.7342C7.63213 10.9686 7.50033 11.2863 7.5 11.6178V16.6178H3.75V7.9062L10 3.03745L16.25 7.91276V16.6178H12.5Z"
      fill="#18181B" />
  </svg>
</template>

<style lang="scss" scoped>

</style>
