<script lang="ts">
export default {
  name: 'Doc',
}
</script>

<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 17 20"
    width="17"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19572 0.536621C0.519877 0.536621 0 1.10849 0 1.73234V18.7843C0 19.4602 0.571865 19.98 1.19572 19.98H15.8043C16.4801 19.98 17 19.4602 17 18.7843V5.99534L11.5413 0.536621H1.19572Z"
      fill="#2B7CD3" />
    <path
      d="M16.9999 5.99534H12.7369C12.061 5.99534 11.5411 5.47546 11.5411 4.79962V0.536621L16.9999 5.99534Z"
      fill="#185ABD" />
    <path
      d="M4 8.1157H5.21384C5.67373 8.1157 6.03191 8.28099 6.28839 8.61157C6.54486 8.93664 6.6731 9.39394 6.6731 9.98347V12.0083C6.6731 12.6033 6.54486 13.0689 6.28839 13.405C6.03633 13.7355 5.68257 13.9008 5.22711 13.9008H4V8.1157ZM5.22711 12.9339C5.43052 12.9339 5.58529 12.854 5.69142 12.6942C5.79754 12.5344 5.85061 12.303 5.85061 12V9.98347C5.85061 9.68595 5.79754 9.46281 5.69142 9.31405C5.58529 9.15978 5.43052 9.08264 5.22711 9.08264H4.81586V12.9339H5.22711Z"
      fill="white" />
    <path
      d="M8.41525 14C7.95536 14 7.59497 13.8264 7.33407 13.4793C7.07759 13.1322 6.94935 12.6529 6.94935 12.0413V9.97521C6.94935 9.36364 7.07759 8.8843 7.33407 8.53719C7.59497 8.19008 7.95536 8.01653 8.41525 8.01653C8.87071 8.01653 9.22669 8.19008 9.48316 8.53719C9.74406 8.8843 9.87451 9.36364 9.87451 9.97521V12.0413C9.87451 12.6529 9.74406 13.1322 9.48316 13.4793C9.22669 13.8264 8.87071 14 8.41525 14ZM8.41525 13.0165C8.61866 13.0165 8.77122 12.9339 8.87293 12.7686C8.97905 12.5978 9.03212 12.3554 9.03212 12.0413V9.97521C9.03212 9.65565 8.97905 9.41322 8.87293 9.24793C8.77122 9.08264 8.61866 9 8.41525 9C8.21184 9 8.05707 9.0854 7.95094 9.2562C7.84481 9.42149 7.79175 9.66116 7.79175 9.97521V12.0413C7.79175 12.3554 7.84481 12.5978 7.95094 12.7686C8.05707 12.9339 8.21184 13.0165 8.41525 13.0165Z"
      fill="white" />
    <path
      d="M11.5474 14C11.1096 14 10.7669 13.843 10.5193 13.5289C10.276 13.2094 10.1544 12.7548 10.1544 12.1653V9.85124C10.1544 9.2562 10.276 8.7989 10.5193 8.47934C10.7669 8.15978 11.1096 8 11.5474 8C11.9896 8 12.3323 8.15978 12.5755 8.47934C12.8231 8.79339 12.958 9.24518 12.9801 9.83471H12.1642C12.151 9.53719 12.0935 9.32231 11.9918 9.19008C11.8901 9.05234 11.7397 8.98347 11.5407 8.98347C11.3683 8.98347 11.2334 9.0551 11.1361 9.19835C11.0433 9.3416 10.9968 9.55923 10.9968 9.85124V12.1653C10.9968 12.4518 11.0433 12.6667 11.1361 12.8099C11.2334 12.9477 11.3705 13.0165 11.5474 13.0165C11.7508 13.0165 11.9033 12.9504 12.005 12.8182C12.1068 12.6804 12.1665 12.4683 12.1841 12.1818H13C12.9823 12.7658 12.8474 13.2149 12.5954 13.5289C12.3478 13.843 11.9984 14 11.5474 14Z"
      fill="white" />
  </svg>
</template>

<style lang="scss" scoped></style>
