<template>
  <svg
    fill="none"
    height="22"
    viewBox="0 0 18 22"
    width="18"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.31307 0.828125C0.601824 0.828125 0.0547112 1.42995 0.0547112 2.08648L0 20.0318C0 20.743 0.547112 21.3448 1.25836 21.3448H16.6869C17.3982 21.3448 17.9453 20.7977 17.9453 20.0865L18 6.62752L12.2006 0.828125H1.31307Z"
      fill="#A3D2F1" />
    <path
      d="M17.9454 6.57281H13.459C12.7478 6.57281 12.2007 6.02569 12.2007 5.31445V0.828125L17.9454 6.57281Z"
      fill="#79BEEB" />
    <path
      d="M5.19862 14.1228C5.19862 14.3964 5.25333 14.5058 5.52689 14.5058C5.80045 14.5058 5.85516 14.3417 5.85516 14.1228V8.48755H6.73054V14.1228C6.73054 14.8341 6.45698 15.3265 5.52689 15.3265C4.5968 15.3265 4.32324 14.8341 4.32324 14.1228V13.1927H5.19862V14.1228Z"
      fill="white" />
    <path
      d="M7.77002 8.48755H9.08309C10.0132 8.48755 10.232 8.92524 10.232 9.63648V11.2231C10.232 11.9344 9.90376 12.372 9.08309 12.372H8.6454V15.217H7.77002V8.48755ZM9.02838 11.5514C9.24722 11.5514 9.35665 11.442 9.35665 11.2231V9.69119C9.35665 9.47235 9.24722 9.36293 9.02838 9.36293H8.6454V11.5514H9.02838Z"
      fill="white" />
    <path
      d="M12.2569 11.3873H13.5153V14.0681C13.5153 14.7794 13.2417 15.2718 12.2569 15.2718C11.2721 15.2718 10.9985 14.7794 10.9985 14.0681V9.58182C10.9985 8.87058 11.2721 8.37817 12.2569 8.37817C13.2417 8.37817 13.5153 8.87058 13.5153 9.58182V10.4025H12.5852V9.58182C12.5852 9.36298 12.4757 9.19884 12.2569 9.19884C11.9833 9.19884 11.9286 9.36298 11.9286 9.58182V14.0681C11.9286 14.3417 12.038 14.5058 12.2569 14.5058C12.5304 14.5058 12.5852 14.3417 12.5852 14.0681V12.208H12.2569V11.3873Z"
      fill="white" />
  </svg>
</template>

<style lang="scss" scoped></style>
