5
<template>
  <li class="file" @click="openDocument(file.path)">
    <div class="d-flex flex-column">
      <div class="d-flex align-center">
        <div class="file__icon">
          <Skeleton v-if="file.loading" :rows="1" height="2rem" width="2rem" />
          <component :is="getIconName(file.path)" v-else />
        </div>
        <div class="file__text">
          <div v-if="file.loading" class="file__info">
            <Skeleton :rows="1" class="mb-1" height="1.05rem" width="6rem" />
            <Skeleton :rows="1" height="1.05rem" width="3rem" />
          </div>
          <div v-else class="file__info">
            <!-- TODO -->
            <span class="file__name">{{ file.name }}</span>
          </div>
        </div>
      </div>
      <div>
        <Skeleton v-if="file.loading" :rows="1" height="1.05rem" width="3rem" />
        <span v-else class="file__size">{{ formatSize(file.size) }}</span>
      </div>
    </div>
    <div v-if="!disabled" class="file__actions">
      <v-menu v-model="menu" :close-on-content-click="false" location="end">
        <template #activator="{ props }">
          <v-btn
            icon
            size="x-small"
            style="background: transparent"
            v-bind="props"
            variant="text">
            <v-icon color="grey">mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-card min-width="150">
          <div class="d-flex flex-column align-start">
            <div v-for="(btn, idx) in actions" :key="idx" class="pa-1 w-100">
              <v-btn
                :loading="$loading.isLoading(btn.loader || 'empty')"
                class="actionBtn"
                variant="text"
                @click="btn.command">
                <v-icon class="mr-1">{{ btn.icon }}</v-icon>
                {{ btn.label }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-menu>
    </div>
  </li>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue'
import { download, openDocument } from '@/utils/helpers.js'
import { ref } from 'vue'
import { handleAsync } from 'best-modules/plugins'
import Xlsx from '@/assets/images/icons/file-format/xlsx.vue'
import Pdf from '@/assets/images/icons/file-format/pdf.vue'
import Png from '@/assets/images/icons/file-format/png.vue'
import Jpg from '@/assets/images/icons/file-format/jpg.vue'
import Doc from '@/assets/images/icons/file-format/doc.vue'
import Zip from '@/assets/images/icons/file-format/zip.vue'
import Rar from '@/assets/images/icons/file-format/rar.vue'
import Svg from '@/assets/images/icons/file-format/svg.vue'

export default {
  components: { Skeleton },
  emits: ['remove-file'],
  props: {
    file: { type: Object },
    disabled: { type: Boolean },
  },
  setup(props, { emit }) {
    const menu = ref(false)
    const formatSize = bytes => {
      if (bytes === 0) {
        return '0 B'
      }

      const k = 1000,
        dm = 2,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }

    const getIconName = path => {
      const ext = path.split('.').at(-1)
      switch (true) {
        case ['doc', 'docx'].includes(ext):
          return Doc
        case ['xls', 'xlsx'].includes(ext):
          return Xlsx
        case ['zip'].includes(ext):
          return Zip
        case ['rar'].includes(ext):
          return Rar
        case ['pdf'].includes(ext):
          return Pdf
        case ['png'].includes(ext):
          return Png
        case ['svg'].includes(ext):
          return Svg
        case ['jpg', 'jpeg'].includes(ext):
          return Jpg
      }
    }

    const actions = [
      {
        loader: `download_${props.file.path}`,
        label: 'Завантажити',
        command: () => {
          return handleAsync(`download_${props.file.path}`, () => {
            return download(props.file.path, props.file.name)
          })
        },
        icon: 'mdi-file-download-outline',
        disabled: props.file.loading,
      },
      {
        loader: `open_${props.file.path}`,
        label: 'Переглянути',
        command: () => {
          return handleAsync(`open_${props.file.path}`, () => {
            return openDocument(props.file.path)
          })
        },
        icon: 'mdi-eye',
        disabled: props.file.loading,
      },
      {
        label: 'Видалити',
        command: () => emit('remove-file', props.file),
        icon: 'mdi-delete-forever',
        loader: `deleteFile__${props.file.path}`,
        disabled: props.disabled || props.file.loading,
      },
    ]

    return {
      formatSize,
      getIconName,
      download,
      openDocument,
      actions,
      menu,
    }
  },
}
</script>

<style lang="scss" scoped>
.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  padding-right: 0;
  margin-top: 3px;
  transition: 0.3s background;
  cursor: default;

  &:hover {
    background: #f4f4f5;
  }

  &__icon {
    margin-right: 0.5rem;
  }

  &__text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__info > * {
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #52525b;
  }

  &__info > *:last-child {
    margin-bottom: 0;
  }

  &__size {
    color: #b5b7c0;
    font-size: 10px;
    font-weight: 600;
  }

  &__actions {
    display: flex;
    align-items: center;

    & .p-button {
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
}

.actionBtn {
  background: transparent !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #323232 !important;
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  letter-spacing: -0.26px;
}
</style>
