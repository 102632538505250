<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { useRoute } from 'vue-router'
import { computed, provide } from 'vue'
import { documentActionKey } from './injectionKeys'

export default {
  name: 'DocumentIndex',
  setup() {
    const route = useRoute()

    const documentAction = computed<'signature' | 'agreement'>(() => {
      if (
        route.matched.some(r =>
          [
            'document-signature-list',
            'absence-document-signature-all-list',
          ].includes(r?.name)
        )
      ) {
        return 'signature'
      }
      if (
        route.matched.some(r =>
          [
            'document-agreement-list',
            'absence-document-agreement-me-list',
          ].includes(r?.name)
        )
      ) {
        return 'agreement'
      }

      throw new Error('DocumentIndex - invalid route')
    })

    provide(documentActionKey, documentAction)
  },
}
</script>

<style lang="scss" scoped></style>
