<template>
  <div class="mb-2">
    <div>
      <div
        class="d-flex align-center justify-space-between mb-2"
        style="cursor: pointer"
        @click="setExpanded(!isExpanded)">
        <span class="info-block__label">{{ label }}</span>
        <v-btn-group density="compact" divided rounded="lg" variant="outlined">
          <v-btn size="x-small">
            <v-icon
              :icon="
                isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
              "></v-icon>
          </v-btn>
        </v-btn-group>
      </div>
      <div v-show="isExpanded" style="transition: 0.3s all">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    label: { type: String, default: '' },
    expanded: { type: Boolean, default: false },
  },
  setup(props) {
    const isExpanded = ref(props.expanded)
    const setExpanded = val => {
      isExpanded.value = val
    }

    return {
      isExpanded,
      setExpanded,
    }
  },
}
</script>

<style lang="scss" scoped>
//.label {
//  color: #8e92bc;
//  font-size: 14px;
//  font-weight: 600;
//}
</style>
