<template>
  <v-card class="shadow-1" rounded="lg">
    <v-card-title class="mt-1 mb-2 pa-1">
      <div class="d-flex align-center justify-space-between">
        <h4>Коментарі</h4>
        <div>
          <v-btn
            :disabled="disabled"
            color="primary"
            density="comfortable"
            icon
            rounded="lg"
            size="small"
            @click="openCommentsDialog">
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-title>
    <v-card-text class="pa-0">
      <div v-if="localComments.length" class="d-flex flex-column gap-2">
        <TaskCommentItem
          v-for="item in visibleComments"
          :key="item.id"
          :comment="item"
          :disabled="disabled"
          :hideDelete="hideDelete"
          show-documents
          @delete="deleteComment">
        </TaskCommentItem>
        <div
          v-if="!showAll && localComments.length > 3"
          class="mt-2 d-flex justify-end">
          <v-btn color="secondary" size="small" @click="showAllComments">
            Переглянути всі
          </v-btn>
        </div>
      </div>
      <ContentNotFound v-else />
    </v-card-text>
  </v-card>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { openDialog } from '@/dialog/index.ts'
import TaskCommentItem from '@/pages/tasks/components/comments/TaskCommentItem.vue'
import ContentNotFound from '@/components/ContentNotFound.vue'
import { axios } from '@/plugins'
import { urlCommentDelete } from '@/utils/urls'

export default {
  name: 'TaskComments',
  components: { ContentNotFound, TaskCommentItem },
  props: {
    comments: { type: [Array, Object], default: () => [] },
    entityId: { type: Number, required: true },
    entityTypeId: {
      type: Number,
      required: true,
      validator: val => [1, 2, 3, 4].includes(val),
    },
    disabled: { type: Boolean },
    hideDelete: { type: Boolean, default: false },
  },
  setup(props) {
    const localComments = ref(
      Array.isArray(props.comments) ? props.comments : []
    )
    const showAll = ref(false)

    const store = useStore()
    const user = computed(() => store.state.user.userData)

    const visibleComments = computed(() => {
      return showAll.value
        ? localComments.value
        : localComments.value.slice(0, 3)
    })

    const showAllComments = () => {
      showAll.value = true
    }

    const deleteComment = async commentId => {
      return axios
        .delete(urlCommentDelete(commentId))
        .then(() => {
          localComments.value = localComments.value.filter(
            c => c.id !== commentId
          )
        })
        .finally(() => {})
    }

    const openCommentsDialog = () => {
      openDialog({
        name: 'Comments',
        dialogData: {
          commentDocuments: true,
          comments: Array.from(localComments.value).reverse(),
          entityId: props.entityId,
          entityTypeId: props.entityTypeId,
          hideDialogComments: true,
          updateComments: comments => (localComments.value = comments),
        },
        params: {
          hideActionButtons: true,
        },
      })
    }
    return {
      deleteComment,
      openCommentsDialog,
      localComments,
      user,
      visibleComments,
      showAllComments,
      showAll,
    }
  },
}
</script>

<style lang="scss" scoped></style>
