<template>
  <div>
    <b-server-autocomplete
      v-model="requestData.relatedId"
      :error-messages="getErrorMessages(v$.relatedId)"
      :label="`Пов'язана кореспонденція`"
      :url="urlCorrespondenceList()"
      hide-details
      item-title="registrationNumber"
      placeholder="Оберіть зі списку"
      @blur="v$.relatedId.$touch()"></b-server-autocomplete>
  </div>
</template>

<script>
import { BServerAutocomplete } from 'best-modules/components'
import {
  urlCorrespondenceList,
  urlCorrespondenceRelated,
} from '@/utils/urls.js'
import { axios } from '@/plugins'
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { getErrorMessages } from 'best-modules/utils'

export default {
  name: 'RelatedCorrespondence',
  components: { BServerAutocomplete },
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages, urlCorrespondenceList },
  setup(props) {
    const requestData = reactive({
      relatedId: null,
    })
    const rules = {
      relatedId: { required },
    }

    const v$ = useVuelidate(rules, requestData)

    const submit = () => {
      return axios.post(
        urlCorrespondenceRelated(props.dialog.dialogData.correspondenceId),
        requestData
      )
    }

    return { requestData, v$, submit }
  },
}
</script>
