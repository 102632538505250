<template>
  <div class="w-100 cursor-default">
    <div class="item">
      <div class="d-flex align-start">
        <div v-if="!hidePhoto" class="mr-3">
          <BaseImage
            v-if="comment?.user?.photo"
            :src="comment?.user?.photo"
            rounded
            style="cursor: pointer"
            width="32"></BaseImage>
          <v-avatar v-else class="cursor-pointer" color="primary">
            <v-icon icon="mdi-account-circle"></v-icon>
          </v-avatar>
        </div>
        <div class="item_block">
          <div class="item_block__header">
            <span class="mr-2">{{
              getUserName(comment?.user, { initials: true })
            }}</span>
            <span>{{ formatDate(comment?.created_at, 'response') }}</span>
          </div>
          <span
            v-for="(row, idx) in comment.comment.split('\n')"
            :key="idx"
            class="item_block__comment">
            {{ row }} <br />
          </span>
          <div
            v-if="
              showDocuments && comment.documents && comment.documents.length
            "
            class="mt-2 d-flex align-center gap-1">
            <strong>Файли:</strong>
            <div
              v-for="(file, index) in comment.documents || []"
              :key="file.id"
              class="d-flex align-center gap-1 cursor-pointer"
              @click="openDocument(file.path)">
              <component :is="getIconName(file.path)" style="height: 15px" />
              <span class="text-blue font-weight-bold item_block__document">{{
                file.name
              }}</span>
              <span class="text-grey">/ {{ formatSize(file.size) }}</span>
              <v-divider
                v-if="index < comment.documents.length - 1"
                class="mx-2"
                vertical>
              </v-divider>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!hideDelete">
        <b-action-menu
          :actions="[
            {
              title: 'Видалити',
              action: () => $emit('delete', comment.id),
              icon: 'mdi-delete-forever',
              disabled: !isAuthor || disabled,
            },
          ]"
          :btn-props="{ color: 'grey' }"
          icon="mdi-dots-horizontal"></b-action-menu>
      </div>
    </div>
    <div class="d-flex justify-end mt-2">
      <slot name="showAllComments"></slot>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/plugins/index.js'
import { computed, inject } from 'vue'
import { getUserName, openDocument } from '@/utils/helpers.js'
import { formatDate } from '@/plugins/axios/helpers.js'
import { BActionMenu } from 'best-modules/components/index'
import Doc from '@/assets/images/icons/file-format/doc.vue'
import Xlsx from '@/assets/images/icons/file-format/xlsx.vue'
import Zip from '@/assets/images/icons/file-format/zip.vue'
import Rar from '@/assets/images/icons/file-format/rar.vue'
import Pdf from '@/assets/images/icons/file-format/pdf.vue'
import Png from '@/assets/images/icons/file-format/png.vue'
import Svg from '@/assets/images/icons/file-format/svg.vue'
import Jpg from '@/assets/images/icons/file-format/jpg.vue'

export default {
  name: 'TaskCommentItem',
  components: {
    BActionMenu,
  },
  props: {
    comment: { type: Object, required: true },
    loading: { type: [Number, Boolean] },
    hideDelete: { type: Boolean },
    hidePhoto: { type: Boolean },
    disabled: { type: Boolean },
    showDocuments: { type: Boolean, default: false },
  },
  emits: ['delete'],
  methods: { openDocument, formatDate, getUserName },
  setup(props) {
    const store = useStore()
    const loadingState = inject('loadingState', {})

    const userId = computed(() => store.state.user.userData?.id)
    const isAuthor = computed(() => {
      return props.comment.userId === userId.value
    })

    const formatSize = bytes => {
      if (bytes === 0) {
        return '0 B'
      }

      const k = 1000,
        dm = 2,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }

    const getIconName = path => {
      const ext = path.split('.').at(-1)
      switch (true) {
        case ['doc', 'docx'].includes(ext):
          return Doc
        case ['xls', 'xlsx'].includes(ext):
          return Xlsx
        case ['zip'].includes(ext):
          return Zip
        case ['rar'].includes(ext):
          return Rar
        case ['pdf'].includes(ext):
          return Pdf
        case ['png'].includes(ext):
          return Png
        case ['svg'].includes(ext):
          return Svg
        case ['jpg', 'jpeg'].includes(ext):
          return Jpg
      }
    }

    return { loadingState, isAuthor, formatSize, getIconName }
  },
}
</script>

<style lang="scss">
.item {
  display: flex;
  align-items: start;
  justify-content: space-between;

  &_block {
    background: #f4f4f5;
    border-radius: 24px;
    padding: 12px;
    transition: 0.3s;

    &:hover {
      background: #eaeaea;
    }

    &__header {
      display: flex;
      align-items: center;
      font-size: 10px;
      color: #8e92bc;
    }

    &__comment {
      font-size: 12px;
      display: block;
      margin-top: 4px;
    }

    &__document:hover {
      text-decoration: underline;
    }
  }
}
</style>
