<script lang="ts" setup>

</script>

<template>
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.75 17.3552L12.1313 15.7365L11.25 16.6177L13.75 19.1177L18.75 14.1177L17.8687 13.2365L13.75 17.3552Z"
          fill="#18181B" />
    <path
      d="M9.375 17.8677H5V2.86771H10V6.61771C10.001 6.94893 10.133 7.26632 10.3672 7.50053C10.6014 7.73475 10.9188 7.86675 11.25 7.86771H15V11.6177H16.25V6.61771C16.2522 6.53556 16.2366 6.45391 16.2043 6.37838C16.1719 6.30285 16.1235 6.23524 16.0625 6.18021L11.6875 1.80521C11.6325 1.74416 11.5649 1.69578 11.4893 1.66341C11.4138 1.63104 11.3321 1.61545 11.25 1.61771H5C4.66877 1.61866 4.35139 1.75067 4.11717 1.98488C3.88296 2.21909 3.75096 2.53648 3.75 2.86771V17.8677C3.75096 18.1989 3.88296 18.5163 4.11717 18.7505C4.35139 18.9847 4.66877 19.1167 5 19.1177H9.375V17.8677ZM11.25 3.11771L14.75 6.61771H11.25V3.11771Z"
      fill="#18181B" />
  </svg>
</template>

<style lang="scss" scoped>

</style>
