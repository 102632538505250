<template>
  <v-card elevation="1">
    <v-card-text>
      <b-server-table
        :key="renderKey"
        v-model="selectedDocuments"
        :headers="documentListHeaders"
        :request-func="getDocuments"
        show-filters
        show-search
        show-select
        @click:row="navigateToDocument($event.id)">
        <template v-if="!isAbsence" #action-button>
          <v-fade-transition>
            <v-btn
              v-if="selectedDocuments.length"
              class="mr-2"
              rounded="lg"
              @click.stop="signDocuments">
              Підписати документи
            </v-btn>
          </v-fade-transition>
          <v-btn color="primary" rounded="lg" @click.stop="createDocument">
            <v-icon class="mr-1">mdi-plus-circle</v-icon>
            Створити документ
          </v-btn>
        </template>
        <template #filters>
          <b-server-filter-item
            v-slot="{ props }"
            directory="documentTypes"
            query-key="typeId">
            <b-select
              clearable
              label="Тип документа"
              placeholder="Оберіть зі списку"
              v-bind="props"></b-select>
          </b-server-filter-item>
        </template>
        <template #item.created_at="{ item }">
          <span style="white-space: nowrap">
            {{ isCurrentDate(item.created_at) }}
          </span>
        </template>
        <template #item.type="{ item }">
          {{ item.type?.name }}
        </template>
        <template #item.name="{ item }">
          <v-tooltip :text="item?.name">
            <template #activator="{ props }">
              <div class="text-truncate" style="width: 250px" v-bind="props">
                <span>
                  {{ item.name }}
                </span>
              </div>
            </template>
          </v-tooltip>
        </template>
        <template #item.number="{ item }">
          <span style="white-space: nowrap">
            {{ item.number }}
          </span>
        </template>
        <template #item.status="{ item }">
          <span style="white-space: nowrap">
            {{ item.status?.name }}
          </span>
        </template>
        <template #item.author="{ item }">
          <span style="white-space: nowrap">
            {{ getUserName(item.author, { initials: true }) }}
          </span>
        </template>
        <template #[`item.contractors`]="{ item }">
          <v-menu>
            <template #activator="{ props }">
              <span v-if="item.contractors.length" class="link" v-bind="props">
                Показати
              </span>
              <span v-else>Відсутні</span>
            </template>
            <v-card>
              <v-card-text>
                <div
                  v-for="(contractor, idx) in item.contractors"
                  :key="contractor.id"
                  :class="{ 'mb-2': item.contractors.length - 1 > idx }"
                  class="link"
                  @click.stop="
                    navigateToItem(
                      'single-contractor',
                      contractor?.contractorId
                    )
                  ">
                  {{ contractor.contractor.name }}
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
        <template #bottom></template>
      </b-server-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { getUserName, isCurrentDate, navigateToItem } from '@/utils/helpers.js'
import { useDocumentList } from '@/pages/documents/documentList.js'
import { inject } from 'vue'
import { documentActionKey } from './injectionKeys'
import { BServerFilterItem, BServerTable } from 'best-modules/components/index'
import { useRoute } from '@/plugins'

export default {
  components: { BServerTable, BServerFilterItem },
  methods: { isCurrentDate, getUserName },
  setup() {
    const documentAction = inject(documentActionKey)
    const route = useRoute()
    const isAbsence = [
      'absence-document-agreement-me-list',
      'absence-document-signature-all-list',
    ].includes(route?.name as string)

    const navigateToDocument = (id: number) => {
      navigateToItem(
        isAbsence
          ? `absence-document-${documentAction.value}-single`
          : `document-${documentAction.value}-single`,
        id
      )
    }

    return {
      ...useDocumentList(),
      navigateToItem,
      navigateToDocument,
      isAbsence,
    }
  },
}
</script>

<style lang="scss" scoped></style>
