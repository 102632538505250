<template>
  <div>
    <v-card class="h-100 shadow-1" rounded="lg">
      <v-card-title class="mt-1 mb-2" style="height: 45px">
        <h4>Юридична інформація</h4>
      </v-card-title>
      <v-card-text class="d-flex flex-column gap-2">
        <b-select
          v-model="contractorData.typeId"
          :error-messages="getErrorMessages(v$.typeId)"
          :items="$directory.get('contractorTypes', contractorData.type)"
          :loading="$loading.isLoading('contractorTypes')"
          hide-details
          label="Тип"
          placeholder="Оберіть тип"
          @blur="v$.typeId.$touch()"
          @focus="$directory.fill('contractorTypes')"></b-select>
        <slot v-if="$slots['action-buttons']" name="action-buttons"></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { getErrorMessages } from 'best-modules/utils'
import { computed } from 'vue'

export default {
  name: 'ContractorSidebar',
  emits: ['update:contractor'],
  props: {
    contractor: { type: Object, required: true },
    v$: { type: Object },
  },
  methods: { getErrorMessages },
  setup(props, { emit }) {
    const contractorData = computed({
      get() {
        return props.contractor
      },
      set(v) {
        emit('update:contractor', v)
      },
    })

    return { contractorData }
  },
}
</script>

<style lang="scss" scoped></style>
