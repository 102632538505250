<template>
  <div>
    <div class="how-work">
      <div class="how-work-container">
        <h3>Як це працює</h3>
        <span>
          Це може бути ключ, отриманий у будь-якому АЦСК України, наприклад,
          той, що ви використовуєте для підписання податкових накладних.
          Зазвичай, це файли з розширенням:
          <b>dat (key-6.dat), zs2, sk, jks, pk8, pfx</b>
        </span>
      </div>
    </div>
    <div class="d-flex align-center justify-space-between gap-1 mt-5">
      <v-btn variant="outlined" color="primary" @click="keyType = 'file'">
        Файловий ключ
        <v-icon class="ml-2">mdi-arrow-right-thin</v-icon>
      </v-btn>
      <v-btn variant="outlined" color="primary" @click="keyType = 'media'">
        Апаратний ключ
        <v-icon class="ml-2">mdi-arrow-right-thin</v-icon>
      </v-btn>
      <v-btn
        variant="outlined"
        color="primary"
        :disabled="dialog.dialogData.documents?.length > 1"
        @click="keyType = 'cloud'">
        Хмарний / Апаратний ключ
        <v-icon class="ml-2" color="primary">mdi-arrow-right-thin</v-icon>
      </v-btn>
    </div>
    <div class="mt-10">
      <span>
        Якщо ви не маєте ключа, зверніться до АЦСК. Повний перелік АСЦК - за
      </span>
      <a
        class="link d-inline text-decoration-underline"
        href="https://czo.gov.ua/ca-registry"
        target="_blank">
        посиланням.
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { KeyType } from './types'
import { computed, inject, PropType } from 'vue'
import { dialogKey } from './injectionKeys'

export default {
  name: 'KeyTypeSelect',
  emits: ['update:model-value'],
  props: {
    modelValue: {
      type: String as PropType<KeyType>,
    },
  },
  setup(props, { emit }) {
    const keyType = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:model-value', val)
      },
    })

    const dialog = inject(dialogKey)

    return { keyType, dialog }
  },
}
</script>
