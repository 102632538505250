<template>
  <div>
    <b-section-loader :loading="$loading.isLoading(['taskTypes'])">
      <v-row class="align-center">
        <v-col cols="12" md="1">
          <v-btn color="primary" icon readonly rounded="circle" variant="tonal">
            <time-icon />
          </v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <span class="label">Тип</span>
          <b-select
            :items="$directory.get('taskTypes', task.type)"
            :modelValue="task.typeId"
            :readonly="true"
            hide-details
            placeholder="Оберіть зі списку" />
        </v-col>
        <v-col cols="12" md="3">
          <span class="label">Термін виконання</span>
          <BaseDatePicker
            v-model="task.deadline"
            :error-messages="getErrorMessages(v$.deadline)"
            :loading="$loading.isLoading('updateDeadline')"
            :readonly="disabled || $loading.isLoading('updateDeadline')"
            hide-details
            time-picker
            @blur="v$.deadline.$touch()" />
        </v-col>
        <v-col
          v-if="!taskType.familiarization"
          class="d-flex align-center"
          cols="12"
          md="5">
          <div class="d-flex align-center gap-2">
            <v-switch
              v-model="task.isHighImportance"
              :readonly="disabled"
              class="mt-5"
              color="primary"
              hide-details
              label="Важлива задача"
              single>
            </v-switch>
            <v-switch
              v-model="task.isCEO"
              :readonly="disabled"
              class="mt-5"
              color="primary"
              hide-details
              label="Задача від ГД"
              single>
            </v-switch>
          </div>
        </v-col>
      </v-row>
      <v-row class="align-start">
        <v-col cols="12" md="1">
          <v-btn color="primary" icon readonly rounded="circle" variant="tonal">
            <rules-icon />
          </v-btn>
        </v-col>
        <v-col class="d-flex flex-column gap-2" cols="12" md="11">
          <div>
            <span class="label">Тема</span>
            <b-input
              v-model="task.topic"
              :error-messages="getErrorMessages(v$.topic)"
              :readonly="task.status && task.status.id !== 1"
              hide-details
              placeholder="Вкажіть тему задачі"
              @blur="v$.topic.$touch()" />
          </div>
          <div>
            <span class="label">Опис</span>
            <BaseTextarea
              v-model="task.description"
              :error-messages="getErrorMessages(v$.description)"
              :readonly="disabled"
              hide-details
              placeholder="Додайте опис задачі"
              @blur="v$.description.$touch()" />
          </div>
        </v-col>
      </v-row>
      <v-row class="align-start">
        <v-col cols="12" md="1">
          <v-btn color="primary" icon readonly rounded="circle" variant="tonal">
            <user-icon />
          </v-btn>
        </v-col>
        <v-col v-if="!taskType.familiarization" cols="12" md="3">
          <change-person
            :invalid="v$.executorId.$error"
            :person="task.executor"
            always-edit
            @update-person="executorDialog"
            @create-person="executorDialog">
            <template #label>
              <div class="d-flex align-center mb-1">
                <span class="label mr-2 mb-0">Виконавець</span>
                <v-tooltip
                  text="Співробітник, який виконує завдання. Якщо під час створення завдання додати різних виконавців, то для кожного буде створено своє окреме завдання, при цьому копіюється завдання і постановнику">
                  <template #activator="{ props }">
                    <v-btn
                      color="white"
                      density="compact"
                      icon
                      rounded="circle"
                      size="x-small">
                      <v-icon size="small" v-bind="props">
                        mdi-information-variant
                      </v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </div>
            </template>
          </change-person>
        </v-col>
        <v-divider class="mx-2" vertical></v-divider>
        <v-col cols="12" md="3">
          <change-person
            :person="task.observers"
            always-edit
            multiple
            @update-person="observerUsersDialog"
            @create-person="observerUsersDialog">
            <template #label>
              <div class="d-flex align-center mb-1">
                <span class="label mr-2 mb-0">Наглядач</span>
                <v-tooltip
                  text="Співробітник, який стежить за перебігом завдання та може залишати коментарі">
                  <template #activator="{ props }">
                    <v-btn
                      color="white"
                      density="compact"
                      icon
                      rounded="circle"
                      size="x-small">
                      <v-icon size="small" v-bind="props">
                        mdi-information-variant
                      </v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </div>
            </template>
          </change-person>
        </v-col>
        <v-divider class="mx-2" vertical></v-divider>
        <v-col cols="12" md="3">
          <change-person
            :person="task.controlUsers"
            always-edit
            multiple
            @update-person="controlUsersDialog"
            @create-person="controlUsersDialog">
            <template #label>
              <div class="d-flex align-center mb-1">
                <span class="label mr-2 mb-0">Контролюючий</span>
                <v-tooltip
                  text="Співробітник, який контролює завдання виконання завадання">
                  <template #activator="{ props }">
                    <v-btn
                      color="white"
                      density="compact"
                      icon
                      rounded="circle"
                      size="x-small">
                      <v-icon size="small" v-bind="props">
                        mdi-information-variant
                      </v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </div>
            </template>
          </change-person>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="11">
          <v-btn-group
            v-if="!showContractor"
            density="compact"
            rounded="lg"
            variant="outlined">
            <v-btn
              color="primary"
              density="comfortable"
              rounded="lg"
              size="x-small"
              @click="showContractor = true">
              <v-icon class="mr-1" color="primary">mdi-plus</v-icon>
              Контрагент
            </v-btn>
          </v-btn-group>
          <div v-if="showContractor">
            <span class="label">Контрагент</span>
            <b-server-autocomplete
              v-model="task.contractorId"
              :default-item="task.contractor"
              :readonly="disabled"
              :url="urlContractorsList()"
              hide-details
              item-value="id"
              placeholder="Введіть мін. 3 символи">
              <template #append>
                <v-btn
                  icon="mdi-close"
                  variant="text"
                  @click="
                    () => {
                      showContractor = false
                      task.contractorId = null
                    }
                  "></v-btn>
              </template>
            </b-server-autocomplete>
          </div>
        </v-col>
      </v-row>
      <v-row class="align-start">
        <v-col cols="12" md="1">
          <v-btn color="primary" icon readonly rounded="circle" variant="tonal">
            <attachment-icon />
          </v-btn>
        </v-col>
        <v-col class="pt-5" cols="12" md="11">
          <FilesUploader
            v-model="task.attachment"
            :prefix-folder="void [0]"
            :readonly="disabled"
            :v$="v$.attachment"
            file-list
            label="Файли"
            main-folder="Документы"
            name-folder="'template'"
            noCard>
          </FilesUploader>
        </v-col>
      </v-row>
    </b-section-loader>
  </div>
</template>

<script lang="ts">
import RulesIcon from '@/assets/images/icons/rules-icon.vue'
import { useCreateTask } from '@/pages/tasks/helpers/createTask'
import { getErrorMessages } from 'best-modules/utils'
import TimeIcon from '@/assets/images/icons/time-icon.vue'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { urlAllUsers, urlContractorsList } from '@/utils/urls'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Task } from '@/utils/types/task'
import UserIcon from '@/assets/images/icons/user-icon.vue'
import { ref } from 'vue'
import AttachmentIcon from '@/assets/images/icons/attachment-icon.vue'
import FilesUploader from '@/components/filesUploader/FilesUploader.vue'
import { omit } from 'lodash'
import { BSectionLoader, BServerAutocomplete } from 'best-modules/components'
import ChangePerson from '@/components/ChangePerson.vue'

export default {
  name: 'CreateTask',
  components: {
    ChangePerson,
    FilesUploader,
    AttachmentIcon,
    UserIcon,
    BaseDatePicker,
    TimeIcon,
    RulesIcon,
    BSectionLoader,
    BServerAutocomplete,
  },
  props: {
    dialog: {
      type: Object,
      default: null,
    },
  },
  methods: { urlContractorsList, urlAllUsers, getErrorMessages },
  setup(props) {
    const useTask = useCreateTask(props.dialog.dialogData.taskData)
    const showContractor = ref(false)

    const { task } = useTask as {
      task: CachedObjectProxy<Task>
    }

    Object.assign(task, props.dialog.dialogData.taskData || {})

    return {
      showContractor,
      task,
      ...omit(useTask, 'task'),
    }
  },
}
</script>

<style lang="scss" scoped></style>
