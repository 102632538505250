<template>
  <v-card class="mt-3 shadow-1" rounded="lg">
    <v-card-title class="mt-1 mb-2">
      <div class="d-flex align-center justify-space-between">
        <h4>{{ contactType.name }}</h4>
        <div v-if="!disabled">
          <v-btn
            color="primary"
            density="comfortable"
            icon
            rounded="lg"
            size="small"
            @click="createContact()">
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <div v-if="localContacts.length">
        <div
          v-for="contact in localContacts"
          :key="contact.id"
          class="contact__item">
          <v-row align="center">
            <v-col cols="3">
              <div style="border-right: 1px solid #ccc">
                <v-chip color="primary" variant="tonal">
                  {{ contact.type.name }}
                </v-chip>
              </div>
            </v-col>
            <v-col cols="8">
              <div v-if="fullDetails" class="pl-8">
                {{ getAddressStr(contact) }}
              </div>
              <div v-else class="pl-8">{{ contact[type] }}</div>
            </v-col>
            <v-col v-if="!disabled" class="pr-0" cols="1">
              <div class="d-flex justify-end">
                <b-action-menu
                  :actions="[
                    {
                      title: 'Редагувати',
                      icon: 'mdi-pencil',
                      action: () => updateContact(contact),
                    },
                    {
                      title: 'Видалити',
                      icon: 'mdi-delete-forever',
                      action: () => deleteContact(contact.id),
                    },
                  ]"
                  :btnProps="{ density: 'compact' }"></b-action-menu>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <ContentNotFound v-else />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { PropType, ref } from 'vue'
import {
  urlContractorAddressDelete,
  urlContractorEmailDelete,
  urlContractorPhoneDelete,
} from '@/utils/urls.js'
import { openDialog } from '@/dialog'
import axios from '@/plugins/axios/index.js'
import { BActionMenu } from 'best-modules/components'
import ContentNotFound from '@/components/ContentNotFound.vue'
import { getAddressStr } from '@/utils/helpers'

export default {
  name: 'ContractorContact',
  components: { ContentNotFound, BActionMenu },
  props: {
    contacts: {
      type: Object,
    },
    type: {
      type: String as PropType<'address' | 'phone' | 'email'>,
      required: true,
    },
    contractorId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullDetails: {
      type: Boolean,
      default: false,
    },
  },
  methods: { getAddressStr },
  setup(props) {
    const getContactType = () => {
      const types = {
        address: {
          name: 'Адреса',
          emptyText: 'Адреси відсутні',
          delete: urlContractorAddressDelete,
        },
        phone: {
          name: 'Телефон',
          emptyText: 'Телефони відсутні',
          delete: urlContractorPhoneDelete,
        },
        email: {
          name: 'Email',
          emptyText: 'Email відсутні',
          delete: urlContractorEmailDelete,
        },
      }
      return types[props.type]
    }
    const contactType = getContactType()
    const localContacts = ref([...(props.contacts || [])])

    const createContact = () => {
      openDialog({
        name: 'ContractorContact',
        action: 'create',
        dialogData: {
          type: props.type,
          contractorId: props.contractorId,
          title: contactType.name.toLowerCase().replace(/а$/, 'у'),
        },
        params: {
          cb: contact => {
            localContacts.value = [...localContacts.value, contact]
          },
        },
      })
    }
    const updateContact = contact => {
      openDialog({
        name: 'ContractorContact',
        action: 'update',
        dialogData: {
          contractorId: props.contractorId,
          type: props.type,
          contact,
          title: contactType.name,
        },
        params: {
          cb: newContact => {
            localContacts.value = localContacts.value.map(c => {
              if (c.id === contact.id) {
                return newContact
              } else {
                return c
              }
            })
          },
        },
      })
    }
    const deleteContact = (contactId: number): Promise<void> => {
      return axios.delete(contactType.delete(contactId)).then(() => {
        localContacts.value = localContacts.value.filter(c => {
          return c.id !== contactId
        })
      })
    }

    return {
      localContacts,
      contactType,
      createContact,
      updateContact,
      deleteContact,
    }
  },
}
</script>

<style lang="scss">
.contact__item {
  margin-bottom: 1rem;
  padding-right: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: #fcfcfc;
  }
}
</style>
