<template>
  <div>
    <b-section-loader :loading="$loading.isLoading('getContractor')">
      <div v-if="contractorInfo">
        <v-card class="shadow-1" rounded="lg">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <b-input
                  :readonly="true"
                  :value="contractorInfo.companyFullName"
                  hide-details
                  label="Повна назва компанії"></b-input>
              </v-col>
              <v-col cols="3">
                <b-select
                  v-model="contractorInfo.type"
                  :items="[contractorInfo.type]"
                  :readonly="true"
                  hide-details
                  label="Тип"
                  return-object></b-select>
              </v-col>
              <v-col cols="3">
                <b-select
                  v-model="contractorInfo.residentCountry"
                  :items="[contractorInfo.residentCountry]"
                  :readonly="true"
                  hide-details
                  label="Країна реєстрації"
                  return-object></b-select>
              </v-col>
              <v-col cols="3">
                <b-input
                  v-model="contractorInfo.stateRegistrationNumber"
                  :mask="'#'.repeat(20)"
                  :readonly="true"
                  hide-details
                  label="Номер реєстрації"
                  placeholder="Введіть номер"></b-input>
              </v-col>
              <v-col cols="3">
                <b-input-date
                  v-model="contractorInfo.stateRegistrationDate"
                  :readonly="true"
                  hide-details
                  label="Дата реєстрації"></b-input-date>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <contact
          :contacts="contractorInfo.addresses"
          :contractorId="1"
          disabled
          full-details
          type="address"></contact>
        <founders :founders="contractorInfo.founders || []"></founders>
        <beneficial :beneficial-owners="contractorInfo.beneficiarOwners || []">
        </beneficial>
        <collegial
          v-if="contractorInfo.collegium"
          :collegial="contractorInfo.collegium"></collegial>
        <director
          v-if="contractorInfo.director"
          :director="contractorInfo.director"></director>
        <queds
          :main-qued="contractorInfo.mainQued"
          :queds="contractorInfo.queds"></queds>
      </div>
      <content-not-found v-else></content-not-found>
    </b-section-loader>
  </div>
</template>

<script lang="ts">
import { handleAsync } from 'best-modules/plugins'
import { axios } from '@/plugins'
import { urlContractorIntegrationInfo } from '@/utils/urls'
import { ref } from 'vue'
import { BInputDate, BSectionLoader } from 'best-modules/components'
import ContentNotFound from '@/components/ContentNotFound.vue'
import Contact from '@/pages/contractor/single/Contact.vue'
import Founders from '@/components/contractor/Founders.vue'
import Beneficial from '@/components/contractor/Beneficial.vue'
import Queds from '@/components/contractor/Queds.vue'
import Collegial from '@/components/contractor/Collegial.vue'
import Director from '@/components/contractor/Director.vue'

export default {
  name: 'ContractorDetails',
  components: {
    Director,
    Collegial,
    Beneficial,
    Founders,
    Contact,
    ContentNotFound,
    BSectionLoader,
    BInputDate,
    Queds,
  },
  props: {
    dialog: {
      type: Object,
    },
  },
  setup(props) {
    const contractorInfo = ref(null)

    const getContractor = () => {
      return handleAsync('getContractor', () => {
        return axios
          .get(
            urlContractorIntegrationInfo(
              props.dialog.dialogData.contractor.code
            )
          )
          .then(res => {
            contractorInfo.value = res.data
          })
      })
    }

    getContractor()

    return {
      contractorInfo,
    }
  },
}
</script>

<style lang="scss" scoped></style>
