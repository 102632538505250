<template>
  <b-autocomplete
    v-model="correspondencePerformerUser.selectedUser"
    :error-messages="getErrorMessages(v$.selectedUser)"
    :items="departmentUserItems"
    :loading="$loading.isLoading('getDepartmentUserItems')"
    hide-details
    item-title="name"
    item-value="id"
    label="Оберіть співробітника"
    placeholder="Оберіть зі списку"
    return-object
    @blur="v$.selectedUser.$touch()"
    @focus="$directory.fill('getDepartmentUserItems')">
  </b-autocomplete>
</template>

<script lang="ts">
import { reactive, ref } from 'vue'
import axios from '@/plugins/axios'
import { urlAllUsers, urlCorrespondenceCreateUser } from '@/utils/urls'
import { useRoute, useVuelidate } from '@/plugins'
import { required } from '@vuelidate/validators'
import { getErrorMessages } from 'best-modules/utils'
import { handleAsync } from 'best-modules/plugins'

export default {
  name: 'PerformerUsers',
  props: {
    dialog: { type: Object },
  },
  methods: { getErrorMessages },
  setup(props) {
    const departmentUserItems = ref([])
    const route = useRoute()
    const correspondencePerformerUser = reactive({
      selectedUser: null,
    })

    const v$ = useVuelidate(
      {
        selectedUser: { required },
      },
      correspondencePerformerUser
    )

    const getDepartmentUsers = () => {
      departmentUserItems.value = []
      return handleAsync('getDepartmentUserItems', () => {
        return axios
          .get(
            urlAllUsers({
              departmentId: props.dialog?.dialogData?.departmentId,
            })
          )
          .then(res => {
            // eslint-disable-next-line no-unsafe-optional-chaining
            departmentUserItems.value.push(...res?.data)
          })
      })
    }

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        const obj = {
          correspondenceId: +route.params.id,
          userId: correspondencePerformerUser?.selectedUser?.id,
        }
        return axios.post(urlCorrespondenceCreateUser(), obj).then(res => {
          // correspondenceData.value.users.push(res.data)
          correspondencePerformerUser.selectedUser = null
          return res.data
        })
      }
    }

    getDepartmentUsers()

    return {
      v$,
      departmentUserItems,
      correspondencePerformerUser,
      submit,
    }
  },
}
</script>

<style lang="scss" scoped></style>
