import { computed, inject, ref } from 'vue'
import { axios, useBreadcrumb, useRoute } from '@/plugins/index.js'
import {
  urlAbsenceDocumentAgreementList,
  urlAbsenceDocumentSignatureList,
  urlDocumentAgreementList,
  urlDocumentSignatureList,
} from '@/utils/urls.js'
import { navigateToItem } from '@/utils/helpers.js'
import { openDialog } from '@/dialog'
import { documentActionKey } from './injectionKeys'
import { PaginationList } from 'best-modules/components/server-table/types'
import { DataTableHeader } from 'best-modules/components'
import { Document } from '@/utils/types/document'
import { useStore } from 'vuex'
import { setSnackbar } from 'best-modules/plugins'

export function useDocumentList() {
  const store = useStore()
  const userId = computed(() => store.state.user.userData?.id)
  const renderKey = ref(1)
  const route = useRoute()
  const documentAction = inject(documentActionKey)
  const breadcrumb = useBreadcrumb()
  breadcrumb.set([], { type: 'replace' })

  // eslint-disable-next-line vue/return-in-computed-property
  const url = computed(() => {
    switch (route.name) {
      case 'document-signature-notes':
        return (query = {}) =>
          urlDocumentSignatureList({
            ...query,
            statusId: 1,
          })
      case 'document-signature-all':
        return (query = {}) =>
          urlDocumentSignatureList({
            ...query,
            statusId: 2, // done
          })
      case 'document-signature-signed':
        return (query = {}) =>
          urlDocumentSignatureList({
            ...query,
            statusId: 3, // done
          })
      case 'document-signature-canceled':
        return (query = {}) =>
          urlDocumentSignatureList({
            ...query,
            statusId: 4, // done
          })
      case 'document-agreement-new':
        return (query = {}) =>
          urlDocumentAgreementList({
            ...query,
            statusId: 1,
          })
      case 'document-agreement-revision':
        return (query = {}) =>
          urlDocumentAgreementList({
            ...query,
            statusId: 2,
          })
      case 'document-agreement-me':
        return (query = {}) =>
          urlDocumentAgreementList({
            ...query,
            statusId: 3,
          })
      case 'document-agreement-agreed':
        return (query = {}) =>
          urlDocumentAgreementList({
            ...query,
            statusId: 4,
          })
      // absence user
      case 'absence-document-signature-all-list':
        return (query = {}) =>
          urlAbsenceDocumentSignatureList(+route?.params?.absenceId, {
            ...query,
            statusId: 2, // done
          })
      case 'absence-document-agreement-me-list':
        return (query = {}) =>
          urlAbsenceDocumentAgreementList(+route?.params?.absenceId, {
            ...query,
            statusId: 3,
          })
    }
  })

  const documentListHeaders: DataTableHeader[] = [
    {
      key: 'created_at',
      title: 'Дата',
      align: 'start',
      sortable: false,
    },
    {
      key: 'name',
      title: 'Назва',
      align: 'start',
      sortable: false,
    },
    {
      key: 'status',
      title: 'Статус',
      align: 'start',
      sortable: false,
    },
    {
      key: 'number',
      title: 'Номер',
      align: 'start',
      sortable: false,
    },
    {
      key: 'type',
      title: 'Тип',
      align: 'start',
      sortable: false,
      minWidth: '140px',
    },
    {
      key: 'author',
      title: 'Автор',
      sortable: false,
    },
    {
      key: 'contractors',
      title: 'Контрагенти',
      align: 'center',
      sortable: false,
    },
  ]

  const createDocument = () => {
    openDialog({
      name: 'Document',
      action: 'create',
      dialogData: {
        documentAction: documentAction.value,
      },
      params: {
        cb: document => {
          navigateToItem(`document-${documentAction.value}-single`, document.id)
        },
        submitLabel: 'Створити',
      },
    })
  }

  const documentList = ref([])
  const selectedDocuments = ref([])

  const signDocuments = () => {
    const canSign = document => {
      return (
        [1, 2].includes(document.statusId) &&
        document.users?.find(u => u.userId === userId.value)?.isSignature
      )
    }
    const documents = documentList.value.filter(d => {
      return selectedDocuments.value.includes(d.id) && canSign(d)
    })

    if (!documents.length) {
      return setSnackbar({
        color: 'warning',
        text: 'Жоден з обраних документів не можна підписати',
      })
    }

    return openDialog({
      name: 'SignDocument',
      dialogData: {
        documents,
        entityType: 'user',
      },
      params: {
        hideActionButtons: true,
        cb: () => {
          renderKey.value += 1
        },
      },
    })
  }

  const getDocuments = (query): Promise<PaginationList<Document>> => {
    return axios.get(url.value(query)).then(res => {
      documentList.value = res.data.data

      return res.data
    })
  }

  return {
    renderKey,
    navigateToItem,
    createDocument,
    getDocuments,
    documentListHeaders,
    selectedDocuments,
    signDocuments,
    documentList,
  }
}
