<template>
  <v-card class="mt-3 shadow-1" rounded="lg">
    <v-card-text>
      <v-tabs
        v-model="tab"
        color="surface"
        height="40px"
        hide-slider
        selected-class="bg-darkGrey">
        <v-tab
          class="bg-surface text-black font-size-12"
          density="compact"
          height="30px"
          size="small"
          value="task-comments"
          variant="flat">
          Коментарі
        </v-tab>
        <v-tab
          class="bg-surface text-black font-size-12"
          density="comfortable"
          height="30px"
          size="small"
          value="task-history"
          variant="flat">
          Історія
        </v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item value="task-comments">
          <Comments
            :comments="task.comments"
            :disabled="task.statusId === 3"
            :entity-id="task.id"
            :entity-type-id="1"
            :hideDelete="false" />
        </v-window-item>
        <v-window-item value="task-history">
          <history-list :url="computedUrl"></history-list>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Comments from '@/pages/tasks/components/comments/TaskComments.vue'
import { computed, ref } from 'vue'
import HistoryList from '@/components/history/HistoryList.vue'
import { urlTaskHistory } from '@/utils/urls'

export default {
  name: 'TaskDetails',
  emits: ['update:model-value'],
  components: { HistoryList, Comments },
  props: {
    modelValue: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const tab = ref('task-comments')

    const task = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        emit('update:model-value', v)
      },
    })

    const computedUrl = computed(() => urlTaskHistory(task.value.id))

    return {
      computedUrl,
      task,
      tab,
    }
  },
}
</script>

<style lang="scss" scoped></style>
