<script lang="ts">
export default {
  name: 'Rar',
}
</script>

<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 17 20"
    width="17"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19572 0.536621C0.519877 0.536621 0 1.10849 0 1.73234V18.7843C0 19.4602 0.571865 19.98 1.19572 19.98H15.8043C16.4801 19.98 17 19.4602 17 18.7843V5.99534L11.5413 0.536621H1.19572Z"
      fill="#B65BAA" />
    <path
      d="M16.9999 5.99534H12.7369C12.061 5.99534 11.5411 5.47546 11.5411 4.79962V0.536621L16.9999 5.99534Z"
      fill="#A53194" />
    <path
      d="M5.1483 11.9086H4.78906V14H4V8H5.2317C5.6722 8 6.0122 8.17143 6.2517 8.51429C6.49547 8.85143 6.61736 9.32571 6.61736 9.93714C6.61736 10.3714 6.55535 10.74 6.43132 11.0429C6.31158 11.3457 6.13837 11.5714 5.9117 11.72L6.81623 14H5.95019L5.1483 11.9086ZM4.78906 10.9057H5.22528C5.41346 10.9057 5.55673 10.8257 5.6551 10.6657C5.75774 10.5 5.80906 10.26 5.80906 9.94571C5.80906 9.63714 5.75774 9.40286 5.6551 9.24286C5.55673 9.08286 5.41346 9.00286 5.22528 9.00286H4.78906V10.9057Z"
      fill="white" />
    <path
      d="M8.8505 12.9114H7.79842L7.6188 14H6.79767L7.90106 8H8.74786L9.85126 14H9.03012L8.8505 12.9114ZM8.69012 11.9086L8.5105 10.7943C8.47629 10.5657 8.41641 10.0743 8.33088 9.32H8.31163C8.25176 9.94286 8.19402 10.4343 8.13842 10.7943L7.9588 11.9086H8.69012Z"
      fill="white" />
    <path
      d="M11.3321 11.9086H10.9728V14H10.1838V8H11.4155C11.856 8 12.196 8.17143 12.4355 8.51429C12.6792 8.85143 12.8011 9.32571 12.8011 9.93714C12.8011 10.3714 12.7391 10.74 12.6151 11.0429C12.4953 11.3457 12.3221 11.5714 12.0955 11.72L13 14H12.134L11.3321 11.9086ZM10.9728 10.9057H11.4091C11.5972 10.9057 11.7405 10.8257 11.8389 10.6657C11.9415 10.5 11.9928 10.26 11.9928 9.94571C11.9928 9.63714 11.9415 9.40286 11.8389 9.24286C11.7405 9.08286 11.5972 9.00286 11.4091 9.00286H10.9728V10.9057Z"
      fill="white" />
  </svg>
</template>

<style lang="scss" scoped></style>
