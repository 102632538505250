<template>
  <v-card elevation="1">
    <v-card-text>
      <b-server-table
        :key="renderKey"
        :headers="taskHeaders"
        :request-func="getObserverUsers"
        show-filters
        show-search
        @click:row="openRow">
        <template #filters>
          <b-server-filter-item
            v-slot="{ props }"
            directory="taskStatuses"
            query-key="statusId">
            <b-select
              clearable
              label="Статус"
              placeholder="Оберіть зі списку"
              v-bind="props"></b-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            :query-key="['completedStartDate', 'completedEndDate']">
            <b-input-date label="Дата виконання" range v-bind="props" />
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            :query-key="['deadlineStartDate', 'deadlineEndDate']">
            <b-input-date label="Термін виконання" range v-bind="props" />
          </b-server-filter-item>
        </template>
        <template #action-button>
          <printed-form></printed-form>
        </template>
        <template #item.status="{ item }">
          <v-chip
            v-if="item.status"
            :color="getStatusColor(item.status.id)"
            size="small"
            variant="flat"
            >{{ item.status?.name }}
          </v-chip>
        </template>
        <template #item.deadline="{ item }">
          <v-chip v-if="item.isOverdue" color="red" size="small"
            >{{ getDeadline(item.deadline) }}
          </v-chip>
          <span v-else>{{ getDeadline(item.deadline) }}</span>
        </template>
        <template #item.executor="{ item }">
          <span>{{
            getUserName(item.executor, { initials: true }) || '---'
          }}</span>
        </template>
        <template #item.topic="{ item }">
          <span
            v-tooltip:bottom="item.topic"
            style="
              display: block;
              width: 200px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            >{{ item?.topic || '---' }}</span
          >
        </template>
        <template #item.author="{ item }">
          <span>{{
            getUserName(item.author, { initials: true }) || '---'
          }}</span>
        </template>
        <template #item.completedDate="{ item }">
          <div class="d-flex align-center gap-3">
            <span>{{ item.completedDate || '---' }}</span>
            <v-menu v-if="item.completedDate" open-on-hover>
              <template #activator="{ props }">
                <v-btn
                  density="compact"
                  icon="mdi-information-variant"
                  size="small"
                  v-bind="props"
                  variant="plain"></v-btn>
              </template>

              <v-card>
                <v-card-text>
                  {{ item.completionReport || item.rejectComment }}
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </template>
        <template #item.connect="{ item }">
          <span
            v-if="item.entityTypeId && item.entityCustomId"
            class="link"
            @click.stop="
              $router.push({
                name:
                  item.entityTypeId === 3
                    ? 'single-order'
                    : item.entityTypeId === 9
                    ? 'audit-single'
                    : 'correspondence-single',
                params: { id: item.entityCustomId },
              })
            ">
            <span v-if="item.entityTypeId === 3">Наказ</span>
            <span v-else-if="item.entityTypeId === 9">Аудит</span>
            <span v-else>Кореспонденція</span>
          </span>
          <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
        </template>
        <template #[`item.contractor`]="{ item }">
          <v-tooltip
            v-if="item.contractor"
            :text="item.contractor?.name"
            bottom>
            <template #activator="{ props }">
              <span
                class="link"
                style="
                  display: block;
                  width: 230px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                v-bind="props"
                @click.stop="
                  $router.push({
                    name: 'single-contractor',
                    params: { id: item?.contractor?.id },
                  })
                "
                >{{ item.contractor?.name }}</span
              >
            </template>
          </v-tooltip>
          <v-icon v-else>{{ 'mdi-minus' }}</v-icon>
        </template>
      </b-server-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { ref } from 'vue'
import { axios } from '@/plugins'
import { getUserName, navigateToItem } from '@/utils/helpers'
import { urlGetObserverUsers } from '@/utils/urls'
import { taskHeaders } from '@/utils/tableHeaders'
import PrintedForm from './components/PrintedForm.vue'
import {
  BInputDate,
  BServerFilterItem,
  BServerTable,
} from 'best-modules/components/index'
import moment from 'moment'
import { CustomDate } from 'best-modules/utils/index'

export default {
  name: 'ObserverList',
  components: {
    PrintedForm,
    BServerTable,
    BServerFilterItem,
    BInputDate,
  },
  data: () => {
    return {
      taskHeaders,
    }
  },
  methods: { getUserName },
  setup() {
    const renderKey = ref(1)

    const openRow = event => {
      navigateToItem('single-task', event.id)
    }

    const getObserverUsers = query => {
      return axios.get(urlGetObserverUsers(query)).then(res => res.data)
    }

    const getStatusColor = statusId => {
      switch (statusId) {
        case 1:
          return 'yellow-lighten-1'
        case 2:
          return 'blue'
        case 3:
          return 'green'
        case 4:
          return 'red'
        case 5:
          return 'yellow-lighten-1'
      }
    }

    const getDeadline = deadline => {
      return moment(
        new CustomDate(deadline).toString({ time: true, format: 'iso' })
      ).calendar(null, {
        sameDay: 'Сьогодні HH:mm',
        nextDay: 'Завтра HH:mm',
        lastDay: 'Вчора HH:mm',
        nextWeek: 'DD.MM.YYYY HH:mm',
        lastWeek: 'DD.MM.YYYY HH:mm',
        sameElse: 'DD.MM.YYYY HH:mm',
      })
    }

    return {
      getObserverUsers,
      renderKey,
      openRow,
      getStatusColor,
      getDeadline,
    }
  },
}
</script>

<style lang="scss" scoped></style>
