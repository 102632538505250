<template>
  <div>
    <v-row>
      <v-col cols="12">
        <b-input
          v-model="contractor.name"
          :error-messages="getErrorMessages(v$.name)"
          hide-details
          label="Повна назва"
          placeholder="Введіть текст"
          @blur="v$.name.$touch()"></b-input>
      </v-col>
      <v-col cols="6">
        <b-input
          v-model="contractor.code"
          :error-messages="getErrorMessages(v$.code)"
          :mask="'#'.repeat(10)"
          hide-details
          label="ЄДРПОУ/ІПН"
          placeholder="Введіть текст"
          @blur="v$.code.$touch()"></b-input>
      </v-col>
      <v-col cols="6">
        <b-select
          v-model="contractor.typeId"
          :error-messages="getErrorMessages(v$.typeId)"
          :items="$directory.get('contractorTypes')"
          :loading="$loading.isLoading('contractorTypes')"
          hide-details
          label="Тип"
          placeholder="Оберіть тип"
          @blur="v$.typeId.$touch()"></b-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { urlContractorCreate } from '@/utils/urls.js'
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import axios from '@/plugins/axios'
import { getErrorMessages } from 'best-modules/utils'
import { fillDirectory, pushDirectoryItem } from '@/directory/index.ts'

export default {
  name: 'Contractor',
  methods: { getErrorMessages },
  setup() {
    fillDirectory('contractorTypes')
    const contractor = reactive({
      name: null,
      code: null,
      typeId: null,
    })
    const rules = {
      name: { required },
      code: { required },
      typeId: { required },
    }

    const v$ = useVuelidate(rules, contractor)

    const submit = () => {
      return axios.post(urlContractorCreate(), contractor).then(res => {
        pushDirectoryItem('contractorList', res)

        return res
      })
    }

    return { submit, v$, contractor }
  },
}
</script>
