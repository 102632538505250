<script lang="ts" setup>

</script>

<template>
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 4.11768H2.5C2.16848 4.11768 1.85054 4.24937 1.61612 4.48379C1.3817 4.71821 1.25 5.03616 1.25 5.36768V15.3677C1.25 15.6992 1.3817 16.0171 1.61612 16.2516C1.85054 16.486 2.16848 16.6177 2.5 16.6177H17.5C17.8315 16.6177 18.1495 16.486 18.3839 16.2516C18.6183 16.0171 18.75 15.6992 18.75 15.3677V5.36768C18.75 5.03616 18.6183 4.71821 18.3839 4.48379C18.1495 4.24937 17.8315 4.11768 17.5 4.11768ZM16.125 5.36768L10 9.60518L3.875 5.36768H16.125ZM2.5 15.3677V5.93643L9.64375 10.8802C9.74837 10.9528 9.87267 10.9916 10 10.9916C10.1273 10.9916 10.2516 10.9528 10.3562 10.8802L17.5 5.93643V15.3677H2.5Z"
      fill="#18181B" />
  </svg>
</template>

<style lang="scss" scoped>

</style>
