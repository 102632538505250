import axios from '@/plugins/axios'
import { urlUploadFiles } from '@/utils/urls'
import ToggleFilterButton from '@/plugins/best-modules/components/ToggleFilterButton.vue'
import Pagination from '@/plugins/best-modules/components/Pagination.vue'

export const bestModulesConfig = {
  axios,
  urls: {
    urlUploadFiles: urlUploadFiles,
  },
  templates: {
    BServerFilter: {
      toggleButton: {
        component: ToggleFilterButton,
      },
    },
    BPagination: {
      component: Pagination,
    },
  },
}
