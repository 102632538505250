<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 18 20"
    width="18"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.2622 0.443359C0.60366 0.443359 0 1.0135 0 1.63546V18.6359C0 19.3097 0.60366 19.828 1.2622 19.828H16.683C17.3964 19.828 17.9452 19.3097 17.9452 18.6359V5.88556L12.2378 0.443359H1.2622Z"
      fill="#F87171" />
    <path
      d="M18 5.88556H13.5C12.7866 5.88556 12.2378 5.36726 12.2378 4.69346V0.443359L18 5.88556Z"
      fill="#EF4444" />
    <path
      d="M4.71973 7.69971H6.0368C6.96973 7.69971 7.18924 8.11435 7.18924 8.78815V10.2912C7.18924 10.965 6.85998 11.3797 6.0368 11.3797H5.59778V14.0749H4.71973V7.69971ZM5.98192 10.6022C6.20144 10.6022 6.31119 10.4986 6.31119 10.2912V8.83998C6.31119 8.63266 6.20144 8.529 5.98192 8.529H5.59778V10.6022H5.98192Z"
      fill="white" />
    <path
      d="M8.06689 7.69971H9.32909C10.262 7.69971 10.5364 8.11435 10.5364 8.83998V12.9864C10.5364 13.712 10.3169 14.1267 9.38397 14.1267H8.06689V7.69971ZM9.32909 13.2974C9.54861 13.2974 9.65836 13.1937 9.65836 12.9864V8.83998C9.65836 8.63266 9.54861 8.529 9.32909 8.529H8.94494V13.2974H9.32909Z"
      fill="white" />
    <path
      d="M11.5254 14.0749V7.69971H13.501V8.529H12.4034V10.4467H13.3364V11.2242H12.4034V14.023H11.5254V14.0749Z"
      fill="white" />
  </svg>
</template>

<style lang="scss" scoped></style>
