<template>
  <div class="entity">
    <span class="entity__main">
      <slot v-if="checked" name="check"></slot>
      <span class="entity__icon">
        <slot name="append-icon"></slot>
        <span
          v-if="entity.typeId === 1 || entity.typeId === void [0]"
          :style="style"
          class="entity__icon-user">
          <BaseImage
            :class="{ showDefaultIcon: !entity.photo }"
            :src="entity.photo"
            alt="user-icon"
            onerror="this.classList.add('showDefaultIcon')"
            rounded
            width="42" />
          <i :style="style" class="defaultIcon pi pi-user"></i>
        </span>
        <i v-else :class="entity.photo" :style="style"></i>
      </span>
      <span class="entity__text">
        <span class="entity__name">{{ entity.name }}</span
        ><br />
        <span class="entity__description pl-1">{{
          getEntityDescription(entity)
        }}</span>
      </span>
    </span>
    <span v-if="showDelete" class="entity__delete">
      <v-btn icon size="small" variant="text" @click="$emit('delete', entity)">
        <v-icon height="18px" icon="mdi-delete-forever" width="22px" />
      </v-btn>
    </span>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  emits: ['delete'],
  props: {
    entity: { type: Object, required: true },
    showDelete: { type: Boolean },
    checked: { type: Boolean },
    size: { type: String, default: '3rem' },
  },
  setup(props) {
    const style = computed(() => {
      return `width:${props.size};height:${props.size};font-size:${props.size}`
    })

    const getUserDescription = user => {
      if ('department' in user) {
        const department = user.department?.name || 'Департамент не вказано'
        const post = user.post || 'Посаду не вказано'

        return `${department} (${post})`
      }
      return null
    }

    const getEntityDescription = entity => {
      return entity.description || getUserDescription(entity) || 'Группа'
    }

    return { style, getEntityDescription }
  },
}
</script>

<style lang="scss" scoped>
.entity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;

  &:hover {
    background: #00000014;
  }

  & * {
    display: inline-block;
  }

  &__main {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    & i {
      transform: scale(0.7);
    }

    &-user {
      & img {
        margin: 0 auto;
      }
    }
  }

  &__text {
    margin-left: 1rem;
  }

  &__name {
    font-size: 14px;
  }

  &__description {
    color: var(--gray-500);
  }

  &__delete {
    margin-left: 1rem;
  }
}
</style>
