<script lang="ts">

export default {
  name: "DocumentViewIcon"
};
</script>

<template>
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.75 16.6177C14.4404 16.6177 15 16.0581 15 15.3677C15 14.6773 14.4404 14.1177 13.75 14.1177C13.0596 14.1177 12.5 14.6773 12.5 15.3677C12.5 16.0581 13.0596 16.6177 13.75 16.6177Z"
      fill="#18181B" />
    <path
      d="M18.6106 15.0418C18.2243 14.058 17.5579 13.2092 16.6939 12.6006C15.83 11.9919 14.8064 11.6502 13.75 11.6177C12.6936 11.6502 11.67 11.9919 10.8061 12.6006C9.94206 13.2092 9.27566 14.058 8.88944 15.0418L8.75 15.3677L8.88944 15.6936C9.27566 16.6774 9.94206 17.5262 10.8061 18.1348C11.67 18.7435 12.6936 19.0852 13.75 19.1177C14.8064 19.0852 15.83 18.7435 16.6939 18.1348C17.5579 17.5262 18.2243 16.6774 18.6106 15.6936L18.75 15.3677L18.6106 15.0418ZM13.75 17.8677C13.2555 17.8677 12.7722 17.7211 12.3611 17.4464C11.95 17.1717 11.6295 16.7812 11.4403 16.3244C11.2511 15.8676 11.2016 15.3649 11.298 14.88C11.3945 14.395 11.6326 13.9496 11.9822 13.5999C12.3319 13.2503 12.7773 13.0122 13.2623 12.9157C13.7472 12.8193 14.2499 12.8688 14.7067 13.058C15.1635 13.2472 15.554 13.5677 15.8287 13.9788C16.1034 14.3899 16.25 14.8733 16.25 15.3677C16.2493 16.0305 15.9856 16.666 15.5169 17.1347C15.0483 17.6033 14.4128 17.867 13.75 17.8677Z"
      fill="#18181B" />
    <path
      d="M7.5 17.8677H5V2.86771H10V6.61771C10.001 6.94893 10.133 7.26632 10.3672 7.50053C10.6014 7.73475 10.9188 7.86675 11.25 7.86771H15V10.3677H16.25V6.61771C16.2522 6.53556 16.2366 6.45391 16.2043 6.37838C16.1719 6.30285 16.1235 6.23524 16.0625 6.18021L11.6875 1.80521C11.6325 1.74416 11.5649 1.69578 11.4893 1.66341C11.4138 1.63104 11.3321 1.61545 11.25 1.61771H5C4.66877 1.61866 4.35139 1.75067 4.11717 1.98488C3.88296 2.21909 3.75096 2.53648 3.75 2.86771V17.8677C3.75096 18.1989 3.88296 18.5163 4.11717 18.7505C4.35139 18.9847 4.66877 19.1167 5 19.1177H7.5V17.8677ZM11.25 3.11771L14.75 6.61771H11.25V3.11771Z"
      fill="#18181B" />
  </svg>
</template>

<style lang="scss" scoped>

</style>
