import { urlOrderSingle, urlUpdateOrder } from '@/utils/urls.js'
import { computed, provide, ref } from 'vue'
import { axios, useBreadcrumb, useStore } from '@/plugins/index.js'
import { cachedObject } from 'best-modules/utils'
import { useRoute, useRouter } from 'vue-router'
import { setSnackbar } from 'best-modules/plugins'
import { cloneDeep } from 'lodash'

export function useOrder() {
  const store = useStore()
  const route = useRoute()
  const router = useRouter()
  const breadcrumb = useBreadcrumb()
  const initLoading = ref(true)
  const submitLoading = ref(false)

  const user = computed(() => store.state.user.userData)
  const newFile = ref(null)
  const order = cachedObject({})
  order.$setIgnore('related', 'accesses')
  const disabled = computed(() => {
    return !order.isActive
  })

  const getOrder = () => {
    return axios.get(urlOrderSingle(route.params.id)).then(res => {
      if (res.data.status === 403) {
        router.replace({ name: 'order-list-all' })
        return
      }
      order.$set(res.data)
      return res.data
    })
  }

  const submit = (o = null) => {
    submitLoading.value = true
    const req = cloneDeep(o || order.$object)
    req.document = newFile.value || req.document

    return axios.post(urlUpdateOrder(order.id), req).then(res => {
      order.$set(res.data)
      setSnackbar({ text: 'Наказ оновлено' })
      submitLoading.value = false
    })
  }

  provide('submit', submit)

  const cancel = () => {
    return order.$reset()
  }

  Promise.all([getOrder()]).then(res => {
    if ([1, 4].includes(user.value.roleId)) {
      breadcrumb.set([
        { title: 'На ознайомлення', index: 1 },
        { title: res[0].name, index: 2 },
      ])
    } else {
      breadcrumb.set([{ title: res[0].name, index: 1 }])
    }
    initLoading.value = false
  })

  return {
    newFile,
    order,
    initLoading,
    submitLoading,
    disabled,
    submit,
    cancel,
  }
}
