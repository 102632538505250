<template>
  <div>
    <b-section-loader :loading="$loading.isLoading('getHistory')">
      <v-data-table
        v-if="historyList.length"
        :headers="historyHeaders"
        :hide-default-footer="!historyList.length"
        :hide-default-header="!historyList.length"
        :items="historyList"
        :items-per-page="50">
        <!-- eslint-disable-next-line -->
        <template #item.userName="{ item }">
          {{
            getUserName(item.userName, { initials: true, isFullName: true }) ||
            '---'
          }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.date="{ item }">
          {{ formatDate(item?.dateTo, 'response') || '---' }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.field="{ item }">
          {{ item?.name || '---' }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.changedValue="{ item }">
          <div class="d-flex align-center gap-2">
            <HistoryItem :value="item ? item.oldValue : null" />
            <v-icon>mdi-arrow-right-thin</v-icon>
            <HistoryItem :value="item ? item.newValue : null" />
          </div>
        </template>
      </v-data-table>
      <content-not-found v-else></content-not-found>
    </b-section-loader>
  </div>
</template>

<script lang="ts">
import { axios } from '@/plugins/index.js'
import { formatDate } from '@/plugins/axios/helpers'
import HistoryItem from '@/components/history/HistoryItem.vue'
import { getUserName } from '@/utils/helpers'
import { BSectionLoader } from 'best-modules/components'
import { handleAsync } from 'best-modules/plugins'
import { historyHeaders } from '@/utils/tableHeaders'
import { ref } from 'vue'
import ContentNotFound from '@/components/ContentNotFound.vue'

export default {
  name: 'HistoryList',
  components: { ContentNotFound, HistoryItem, BSectionLoader },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      historyHeaders,
    }
  },
  methods: { getUserName, formatDate },
  setup(props) {
    const historyList = ref([])

    const getHistory = () => {
      return handleAsync('getHistory', () => {
        return axios.get(props.url).then(res => {
          historyList.value = res.data
          return res.data
        })
      })
    }

    getHistory()

    return {
      getHistory,
      historyList,
    }
  },
}
</script>

<style lang="scss" scoped></style>
