<template>
  <div>
    <OrderList v-if="ordersListRoute" />
    <RouterView v-else />
  </div>
</template>

<script>
import OrderList from './OrderList.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'Index',
  components: { OrderList },
  setup() {
    const route = useRoute()
    const ordersListRoute = computed(() =>
      [
        'order-list',
        'order-list-all',
        'document-familiarization-me',
        'document-familiarization-familiarized',
      ].includes(route.name)
    )

    return { ordersListRoute }
  },
}
</script>
