<template>
  <svg
    fill="none"
    height="23"
    viewBox="0 0 22 23"
    width="22"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4236 13.7639L18.3611 11.7014C18.2973 11.6376 18.2215 11.5869 18.1381 11.5523C18.0547 11.5178 17.9653 11.5 17.875 11.5C17.7847 11.5 17.6953 11.5178 17.6119 11.5523C17.5285 11.5869 17.4528 11.6376 17.3889 11.7014L11 18.0903V21.125H14.0347L20.4236 14.7361C20.4874 14.6723 20.5381 14.5965 20.5727 14.5131C20.6072 14.4297 20.625 14.3403 20.625 14.25C20.625 14.1597 20.6072 14.0703 20.5727 13.9869C20.5381 13.9035 20.4874 13.8277 20.4236 13.7639ZM13.4653 19.75H12.375V18.6597L15.8125 15.2222L16.9028 16.3125L13.4653 19.75ZM17.875 15.3403L16.7847 14.25L17.875 13.1597L18.9653 14.25L17.875 15.3403Z"
      fill="#0D99FF" />
    <path d="M12.375 11.5H5.5V12.875H12.375V11.5Z" fill="#0D99FF" />
    <path d="M13.75 7.375H5.5V8.75H13.75V7.375Z" fill="#0D99FF" />
    <path
      d="M17.875 3.25C17.8746 2.88546 17.7296 2.53596 17.4718 2.27819C17.214 2.02042 16.8645 1.87542 16.5 1.875H2.75C2.38546 1.87542 2.03597 2.02042 1.77819 2.27819C1.52042 2.53596 1.37542 2.88546 1.375 3.25V12.1875C1.37352 13.5586 1.74545 14.9043 2.45089 16.08C3.15632 17.2557 4.16863 18.2171 5.37914 18.8611L8.93751 20.7584V19.2L6.02636 17.6479C5.03591 17.121 4.20763 16.3344 3.63042 15.3724C3.05321 14.4104 2.74885 13.3094 2.75 12.1875V3.25H16.5V9.4375H17.875V3.25Z"
      fill="#0D99FF" />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'RulesIcon',
}
</script>

<style lang="scss" scoped></style>
