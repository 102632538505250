<template>
  <svg
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3189 12.9936L9.00645 2.68113C7.2877 0.893632 4.46895 0.893632 2.68145 2.61238C0.893945 4.33113 0.893945 7.21863 2.68145 8.93738L2.7502 9.00613L4.6752 10.9999L5.63769 10.0374L3.64395 8.04363C2.4752 6.87488 2.4752 4.88113 3.64395 3.71238C4.8127 2.54363 6.80645 2.47488 7.9752 3.64363C7.9752 3.64363 7.9752 3.64363 8.04395 3.71238L18.2877 13.9561C19.5252 15.1249 19.5252 17.1186 18.3564 18.2874C17.1877 19.5249 15.1939 19.5249 14.0252 18.3561C14.0252 18.3561 14.0252 18.3561 13.9564 18.2874L8.86895 13.1999C8.18145 12.5124 8.2502 11.4124 8.86895 10.7936C9.55645 10.1749 10.5877 10.1749 11.2752 10.7936L14.0939 13.6124L15.0564 12.6499L12.1689 9.76238C10.9314 8.59363 9.00645 8.66238 7.8377 9.89988C6.7377 11.0686 6.7377 12.9249 7.8377 14.1624L12.9939 19.3186C14.7127 21.1061 17.5314 21.1061 19.3189 19.3874C21.1064 17.6686 21.1064 14.7811 19.3189 12.9936C19.3189 13.0624 19.3189 12.9936 19.3189 12.9936Z"
      fill="#0D99FF" />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'AttachmentIcon',
}
</script>

<style lang="scss" scoped></style>
