<template>
  <v-card elevation="1">
    <v-card-text>
      <div class="d-flex justify-end align-center">
        <v-btn class="mb-2" rounded="lg" @click="createContractor">
          <v-icon class="mr-1">mdi-plus-circle</v-icon>
          Створити контрагента
        </v-btn>
      </div>
      <b-server-table
        :key="renderKey"
        :headers="contractorListHeaders"
        :request-func="getContractorList"
        @click:row="openRow">
      </b-server-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from 'vue'
import { navigateToItem } from '@/utils/helpers.js'
import { urlContractorList } from '@/utils/urls.js'
import { openDialog } from '@/dialog/index.ts'
import { BServerTable } from 'best-modules/components/index'
import { contractorListHeaders } from '@/pages/audit/headers'
import axios from '@/plugins/axios'

export default {
  components: { BServerTable },
  data: () => {
    return {
      contractorListHeaders,
    }
  },
  setup() {
    const renderKey = ref(1)
    const contractorList = ref([])

    const getContractorList = query => {
      return axios
        .get(urlContractorList({ ...query, paginate: true }))
        .then(res => res.data)
    }

    const openRow = e => {
      navigateToItem('single-contractor', e?.id)
    }

    const createContractor = () => {
      openDialog({
        name: 'Contractor',
        params: {
          cb: contractor => {
            navigateToItem('single-contractor', contractor.id)
          },
        },
      })
    }

    return {
      getContractorList,
      renderKey,
      openRow,
      contractorList,
      navigateToItem,
      createContractor,
    }
  },
}
</script>

<style lang="scss" scoped></style>
