<template>
  <div style="min-height: 45px">
    <div class="d-flex align-center justify-space-between">
      <slot name="label"></slot>
      <v-hover v-if="!disabled" v-slot="{ props }">
        <div
          class="link font-size-12"
          v-bind="props"
          @click="
            persons.length && !multiple ? updatePersons() : createPerson()
          ">
          <v-icon> {{ persons.length ? 'mdi-pencil' : 'mdi-plus' }}</v-icon>
          <span>{{ persons.length ? 'Змінити' : 'Додати' }}</span>
        </div>
      </v-hover>
    </div>
    <b-section-loader :loading="loading" height="30px">
      <div>
        <div v-if="persons.length" class="d-flex flex-column gap-2 my-2">
          <div
            v-for="(person, index) in persons"
            :key="index"
            class="d-flex align-center gap-2">
            <BaseImage
              v-if="person.photo || person?.user?.photo"
              :src="person.photo || person?.user?.photo"
              rounded
              width="30" />
            <v-avatar
              v-if="!person.photo && !person?.user?.photo"
              class="cursor-pointer"
              color="primary"
              size="small">
              <v-icon icon="mdi-account-circle"></v-icon>
            </v-avatar>
            <div class="d-flex align-center justify-space-between w-100">
              <div class="label">
                {{
                  getPersonName(person.user || person, {
                    keyNames: { surname: 'secondName' },
                  })
                }}
              </div>
              <v-btn
                v-if="!alwaysEdit"
                :disabled="disabled"
                density="comfortable"
                icon
                size="x-small"
                variant="text"
                @click="deletePerson(person.id)">
                <v-icon color="primary" size="large">mdi-delete-forever</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <span
          v-else
          :class="{ 'text-error': invalid, 'text-disabled': !invalid }">
          Дані відсутні
        </span>
      </div>
    </b-section-loader>
  </div>
</template>

<script lang="ts">
import { getPersonName } from 'best-modules/utils/helpers'
import { computed, ref, watch } from 'vue'
import { BSectionLoader } from 'best-modules/components'

export default {
  name: 'ChangePerson',
  emits: ['update-person', 'create-person', 'delete-person'],
  components: { BSectionLoader },
  props: {
    person: { type: [Array, Object], default: () => [] },
    invalid: { type: Boolean, default: false },
    alwaysEdit: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  methods: { getPersonName },
  setup(props, { emit }) {
    const changePerson = ref(false)

    const persons = computed(() =>
      props.multiple
        ? Array.isArray(props.person)
          ? props.person
          : []
        : props.person
        ? [props.person]
        : []
    )

    const createPerson = () => {
      if (props.disabled) return
      changePerson.value = true
      if (changePerson.value) emit('create-person')
    }

    const updatePersons = () => {
      if (props.disabled) return
      changePerson.value = true
      if (changePerson.value) emit('update-person', props.person)
    }

    const deletePerson = personId => {
      if (props.disabled) return
      emit('delete-person', personId)
    }

    watch(
      () => props.person,
      () => {
        changePerson.value = false
      }
    )

    return {
      changePerson,
      createPerson,
      updatePersons,
      deletePerson,
      persons,
    }
  },
}
</script>
