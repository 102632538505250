<script lang="ts">
export default {
  name: 'Zip',
}
</script>

<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 17 20"
    width="17"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19572 0.536621C0.519877 0.536621 0 1.10849 0 1.73234V18.7843C0 19.4602 0.571865 19.98 1.19572 19.98H15.8043C16.4801 19.98 17 19.4602 17 18.7843V5.99534L11.5413 0.536621H1.19572Z"
      fill="#F7CC4C" />
    <path
      d="M16.9999 5.99534H12.7369C12.061 5.99534 11.5411 5.47546 11.5411 4.79962V0.536621L16.9999 5.99534Z"
      fill="#F7AB00" />
    <path
      d="M4 13.28L5.96636 9.00286H4.13388V8H7.22149V8.72857L5.26349 12.9971H7.26333V14H4V13.28Z"
      fill="white" />
    <path d="M7.68773 8H8.71693V14H7.68773V8Z" fill="white" />
    <path
      d="M11.1257 8C11.717 8 12.1772 8.17714 12.5063 8.53143C12.8354 8.88 13 9.36857 13 9.99714C13 10.6257 12.8354 11.1143 12.5063 11.4629C12.1772 11.8114 11.717 11.9857 11.1257 11.9857H10.5316V14H9.50239V8H11.1257ZM11.1173 10.9914C11.6696 10.9914 11.9457 10.66 11.9457 9.99714C11.9457 9.33429 11.6696 9.00286 11.1173 9.00286H10.5316V10.9914H11.1173Z"
      fill="white" />
  </svg>
</template>

<style scoped lang="scss"></style>
