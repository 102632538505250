<template>
  <div>
    <v-card class="mt-3 shadow-1" rounded="lg">
      <v-card-title class="mt-1 mb-2">
        <h4>Види діяльності</h4>
      </v-card-title>
      <v-card-text>
        <b-select
          :items="[mainQued.qued]"
          :model-value="mainQued.qued"
          :readonly="true"
          item-title="description"
          item-value="code"
          label="Основний квед"
          return-object>
        </b-select>
        <v-data-table
          v-if="queds.length"
          :disable-pagination="true"
          :headers="contractorQuedHeaders"
          :hide-default-header="!queds.length"
          :items="queds"
          :items-per-page="-1"
          hide-default-footer>
          <template #item.name="{ item }">
            {{ item.qued?.description }}
          </template>
          <template #item.code="{ item }">
            {{ item.qued?.code }}
          </template>
        </v-data-table>
        <content-not-found v-else></content-not-found>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import ContentNotFound from '@/components/ContentNotFound.vue'
import { contractorQuedHeaders } from '@/utils/tableHeaders'

export default {
  name: 'Queds',
  components: { ContentNotFound },
  props: {
    queds: {
      type: Array,
      required: true,
    },
    mainQued: {
      type: Object,
    },
  },
  data() {
    return {
      contractorQuedHeaders,
    }
  },
}
</script>

<style lang="scss" scoped></style>
