<template>
  <div>
    <v-data-table
      v-if="order.tasks && order.tasks.length"
      :disable-pagination="true"
      :headers="orderTaskHeaders"
      :hide-default-footer="!order.tasks.length"
      :hide-default-header
      :items="order.tasks || []"
      :items-per-page="-1">
      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <v-chip
          v-if="item.status"
          :color="getStatusColor(item.status.id)"
          size="small"
          variant="flat"
          >{{ item.status?.name }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.author="{ item }">
        {{
          getPersonName(item.author, {
            keyNames: { surname: 'secondName' },
            initials: true,
          })
        }}
      </template>
      <!-- eslint-disable-next-line -->
      <template #item.executor="{ item }">
        {{
          getPersonName(item.executor, {
            keyNames: { surname: 'secondName' },
            initials: true,
          })
        }}
      </template>
      <template #bottom></template>
    </v-data-table>
    <content-not-found v-else></content-not-found>
  </div>
</template>

<script lang="ts">
import ContentNotFound from '@/components/ContentNotFound.vue'
import { orderTaskHeaders } from '@/utils/tableHeaders'
import { getPersonName } from 'best-modules/utils/helpers'

export default {
  name: 'Tasks',
  components: { ContentNotFound },
  props: {
    order: {
      type: Object,
    },
  },
  data() {
    return {
      orderTaskHeaders,
    }
  },
  methods: { getPersonName },
  setup() {
    const getStatusColor = statusId => {
      switch (statusId) {
        case 1:
          return 'yellow-lighten-1'
        case 2:
          return 'blue'
        case 3:
          return 'green'
        case 4:
          return 'red'
        case 5:
          return 'yellow-lighten-1'
      }
    }

    return {
      getStatusColor,
    }
  },
}
</script>

<style lang="scss" scoped></style>
