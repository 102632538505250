import { computed, provide } from 'vue'
import {
  axios,
  useBreadcrumb,
  useRoute,
  useStore,
  useVuelidate,
} from '@/plugins'
import { urlTaskSingle, urlTaskUpdate } from '@/utils/urls.js'
import { cachedObject } from 'best-modules/utils'
import { setSnackbar } from 'best-modules/plugins'
import { Task } from '@/utils/types/task'
import { validateAlert } from '@/utils/helpers'
import { required } from '@vuelidate/validators'

export function useEditTask() {
  const store = useStore()
  const route = useRoute()
  const breadcrumb = useBreadcrumb()
  const isAbsence = [
    'absence-single-task',
    'absence-outgoing-tasks',
    'absence-incoming-tasks',
  ].includes(<string>route.name)
  const task = cachedObject<Task>({} as Task)
  task.$setIgnore(
    'created_at',
    'updated_at',
    'comments',
    'statusId',
    'status',
    'control_users',
    'observers',
    'executor',
    'executorId',
    'deadline'
  )

  const taskType = computed(() => {
    return {
      execution: task.typeId === 1,
      familiarization: task.typeId === 2,
      errand: task.typeId === 3,
    }
  })

  const rules = {
    authorId: !taskType.value.execution ? { required } : {},
    topic: taskType.value.execution ? { required } : {},
    description: taskType.value.errand ? {} : { required },
    executorId: !taskType.value.familiarization ? { required } : {},
    deadline: { required },
    attachment: {},
  }
  const v$ = useVuelidate(rules, task.$object)

  const userId = computed(() => store.state.user.userData?.id)
  const isAuthor = computed(() => userId.value === task.authorId)

  const disabled = computed(() => {
    return [2, 3, 4, 5].includes(task.statusId) || task.isOverdue
  })

  const getTask = () => {
    return axios
      .get(urlTaskSingle(route.params.id, +route.params?.absenceId))
      .then(res => {
        task.$set(res.data)
        return res.data
      })
      .then(res => {
        const taskDirection =
          res.executorId === userId.value
            ? isAbsence
              ? 'absence-incoming'
              : 'incoming'
            : isAbsence
            ? 'absence-outgoing'
            : 'outgoing'
        breadcrumb.set([
          {
            title: `${
              ['outgoing', 'absence-outgoing'].includes(taskDirection)
                ? 'Вихідні'
                : 'Вхідні'
            } завдання`,
            to: { name: `${taskDirection}-tasks` },
            index: 0,
          },
          { title: res.topic, index: 1 },
        ])
      })
  }

  const submit = () => {
    v$.value.$validate()
    if (!v$.value.$error) {
      return axios.post(urlTaskUpdate(task.id), task.$object).then(res => {
        task.$set(res.data)
        const entityName = taskType.value.errand ? 'Доручення' : 'Завдання'
        setSnackbar({ text: `${entityName} оновлено` })
        return res.data
      })
    } else {
      validateAlert(v$.value, 'task')
    }
  }

  const cancel = () => {
    return task.$reset()
  }

  provide('taskType', taskType)

  return {
    v$,
    task,
    taskType,
    submit,
    cancel,
    getTask,
    disabled,
    isAuthor,
  }
}
