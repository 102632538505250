<template>
  <v-card-text>
    <document-page-template
      :document="document.document"
      :loading="$loading.isLoading('getDocument')"
      :token="authToken">
      <template #top-header>
        <div class="d-flex gap-1">
          <v-tooltip text="Завантажити протокол підписання">
            <template #activator="{ props }">
              <v-btn
                icon
                size="x-small"
                v-bind="props"
                variant="text"
                @click="downloadProtocol()">
                <v-icon icon="mdi-file-sign" size="large" />
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip text="Завантажити архів">
            <template #activator="{ props }">
              <v-btn
                icon
                size="x-small"
                v-bind="props"
                variant="text"
                @click="downloadArchive()">
                <v-icon icon="mdi-folder-zip" size="large" />
              </v-btn>
            </template>
          </v-tooltip>
        </div>
      </template>
      <template #info>
        <div>
          <div
            v-if="
              document?.client_access?.isSignerDocument &&
              !clientSignatures.length
            ">
            <v-btn class="w-100" @click="signDocument"> Підписати</v-btn>
            <v-divider class="mb-2"></v-divider>
          </div>

          <signature-status :document="document"></signature-status>
        </div>
      </template>
    </document-page-template>
  </v-card-text>
</template>

<script lang="ts">
import SignatureStatus from '@/components/signature/SignatureStatus.vue'
import DocumentPageTemplate from '@/components/document-page-template/Index.vue'
import {
  urlClientAccessDocument,
  urlClientSignatureArchive,
  urlClientSignatureProtocol,
} from '@/utils/urls'
import axios from '@/plugins/axios/index.js'
import { computed, reactive } from 'vue'
import { handleAsync } from 'best-modules/plugins'
import { useRouter } from 'vue-router'
import { openDialog } from '@/dialog'
import { download, toPath } from 'best-modules/utils'
import { documentSignatureSingleChannel, socket } from '@/socket.js'

export default {
  name: 'Document',
  components: {
    SignatureStatus,
    DocumentPageTemplate,
  },
  props: {
    authToken: {
      type: String,
      required: true,
    },
  },
  methods: { download },
  setup(props) {
    const router = useRouter()
    const document = reactive({
      id: null,
      contractor: null,
      participants: null,
      documentSigned: null,
    })

    const clientSignatures = computed(() => {
      if (!document.participants) {
        return []
      }
      return document.participants.filter(s => {
        return s.entityTypeId === 5 && !!s.documentSigner
      })
    })

    const getDocument = () => {
      return handleAsync('getDocument', () => {
        return axios({
          url: urlClientAccessDocument(),
          method: 'get',
          headers: {
            Authorization: `Bearer ${props.authToken}`,
          },
        })
          .then(res => {
            Object.assign(document, res.data)
            window.document.title = res.data.document[0].name
          })
          .catch(err => {
            router.push({ name: 'share-document-access-denied' })

            return err
          })
      })
    }

    getDocument().then(() => {
      socket.on(documentSignatureSingleChannel, res => {
        if (res.id === document.id) {
          Object.assign(document, res)
        }
      })
    })

    const signDocument = () => {
      openDialog({
        name: 'SignDocument',
        dialogData: {
          authToken: props.authToken,
          entityType: 'client',
          documents: [document],
        },
        params: {
          hideActionButtons: true,
          cb: signatures => {
            if (!document.participants) {
              document.participants = []
            }
            document.participants.push(...signatures)
          },
        },
      })
    }

    const downloadProtocol = () => {
      return handleAsync('downloadProtocol', async () => {
        const { data } = await axios({
          url: urlClientSignatureProtocol(),
          method: 'get',
          headers: {
            Authorization: `Bearer ${props.authToken}`,
          },
        })
        const { data: blob }: { data: Blob } = await axios({
          url: toPath(data.url),
          method: 'get',
          headers: {
            Authorization: `Bearer ${props.authToken}`,
          },
          responseType: 'blob',
        })
        const url = URL.createObjectURL(blob)

        return download(url, data.url.split('/').at(-1))
      })
    }

    const downloadArchive = () => {
      return handleAsync('downloadArchive', async () => {
        const { data } = await axios({
          url: urlClientSignatureArchive(),
          method: 'get',
          headers: {
            Authorization: `Bearer ${props.authToken}`,
          },
        })
        const { data: blob }: { data: Blob } = await axios({
          url: toPath(data.url),
          method: 'get',
          headers: {
            Authorization: `Bearer ${props.authToken}`,
          },
          responseType: 'blob',
        })
        const url = URL.createObjectURL(blob)

        return download(url, data.url.split('/').at(-1))
      })
    }

    return {
      document,
      signDocument,
      clientSignatures,
      downloadProtocol,
      downloadArchive,
    }
  },
}
</script>

<style lang="scss" scoped></style>
