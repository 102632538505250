<template>
  <svg
    fill="none"
    height="23"
    viewBox="0 0 22 23"
    width="22"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 3.25C8.92987 3.25 9.59448 3.45161 10.1598 3.82932C10.7251 4.20704 11.1657 4.7439 11.4258 5.37203C11.686 6.00015 11.7541 6.69131 11.6214 7.35812C11.4888 8.02493 11.1614 8.63744 10.6807 9.11818C10.1999 9.59892 9.58743 9.92631 8.92062 10.0589C8.25381 10.1916 7.56265 10.1235 6.93453 9.86334C6.30641 9.60316 5.76954 9.16257 5.39182 8.59727C5.01411 8.03198 4.8125 7.36737 4.8125 6.6875C4.8125 5.77582 5.17466 4.90148 5.81932 4.25682C6.46398 3.61216 7.33832 3.25 8.25 3.25ZM8.25 1.875C7.29818 1.875 6.36773 2.15725 5.57632 2.68605C4.78491 3.21486 4.16808 3.96647 3.80383 4.84584C3.43958 5.72521 3.34428 6.69284 3.52997 7.62637C3.71566 8.55991 4.17401 9.41741 4.84705 10.0905C5.52009 10.7635 6.37759 11.2218 7.31113 11.4075C8.24466 11.5932 9.21229 11.4979 10.0917 11.1337C10.971 10.7694 11.7226 10.1526 12.2514 9.36118C12.7803 8.56977 13.0625 7.63932 13.0625 6.6875C13.0625 5.41115 12.5555 4.18707 11.653 3.28455C10.7504 2.38203 9.52635 1.875 8.25 1.875Z"
      fill="#0D99FF" />
    <path
      d="M15.125 21.125H13.75V17.6875C13.75 16.7758 13.3878 15.9015 12.7432 15.2568C12.0985 14.6122 11.2242 14.25 10.3125 14.25H6.1875C5.27582 14.25 4.40148 14.6122 3.75682 15.2568C3.11216 15.9015 2.75 16.7758 2.75 17.6875V21.125H1.375V17.6875C1.375 16.4111 1.88203 15.1871 2.78455 14.2845C3.68707 13.382 4.91115 12.875 6.1875 12.875H10.3125C11.5889 12.875 12.8129 13.382 13.7155 14.2845C14.618 15.1871 15.125 16.4111 15.125 17.6875V21.125Z"
      fill="#0D99FF" />
    <path d="M22 3.25H15.125V4.625H22V3.25Z" fill="#0D99FF" />
    <path d="M22 6.6875H15.125V8.0625H22V6.6875Z" fill="#0D99FF" />
    <path d="M19.9375 10.125H15.125V11.5H19.9375V10.125Z" fill="#0D99FF" />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'UserIcon',
}
</script>

<style lang="scss" scoped></style>
