<template>
  <div>
    <v-card class="h-100 shadow-1" rounded="lg">
      <v-card-text
        v-for="(status, index) in visibleStatusCards"
        :key="index"
        :class="status.class"
        class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-clock-fast</v-icon>
          <strong>{{ status.title }}</strong>
        </div>
        <v-tooltip v-if="task.completionReport" :text="task.completionReport">
          <template #activator="{ props }">
            <v-btn
              class="ml-2"
              color="white"
              density="compact"
              icon
              rounded="circle"
              size="x-small">
              <v-icon color="black" size="x-small" v-bind="props">
                mdi-information-variant
              </v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </v-card-text>
      <v-card-text v-if="task.isOverdue" style="color: red">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-timer-cancel-outline</v-icon>
          <strong>Завдання протерміноване!</strong>
        </div>
      </v-card-text>
      <v-card-text class="d-flex flex-column gap-2">
        <div class="d-flex align-center justify-space-between">
          <span class="label mb-0">Дата створення</span>
          <span class="label mb-0" style="font-size: 14px">
            {{ new CustomDate(task.created_at).toString({ time: true }) }}
          </span>
        </div>
        <div class="d-flex align-center justify-space-between">
          <span class="label mb-0">Видав</span>
          <span class="label mb-0" style="font-size: 14px">
            {{
              getPersonName(task.author, {
                keyNames: { surname: 'secondName' },
                initials: true,
              })
            }}
          </span>
        </div>
        <div class="d-flex align-center justify-space-between">
          <span class="label mb-0">Тип задачі</span>
          <span class="label mb-0" style="font-size: 14px">
            {{ task?.type?.name }}
          </span>
        </div>
        <div
          v-if="taskType.errand && taskData.entityId"
          class="d-flex align-center justify-space-between">
          <span class="label mb-0">Доручення від:</span>
          <RouterLink
            :to="{ name: 'single-order', params: { id: task.entityId } }"
            class="link">
            {{
              task.entityTypeId === 3
                ? 'Наказу'
                : task.entityTypeId === 9
                ? 'Рекомендація по аудиту'
                : 'Корреспонденції'
            }}
          </RouterLink>
        </div>
        <div class="d-flex flex-column">
          <span class="label mb-0">Термін виконання</span>
          <BaseDatePicker
            v-model="taskData.deadline"
            :error-messages="getErrorMessages(v$.deadline)"
            :loading="$loading.isLoading('updateDeadline')"
            :readonly="
              disabled ||
              $loading.isLoading('updateDeadline') ||
              taskData?.statusId !== 1
            "
            hide-details
            time-picker
            @blur="v$.deadline.$touch()"
            @update:model-value="updateDeadline" />
        </div>
        <change-person
          v-if="taskData.typeId !== 2"
          :disabled="disabled || taskData.statusId !== 1"
          :loading="$loading.isLoading('updateExecutor')"
          :person="task.executor"
          always-edit
          @update-person="executorDialog">
          <template #label>
            <div class="d-flex align-center mb-1">
              <span class="label d-block mr-2 mb-0">Виконавець</span>
              <v-tooltip
                text="Співробітник, який виконує завдання. Якщо під час створення завдання додати різних виконавців, то для кожного буде створено своє окреме завдання, при цьому копіюється завдання і постановнику">
                <template #activator="{ props }">
                  <v-btn
                    color="white"
                    density="compact"
                    icon
                    rounded="circle"
                    size="x-small">
                    <v-icon size="small" v-bind="props">
                      mdi-information-variant
                    </v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
          </template>
        </change-person>
        <change-person
          :disabled="disabled || taskData.statusId !== 1"
          :loading="$loading.isLoading('updateControlUsers')"
          :person="task.control_users"
          multiple
          @create-person="controlUsersDialog"
          @delete-person="deleteControlUser">
          <template #label>
            <div class="d-flex align-center mb-1">
              <span class="label mr-2 mb-0">Контроюючий</span>
              <v-tooltip
                text="Співробітник, який виконує завдання. Якщо під час створення завдання додати різних виконавців, то для кожного буде створено своє окреме завдання, при цьому копіюється завдання і постановнику">
                <template #activator="{ props }">
                  <v-btn
                    color="white"
                    density="compact"
                    icon
                    rounded="circle"
                    size="x-small">
                    <v-icon size="small" v-bind="props">
                      mdi-information-variant
                    </v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
          </template>
        </change-person>
        <change-person
          :disabled="disabled || taskData.statusId !== 1"
          :loading="$loading.isLoading('updateObservers')"
          :person="task.observers"
          multiple
          @create-person="observersDialog"
          @delete-person="deleteObserverUser">
          <template #label>
            <div class="d-flex align-center mb-1">
              <span class="label d-block mb-0 mr-2">Наглядач</span>
              <v-tooltip
                text="Співробітник, який виконує завдання. Якщо під час створення завдання додати різних виконавців, то для кожного буде створено своє окреме завдання, при цьому копіюється завдання і постановнику">
                <template #activator="{ props }">
                  <v-btn
                    color="white"
                    density="compact"
                    icon
                    rounded="circle"
                    size="x-small">
                    <v-icon size="small" v-bind="props">
                      mdi-information-variant
                    </v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
          </template>
        </change-person>
        <div
          v-if="taskData.statusId === 5 && user?.id === taskData?.authorId"
          class="d-flex flex-column gap-1 mt-2">
          <v-btn
            :loading="$loading.isLoading('finalCompleteHandler')"
            color="green"
            rounded="lg"
            @click="finalComplete">
            Прийняти роботу
          </v-btn>
          <v-btn
            :loading="$loading.isLoading('revisionTaskHandler')"
            color="red"
            rounded="lg"
            @click="revisionTask">
            Відправити на доопрацювання
          </v-btn>
        </div>
        <div class="d-flex flex-column gap-1 mt-2">
          <v-btn
            v-if="isExecutor && task.statusId === 1"
            :loading="$loading.isLoading('taskTakeToWork')"
            color="green"
            rounded="lg"
            @click="() => actions.taskTakeToWork(task)">
            Взяти в роботу
          </v-btn>
          <v-btn
            v-if="isExecutor && task.statusId === 2"
            :loading="$loading.isLoading('taskComplete')"
            color="red"
            rounded="lg"
            @click="() => actions.taskComplete(task)">
            Завершити задачу
          </v-btn>
        </div>
        <slot v-if="$slots['action-buttons']" name="action-buttons"></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { CustomDate, getErrorMessages } from 'best-modules/utils'
import { getPersonName } from 'best-modules/utils/helpers'
import { computed } from 'vue'
import { urlUserList } from '@/utils/urls'
import { useStore } from 'vuex'
import { useTaskActions } from '@/pages/tasks/helpers/taskActions'
import ChangePerson from '@/components/ChangePerson.vue'
import { useSidebar } from './sidebar'

export default {
  name: 'Sidebar',
  emits: ['update:task'],
  components: {
    ChangePerson,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      default: () => ({ $validate: () => false }),
    },
    disabled: {
      type: Boolean,
      defaults: false,
    },
  },
  methods: {
    getErrorMessages,
    getPersonName,
    CustomDate,
  },
  setup(props, { emit }) {
    const store = useStore()
    const actions = useTaskActions()
    const user = computed(() => store.state.user.userData)

    const taskData = computed({
      get() {
        return props.task
      },
      set(val) {
        emit('update:task', val)
      },
    })

    return {
      user,
      urlUserList,
      taskData,
      actions,
      ...useSidebar(props.task),
    }
  },
}
</script>

<style lang="scss" scoped></style>
