<template>
  <div class="w-100 cursor-default">
    <div class="item">
      <div class="d-flex align-start">
        <div v-if="!hidePhoto" class="mr-3">
          <BaseImage
            v-if="comment?.user?.photo"
            :src="comment?.user?.photo"
            rounded
            style="cursor: pointer"
            width="32"></BaseImage>
          <v-avatar v-else class="cursor-pointer" color="primary">
            <v-icon icon="mdi-account-circle"></v-icon>
          </v-avatar>
        </div>
        <div class="item_block">
          <div class="item_block__header">
            <span class="mr-2">{{
              getUserName(comment?.user, { initials: true })
            }}</span>
            <span>{{ formatDate(comment?.created_at, 'response') }}</span>
          </div>
          <span
            v-for="(row, idx) in comment.comment.split('\n')"
            :key="idx"
            class="item_block__comment">
            {{ row }} <br />
          </span>
        </div>
      </div>
      <div v-if="!hideDelete">
        <b-action-menu
          :actions="[
            {
              title: 'Видалити',
              action: () => $emit('delete', comment.id),
              icon: 'mdi-delete-forever',
              disabled: !isAuthor || disabled,
            },
          ]"
          :btn-props="{ color: 'grey' }"
          icon="mdi-dots-horizontal"></b-action-menu>
      </div>
    </div>
    <div class="d-flex justify-end mt-2">
      <slot name="showAllComments"></slot>
    </div>
  </div>
</template>

<script>
import { useStore } from '@/plugins/index.js'
import { computed, inject } from 'vue'
import { getUserName } from '@/utils/helpers.js'
import { formatDate } from '@/plugins/axios/helpers.js'
import { BActionMenu } from 'best-modules/components/index'

export default {
  name: 'TaskCommentItem',
  components: {
    BActionMenu,
  },
  props: {
    comment: { type: Object, required: true },
    loading: { type: [Number, Boolean] },
    hideDelete: { type: Boolean },
    hidePhoto: { type: Boolean },
    disabled: { type: Boolean },
  },
  emits: ['delete'],
  methods: { formatDate, getUserName },
  setup(props) {
    const store = useStore()
    const loadingState = inject('loadingState', {})

    const userId = computed(() => store.state.user.userData?.id)
    const isAuthor = computed(() => {
      return props.comment.userId === userId.value
    })

    return { loadingState, isAuthor }
  },
}
</script>

<style lang="scss">
.item {
  display: flex;
  align-items: start;
  justify-content: space-between;

  &_block {
    background: #f4f4f5;
    border-radius: 24px;
    padding: 12px;
    transition: 0.3s;

    &:hover {
      background: #eaeaea;
    }

    &__header {
      display: flex;
      align-items: center;
      font-size: 10px;
      color: #8e92bc;
    }

    &__comment {
      font-size: 12px;
      display: block;
      margin-top: 4px;
    }
  }
}
</style>
