import type { DataTableHeader } from 'best-modules/components'

const taskHeaders: DataTableHeader[] = [
  {
    key: 'status',
    title: 'Статус',
    minWidth: '150px',
    sortable: false,
  },
  {
    key: 'topic',
    title: 'Тема',
    sortable: false,
    minWidth: '230px',
  },
  {
    key: 'executor',
    title: 'Виконавець',
    minWidth: '150px',
    sortable: false,
  },
  {
    key: 'author',
    title: 'Автор',
    minWidth: '150px',
    sortable: false,
  },
  {
    key: 'deadline',
    title: 'Термін виконання',
    minWidth: '150px',
    sortable: false,
  },
  {
    key: 'completedDate',
    title: 'Дата завершення',
    minWidth: '170px',
    sortable: false,
  },
  {
    key: 'contractor',
    title: 'Контрагент',
    sortable: false,
  },
  {
    key: 'connect',
    title: "Зв'язок",
    sortable: false,
  },
  {
    key: 'actions',
    title: 'Дії',
    align: 'end',
    sortable: false,
  },
]

const correspondenceTaskHeaders: DataTableHeader[] = [
  {
    key: 'status.name',
    title: 'Статус',
    align: 'start',
  },
  { key: 'type.name', title: 'Тип', align: 'start' },
  { key: 'topic', title: 'Назва', align: 'start' },
  { key: 'responsible', title: 'Відповідальний', align: 'start' },
  { key: 'deadline', title: 'Термін виконання', align: 'start' },
  { key: 'actions', title: 'Дії', align: 'end' },
]

const historyHeaders: DataTableHeader[] = [
  {
    title: 'Користувач',
    align: 'start',
    key: 'userName',
    sortable: false,
  },
  {
    title: 'Дата',
    key: 'date',
    sortable: false,
    align: 'start',
    minWidth: '135px',
  },
  { title: 'Поле', key: 'field', sortable: false, align: 'start' },
  {
    title: 'Зміни',
    key: 'changedValue',
    sortable: false,
    align: 'start',
  },
]

const orderTaskHeaders: DataTableHeader[] = [
  {
    title: 'Статус',
    align: 'start',
    key: 'status',
    sortable: false,
  },
  {
    title: 'Назва',
    align: 'start',
    key: 'topic',
    sortable: false,
  },
  {
    title: 'Автор',
    align: 'start',
    key: 'author',
    sortable: false,
  },
  {
    title: 'Виконавець',
    align: 'start',
    key: 'executor',
    sortable: false,
  },
]

const contractorFounderListHeaders: DataTableHeader[] = [
  { title: 'ПІБ', key: 'name', sortable: false },
  { title: 'IНН/ЄДРПОУ', key: 'code', sortable: false, align: 'start' },
  {
    title: 'Частка статутного капіталу - грн',
    key: 'amount',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Частка статутного капіталу - %',
    key: 'percent',
    align: 'center',
    sortable: false,
  },
  { title: 'Адреса', key: 'location', sortable: false, align: 'start' },
]

const contractorBeneficialListHeaders: DataTableHeader[] = [
  { title: 'ПIБ', key: 'contact', sortable: false },
  { title: 'IНН/ЄДРПОУ', key: 'inn', sortable: false, align: 'start' },
  {
    title: 'Громадянство',
    key: 'nationality',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Дата народження',
    key: 'birthday',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Адреса',
    key: 'address',
    sortable: false,
    align: 'start',
  },
]

const contractorQuedHeaders: DataTableHeader[] = [
  { title: 'Назва діяльності', key: 'name', sortable: false },
  { title: 'Код діяльності', key: 'code', sortable: false },
]

const contractorCollegialListHeaders: DataTableHeader[] = [
  { title: 'ПIБ', key: 'contact', sortable: false },
  { title: 'IНН/ЄДРПОУ', key: 'inn', sortable: false, align: 'start' },
  {
    title: 'Громадянство',
    key: 'nationality.name',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Дата народження',
    key: 'birthday',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Адреса',
    key: 'address',
    sortable: false,
    align: 'start',
  },
]

const contractorDirectorListHeaders: DataTableHeader[] = [
  { title: 'ПIБ', key: 'contact', sortable: false },
  { title: 'IНН/ЄДРПОУ', key: 'inn', sortable: false, align: 'start' },
  {
    title: 'Громадянство',
    key: 'nationality.name',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Дата народження',
    key: 'birthday',
    sortable: false,
    align: 'center',
  },
  {
    title: 'Адреса',
    key: 'address',
    sortable: false,
    align: 'start',
  },
]

export {
  taskHeaders,
  correspondenceTaskHeaders,
  historyHeaders,
  orderTaskHeaders,
  contractorFounderListHeaders,
  contractorBeneficialListHeaders,
  contractorQuedHeaders,
  contractorCollegialListHeaders,
  contractorDirectorListHeaders,
}
