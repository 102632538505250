<template>
  <div v-if="item" class="d-flex flex-column">
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <span>{{
          item.entity?.name ||
          getUserName(item.user) ||
          item.entity?.topic ||
          item.topic ||
          item.subject
        }}</span>
        <span v-if="!hidePost" class="ml-1"
          >({{
            item?.user?.post || item?.entity?.description || 'Посада відсутня'
          }})</span
        >
      </div>
      <div class="user-item__actions ml-2">
        <slot name="actions"></slot>
      </div>
    </div>
    <v-divider v-if="length > 1" class="pt-1"></v-divider>
  </div>
  <span v-else>Дані відсутні</span>
</template>

<script>
import { getUserName } from '@/utils/helpers'

export default {
  name: 'UserItem',
  props: {
    item: { type: Object },
    hidePost: { type: Boolean, default: false },
    length: { type: Number },
  },
  methods: { getUserName },
}
</script>
