import { computed } from 'vue'
import { axios, useRouter, useStore } from '@/plugins/index.js'
import { urlLogout } from '@/utils/urls.js'
import { getUserName, navigateToItem } from '@/utils/helpers.js'
import { openDialog } from '@/dialog/index.ts'
import { setSnackbar } from 'best-modules/plugins'

export function useUserActions() {
  const store = useStore()
  const router = useRouter()

  const user = computed(() => store.state.user.userData)
  const userActions = computed(() => {
    return [
      {
        label: 'Мій профіль',
        icon: 'mdi-badge-account',
        command: 'profile',
      },
      {
        label: 'Змінити користувача',
        icon: 'mdi-account-convert',
        command: 'changeUser',
        name: 'changeUser',
      },
      {
        label: 'Вийти з системи',
        icon: 'mdi-logout',
        command: 'logout',
      },
    ].filter(a => {
      return a.name !== 'changeUser' || user.value?.roleId === 1
    })
  })

  const logout = () => {
    return axios.post(urlLogout()).then(res => {
      if (res?.status === 204) {
        store.commit('removeToken')
        return router.push({ name: 'login' })
      }
      if (res.data.status === 200) {
        return router.push({ name: 'login' })
      }
      if (typeof res.data === 'object') {
        store.commit('user/setUserData', res.data)
        setSnackbar({
          text: `Користувача змінено на ${getUserName(res.data)}`,
        })
      }
    })
  }

  const profile = () => {
    return navigateToItem('single-user', user.value?.id)
  }

  const changeUser = () => {
    openDialog({
      name: 'ChangeUser',
    })
  }

  const handleUserAction = e => {
    switch (e) {
      case 'changeUser':
        changeUser()
        break
      case 'logout':
        logout()
        break
      case 'profile':
        profile()
        break
      default:
        break
    }
  }

  return { userActions, handleUserAction, user }
}
