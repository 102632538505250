<script lang="ts" setup>

</script>

<template>
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.75 17.8677H1.25V19.1177H18.75V17.8677Z" fill="#18181B" />
    <path
      d="M16.875 7.24271C17.0408 7.24271 17.1997 7.17687 17.3169 7.05966C17.4342 6.94245 17.5 6.78347 17.5 6.61771V4.74271C17.4999 4.61427 17.4603 4.48896 17.3865 4.38384C17.3127 4.27871 17.2083 4.19889 17.0875 4.15521L10.2125 1.65522C10.0752 1.60557 9.92482 1.60557 9.7875 1.65522L2.9125 4.15521C2.79171 4.19889 2.6873 4.27871 2.61349 4.38384C2.53968 4.48896 2.50005 4.61427 2.5 4.74271V6.61771C2.5 6.78347 2.56585 6.94245 2.68306 7.05966C2.80027 7.17687 2.95924 7.24271 3.125 7.24271H3.75V15.3677H2.5V16.6177H17.5V15.3677H16.25V7.24271H16.875ZM3.75 5.18021L10 2.90521L16.25 5.18021V5.99271H3.75V5.18021ZM11.25 15.3677H8.75V7.24271H11.25V15.3677ZM5 7.24271H7.5V15.3677H5V7.24271ZM15 15.3677H12.5V7.24271H15V15.3677Z"
      fill="#18181B" />
  </svg>
</template>

<style lang="scss" scoped>

</style>
