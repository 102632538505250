<template>
  <div>
    <b-section-loader :loading="$loading.isLoading('getContractor')">
      <v-row class="cursor-default">
        <v-col
          :class="!$vuetify.display.smAndUp ? null : 'pr-0'"
          cols="12"
          md="8"
          sm="12">
          <v-card class="shadow-1" rounded="lg">
            <v-card-title class="mt-1 mb-2">
              <div
                :class="
                  $vuetify.display.smAndUp
                    ? 'align-center'
                    : 'flex-column align-start'
                "
                class="d-flex justify-space-between">
                <h4>Основна інформація</h4>
                <v-btn-group
                  color="surface"
                  density="compact"
                  divided
                  rounded="xs"
                  variant="flat">
                  <v-tooltip text="Документи">
                    <template #activator="{ props }">
                      <v-btn
                        size="x-small"
                        v-bind="props"
                        @click="openDialogHandler('documents')">
                        <v-icon>mdi-text-box-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                  <v-tooltip text="Завдання">
                    <template #activator="{ props }">
                      <v-btn
                        size="x-small"
                        v-bind="props"
                        @click="openDialogHandler('tasks')">
                        <v-icon>mdi-file-tree</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                  <v-tooltip text="Повна інформація про контрагента">
                    <template #activator="{ props }">
                      <v-btn
                        size="x-small"
                        v-bind="props"
                        @click="openContractorInfoDialog">
                        <v-icon>mdi-cloud-braces</v-icon>
                      </v-btn>
                    </template>
                  </v-tooltip>
                </v-btn-group>
              </div>
            </v-card-title>
            <v-card-text class="gap-2 d-flex flex-column">
              <div>
                <span class="label">Повна назва</span>
                <b-input
                  v-model="contractor.name"
                  :error-messages="getErrorMessages(v$.name)"
                  class="w-100"
                  hide-details
                  placeholder="Введіть текст"
                  @blur="v$.name.$touch()"></b-input>
              </div>
              <div>
                <span class="label">Коротка назва</span>
                <b-input
                  v-model="contractor.shortName"
                  :error-messages="getErrorMessages(v$.shortName)"
                  class="w-100"
                  hide-details
                  placeholder="Введіть текст"
                  @blur="v$.shortName.$touch()"></b-input>
              </div>
              <div>
                <span class="label">ЄДРПОУ/ІПН</span>
                <b-input
                  v-model="contractor.code"
                  :error-messages="getErrorMessages(v$.code)"
                  :mask="'#'.repeat(10)"
                  class="w-100"
                  hide-details
                  placeholder="Введіть текст"
                  @blur="v$.code.$touch()"></b-input>
              </div>
            </v-card-text>
          </v-card>
          <contact
            :contacts="contractor.addresses"
            :contractorId="contractor.id"
            type="address"></contact>
          <contact
            :contacts="contractor.phones"
            :contractorId="contractor.id"
            type="phone"></contact>
          <contact
            :contacts="contractor.emails"
            :contractorId="contractor.id"
            type="email"></contact>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <ContractorSidebar :contractor="contractor" :v$="v$">
            <template #action-buttons>
              <SubmitButtons
                v-if="contractor.$hasChanges"
                :disabled="!contractor.$hasChanges"
                :submit="updateContractor"
                class="mt-3 d-flex justify-end"
                compact
                submitLabel="Оновити"
                @cancel="contractor.$reset()" />
            </template>
          </ContractorSidebar>
        </v-col>
      </v-row>
    </b-section-loader>
  </div>
</template>

<script>
import { axios, useBreadcrumb, useRoute } from '@/plugins/index.js'
import { urlContractorSingle, urlContractorUpdate } from '@/utils/urls.js'
import { computed } from 'vue'
import { cachedObject, getErrorMessages } from 'best-modules/utils'
import { handleAsync } from 'best-modules/plugins'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import ContractorSidebar from '@/pages/contractor/single/components/ContractorSidebar.vue'
import { BSectionLoader } from 'best-modules/components/index'
import Contact from '@/pages/contractor/single/Contact.vue'
import SubmitButtons from '@/components/SubmitButtons.vue'
import { openDialog } from '@/dialog'

export default {
  components: {
    SubmitButtons,
    Contact,
    ContractorSidebar,
    BSectionLoader,
  },
  methods: { getErrorMessages },
  setup() {
    const route = useRoute()
    const breadcrumb = useBreadcrumb()
    const contractor = cachedObject({})
    contractor.$setIgnore('addresses', 'emails', 'phones')
    const contractorId = computed(() => route.params.id)

    const rules = {
      name: { required },
      code: { required },
      typeId: { required },
      shortName: { required },
    }

    const v$ = useVuelidate(rules, contractor)

    const setContractor = obj => {
      contractor.$set(obj)
      breadcrumb.set([
        {
          title: 'Контрагенти',
          to: { name: 'contractors' },
          index: 0,
        },
        {
          title: obj.name,
          index: 1,
        },
      ])
    }

    const getContractor = () => {
      return handleAsync('getContractor', () => {
        return axios.get(urlContractorSingle(contractorId.value)).then(res => {
          setContractor(res.data)
        })
      })
    }

    const updateContractor = () => {
      v$.value.$touch()
      if (!v$.value.$invalid) {
        return axios
          .post(urlContractorUpdate(contractorId.value), contractor.$object)
          .then(res => {
            setContractor(res.data)
          })
      }
    }

    const openDialogHandler = action => {
      openDialog({
        name: 'ContractorContent',
        dialogData: {
          contractor: contractor,
          title: action === 'documents' ? 'Документи' : 'Завдання',
          action: action,
        },
        params: { hideActionButtons: true },
      })
    }

    const openContractorInfoDialog = () => {
      openDialog({
        name: 'ContractorDetails',
        dialogData: {
          contractor: contractor.$object,
          title: `Інформація про контрагента - ${contractor.code}`,
        },
        params: { hideActionButtons: true },
      })
    }

    getContractor()

    return {
      contractor,
      updateContractor,
      v$,
      openDialogHandler,
      openContractorInfoDialog,
    }
  },
}
</script>

<style lang="scss" scoped></style>
