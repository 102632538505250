import { axios } from '@/plugins/index.js'
import { computed, inject, ref } from 'vue'
import {
  urlDocumentAgreementUpdate,
  urlDocumentSignatureUpdate,
} from '@/utils/urls.js'
import { navigateToItem } from '@/utils/helpers.js'
import { openDialog } from '@/dialog/index.ts'
import { cloneDeep } from 'lodash'
import { documentActionKey } from '@/pages/documents/injectionKeys'
import { useRoute } from 'vue-router'

export function useDocumentSingle(document, state) {
  const documentAction = inject(documentActionKey)
  const newFile = ref(null)
  const route = useRoute()
  const absenceId = route?.params?.absenceId

  // done
  const disabled = computed(() => {
    if (documentAction.value === 'agreement') {
      return !state.isAuthor || [3, 4].includes(document.statusId)
    }
    if (documentAction.value === 'signature') {
      return !state.isAuthor || document.status?.id !== 1
    }
    throw new Error('[documentSingle.js] disabled - invalid documentAction')
  })

  const updateDocument = () => {
    const getUrl = () => {
      switch (documentAction.value) {
        case 'signature':
          return urlDocumentSignatureUpdate(document.id, absenceId)
        case 'agreement':
          return urlDocumentAgreementUpdate(document.id, absenceId)
      }
    }
    const requestObj = {
      ...document.$object,
      document: newFile.value || document.document,
    }

    return axios.post(getUrl(), requestObj).then(res => {
      newFile.value = null
      document.$set(res.data)
    })
  }
  const openEditDocumentDialog = () => {
    openDialog({
      name: 'Document',
      action: 'update',
      dialogData: {
        document: cloneDeep(document.$object),
        documentAction: documentAction.value,
        disabled: disabled.value,
      },
      params: {
        cb: updatedDocument => {
          document.$set(updatedDocument)
        },
        submitLabel: 'Зберегти',
      },
    })
  }

  /** Opening dialogs */
  const showDocumentHistory = () => {
    openDialog({
      name: 'DocumentAgreementHistory',
      dialogData: document.$object,
      params: {
        hideActionButtons: true,
      },
    })
  }
  const showAgreedHistory = () => {
    openDialog({
      name: 'DocumentAgreementUsers',
      dialogData: document.$object,
      params: {
        hideActionButtons: true,
      },
    })
  }

  return {
    newFile,
    disabled,
    updateDocument,
    openEditDocumentDialog,
    navigateToItem,
    showDocumentHistory,
    showAgreedHistory,
  }
}
