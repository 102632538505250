<script lang="ts" setup>

</script>

<template>
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.75 12.9802L6.50625 10.7364L5.625 11.6177L8.75 14.7427L14.375 9.11768L13.4937 8.23018L8.75 12.9802Z"
          fill="#18181B" />
    <path
      d="M15.625 3.49268H13.75V2.86768C13.75 2.53616 13.6183 2.21821 13.3839 1.98379C13.1495 1.74937 12.8315 1.61768 12.5 1.61768H7.5C7.16848 1.61768 6.85054 1.74937 6.61612 1.98379C6.3817 2.21821 6.25 2.53616 6.25 2.86768V3.49268H4.375C4.04348 3.49268 3.72554 3.62437 3.49112 3.85879C3.2567 4.09321 3.125 4.41115 3.125 4.74268V17.8677C3.125 18.1992 3.2567 18.5171 3.49112 18.7516C3.72554 18.986 4.04348 19.1177 4.375 19.1177H15.625C15.9565 19.1177 16.2745 18.986 16.5089 18.7516C16.7433 18.5171 16.875 18.1992 16.875 17.8677V4.74268C16.875 4.41115 16.7433 4.09321 16.5089 3.85879C16.2745 3.62437 15.9565 3.49268 15.625 3.49268ZM7.5 2.86768H12.5V5.36768H7.5V2.86768ZM15.625 17.8677H4.375V4.74268H6.25V6.61768H13.75V4.74268H15.625V17.8677Z"
      fill="#18181B" />
  </svg>
</template>

<style lang="scss" scoped>

</style>
