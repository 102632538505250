import { axios } from '@/plugins/index.js'
import {
  urlBreakAgreed,
  urlSendForAgree,
  urlSendForRevision,
} from '@/utils/urls.js'
import { computed, inject } from 'vue'
import { openDialog } from '@/dialog/index.ts'
import { setSnackbar } from 'best-modules/plugins'
import { useRoute } from 'vue-router'

export function useDocumentAgree(document) {
  const state = inject('state')
  const route = useRoute()
  const absenceId = route?.params?.absenceId

  const agreement = computed(() => {
    return document.participants?.find(a => {
      return a.userId === state.userId
    })
  })
  const canAgree = computed(() => {
    if (!document.participants) return false
    if (state.isAuthor) {
      return [1, 2].includes(document.status.id)
    } else {
      return (
        (!agreement.value && document.statusId === 3) ||
        (agreement.value &&
          !agreement.value.isAgreed &&
          !agreement.value.isRevision)
      )
    }
  })
  const hasAgreedUsers = computed(() => {
    return document.participants
      ?.filter(a => a.userId !== state.userId)
      .reduce((acc, cur) => acc || cur.isAgreed, false)
  })

  const sendForAgree = (text = null) => {
    if (!state.isErm && !document.participants?.length) {
      setSnackbar({
        type: 'warn',
        text: 'Спочатку потрібно заповнити список погоджуючих',
      })

      return Promise.resolve()
    }

    return axios
      .post(urlSendForAgree(document.id, absenceId), { text })
      .then(res => {
        setSnackbar({
          text: state.isAuthor
            ? 'Документ відправлено на погодження'
            : 'Документ погоджено',
        })
        document.$set(res.data)
        return res.data
      })
  }

  const handleAgree = () => {
    if (document.status.id === 2) {
      return openDialog({
        name: 'Textarea',
        params: {
          cb(text) {
            return sendForAgree(text)
          },
        },
        dialogData: {
          placeholder: 'Доопрацьовано',
          dialogTitle: 'Доопрацювання',
        },
      })
    } else {
      return sendForAgree()
    }
  }

  const sendForRevision = text => {
    return axios
      .post(urlSendForRevision(document.id, absenceId), {
        text,
      })
      .then(res => res.data)
  }
  const breakAgreed = text => {
    return axios
      .post(urlBreakAgreed(document.id, absenceId), {
        text,
      })
      .then(res => res.data)
  }
  const openCancelAgreedDialog = action => {
    openDialog({
      name: 'Textarea',
      params: {
        async cb(text) {
          const actionFunc =
            action === 'revision' ? sendForRevision : breakAgreed
          const doc = await actionFunc(text)
          document.$set(doc)
          setSnackbar({ text: 'Документ відправлено на доопрацювання' })
        },
      },
      dialogData: {
        dialogTitle: 'Доопрацювання',
        placeholder: 'Причина',
      },
    })
  }

  return {
    isAuthor: state.isAuthor,
    sendForAgree,
    handleAgree,
    openCancelAgreedDialog,
    canAgree,
    agreement,
    hasAgreedUsers,
  }
}
