<template>
  <div class="mb-2">
    <div
      class="d-flex align-center justify-space-between mb-2"
      style="cursor: pointer"
      @click="setExpanded(!isExpanded)">
      <v-btn
        v-if="!department"
        :class="{ 'collapse-btn--active': isExpanded }"
        class="collapse-btn"
        density="compact"
        variant="plain">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <slot name="prepend-label"></slot>
      <h4>
        {{ label }}
      </h4>
      <slot name="append-label"></slot>
      <v-btn
        v-if="department && !collapsedEmpty"
        class="collapse-btn"
        color="white"
        density="compact"
        icon
        rounded="circle"
        size="x-small"
        variant="flat">
        <v-icon color="primary"
          >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </div>
    <v-scroll-x-transition>
      <div
        v-show="isExpanded"
        :style="department ? {} : { borderLeft: '1px solid #ccc' }"
        class="mt-1">
        <slot name="default"></slot>
      </div>
    </v-scroll-x-transition>
  </div>
</template>

<script lang="ts">
import { computed, inject, isRef, provide, ref, Ref, watch } from 'vue'

export default {
  name: 'ExpansionPanel',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
    },
    department: {
      type: Boolean,
      default: false,
    },
    collapsedEmpty: {
      type: Boolean,
      default: false,
    },
    justifySpaceBetween: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isExpanded: Ref<boolean> = ref(props.modelValue || false)
    provide('isExpanded', isExpanded)

    const setExpanded = (value: any): void => {
      isExpanded.value = !!value
      emit('update:modelValue', !!value)
    }

    watch(
      computed(() => props.modelValue),
      setExpanded,
      { immediate: true }
    )

    // collapse panel if parent is collapsed
    const isParentExpanded: Ref<boolean> | boolean = inject('isExpanded', false)
    if (isRef(isParentExpanded)) {
      watch(isParentExpanded, v => {
        !v && setExpanded(false)
      })
    }

    return { isExpanded, setExpanded }
  },
}
</script>

<style lang="scss">
.collapse-btn {
  transition: 0.2s transform;

  &--active {
    transform: rotate(45deg);
  }
}
</style>
