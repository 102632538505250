<template>
  <small
    v-if="v$.$error"
    :class="[
      'p-error',
      'block',
      'mt-1',
      'fadeinup',
      'animation-duration-200',
      'animation-iteration-1',
    ]"
    >{{ v$.$errors[0].$message }}</small
  >
</template>

<script>
export default {
  props: {
    v$: { type: Object, default: () => ({ $validate: () => false }) },
  },
}
</script>

<style lang="scss" scoped>
.p-error {
  color: red;
  font-weight: 600;
}
</style>
