<template>
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5669 12.4258L16.6919 10.5508C16.6339 10.4927 16.565 10.4467 16.4892 10.4153C16.4133 10.3838 16.3321 10.3677 16.25 10.3677C16.1679 10.3677 16.0867 10.3838 16.0108 10.4153C15.935 10.4467 15.8661 10.4927 15.8081 10.5508L10 16.3589V19.1177H12.7588L18.5669 13.3096C18.6249 13.2515 18.671 13.1827 18.7024 13.1068C18.7339 13.031 18.75 12.9497 18.75 12.8677C18.75 12.7856 18.7339 12.7043 18.7024 12.6285C18.671 12.5527 18.6249 12.4838 18.5669 12.4258ZM12.2412 17.8677H11.25V16.8765L14.375 13.7515L15.3662 14.7427L12.2412 17.8677ZM16.25 13.8589L15.2588 12.8677L16.25 11.8765L17.2412 12.8677L16.25 13.8589Z"
      fill="#18181B" />
    <path d="M11.25 10.3677H5V11.6177H11.25V10.3677Z" fill="#18181B" />
    <path d="M12.5 6.61768H5V7.86768H12.5V6.61768Z" fill="#18181B" />
    <path
      d="M16.25 2.86768C16.2496 2.53627 16.1178 2.21855 15.8835 1.98421C15.6491 1.74987 15.3314 1.61806 15 1.61768H2.5C2.1686 1.61806 1.85088 1.74987 1.61654 1.98421C1.3822 2.21855 1.25038 2.53627 1.25 2.86768V10.9927C1.24865 12.2392 1.58677 13.4625 2.22808 14.5313C2.86939 15.6001 3.78966 16.4742 4.89013 17.0596L8.125 18.7844V17.3677L5.4785 15.9567C4.5781 15.4777 3.82512 14.7625 3.30038 13.888C2.77564 13.0135 2.49895 12.0126 2.5 10.9927V2.86768H15V8.49268H16.25V2.86768Z"
      fill="#18181B" />
  </svg>
</template>

<style lang="scss" scoped>

</style>
<script lang="ts" setup>
</script>
