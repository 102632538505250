<template>
  <v-card-title class="mt-1 mb-2">
    <div
      :class="
        $vuetify.display.smAndUp ? 'align-center' : 'flex-column align-start'
      "
      class="d-flex justify-space-between">
      <div class="d-flex align-center gap-2">
        <h4>Завдання</h4>
        <v-chip
          v-if="isDeadlineClose && [1, 2].includes(task.statusId)"
          class="bg-red text-white"
          size="small"
          variant="flat">
          {{ deadlineText }}
        </v-chip>
      </div>
      <div class="d-flex align-center gap-2">
        <v-switch
          v-model="task.isCEO"
          :readonly="disabled"
          color="primary"
          hide-details
          single>
          <template #label>
            <span class="label mb-0">Задача від ГД</span>
          </template>
        </v-switch>
        <v-switch
          v-if="!taskType.familiarization"
          v-model="task.isHighImportance"
          :readonly="disabled"
          color="primary"
          hide-details
          single>
          <template #label>
            <span class="label mb-0">Важлива задача</span>
          </template>
        </v-switch>
        <v-btn-group
          color="surface"
          density="compact"
          divided
          rounded="xs"
          variant="flat">
          <v-tooltip text="Зв'язки">
            <template #activator="{ props }">
              <v-btn
                size="x-small"
                v-bind="props"
                @click="openTaskRelatedDialog">
                <connect-icon color="black" />
              </v-btn>
            </template>
          </v-tooltip>
          <ActionMenu
            v-if="!taskType.familiarization"
            :actions="actions.taskActions(task.$object)">
          </ActionMenu>
        </v-btn-group>
      </div>
    </div>
  </v-card-title>
</template>

<script lang="ts">
import ConnectIcon from '@/assets/images/icons/connect-icon.vue'
import { computed, inject } from 'vue'
import { setSnackbar } from 'best-modules/plugins'
import { useTaskActions } from '@/pages/tasks/helpers/taskActions'
import { openDialog } from '@/dialog'
import ActionMenu from '@/components/ActionMenu.vue'

export default {
  name: 'TaskHeader',
  components: { ActionMenu, ConnectIcon },
  props: {
    modelValue: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: { setSnackbar },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const actions = useTaskActions()
    const task = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        emit('update:model-value', v)
      },
    })
    const taskType = inject(
      'taskType',
      computed(() => ({}))
    )

    const openTaskRelatedDialog = (onClose = () => false) => {
      openDialog({
        name: 'RelatedTask',
        dialogData: {
          task: task,
        },
        params: {
          hideActionButtons: true,
          cb() {
            onClose()
          },
        },
      })
    }

    const now = computed(() => new Date())

    const deadlineDate = computed(() => {
      if (!task.value?.deadline) return null

      const [datePart, timePart] = task.value.deadline.split(', ')
      const [day, month, year] = datePart.split('.').map(Number)
      const [hours, minutes] = timePart.split(':').map(Number)

      const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(
        day
      ).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}:00`

      const parsedDate = new Date(formattedDate)

      return isNaN(parsedDate.getTime()) ? null : parsedDate
    })

    const isOverdue = computed(() => {
      if (!deadlineDate.value) return false
      return deadlineDate.value.getTime() < now.value.getTime()
    })

    const isDeadlineClose = computed(() => {
      if (!deadlineDate.value) return false

      const timeDiff = deadlineDate.value.getTime() - now.value.getTime()

      return isOverdue.value || timeDiff <= 24 * 60 * 60 * 1000
    })

    const deadlineText = computed(() => {
      if (!deadlineDate.value) return ''
      return isOverdue.value
        ? 'Очікує виконання, протермінована'
        : `Сьогодні, до ${deadlineDate.value.getHours()}:${String(
            deadlineDate.value.getMinutes()
          ).padStart(2, '0')}`
    })

    return {
      task,
      actions,
      isDeadlineClose,
      deadlineText,
      openTaskRelatedDialog,
      taskType,
    }
  },
}
</script>
