<template>
  <v-card class="mt-3 shadow-1" rounded="lg">
    <v-card-title class="mt-1 mb-2">
      <div class="d-flex align-center justify-space-between">
        <h4>Пов'язана кореспонденція</h4>
        <v-btn
          color="primary"
          density="comfortable"
          icon
          rounded="lg"
          size="small"
          @click="addRelatedCorrespondence">
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <div v-if="correspondence.related_correspondences?.length">
        <CustomItem
          v-for="c in correspondence.related_correspondences"
          :key="c.id"
          :item="c"
          :length="correspondence.related_correspondences?.length"
          hidePost>
          <template #actions>
            <v-btn
              icon
              size="x-small"
              variant="text"
              @click="removeRelatedCorrespondence(c.id)">
              <v-icon icon="mdi-delete-forever" size="large" />
            </v-btn>
          </template>
        </CustomItem>
      </div>
      <ContentNotFound v-else />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import ContentNotFound from '@/components/ContentNotFound.vue'
import { openDialog } from '@/dialog'
import { correspondence } from '@/pages/correspondence/single/correspondence'
import { computed } from 'vue'
import axios from '@/plugins/axios'
import { urlCorrespondenceUnrelated } from '@/utils/urls'
import CustomItem from '@/dialog/components/templateGroup/CustomItem.vue'

export default {
  name: 'RelatedCorrespondence',
  emits: ['update:correspondence'],
  components: { CustomItem, ContentNotFound },
  props: {
    correspondence: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const correspondenceData = computed({
      get() {
        return props.correspondence
      },
      set(v) {
        emit('update:correspondence', v)
      },
    })
    const addRelatedCorrespondence = () => {
      openDialog({
        name: 'RelatedCorrespondence',
        dialogData: {
          correspondenceId: correspondence.id,
        },
        params: {
          cb: res => {
            correspondenceData.value.related_correspondences =
              res.related_correspondences
          },
        },
      })
    }

    const removeRelatedCorrespondence = relatedId => {
      return axios
        .post(urlCorrespondenceUnrelated(correspondence.id), {
          relatedId,
        })
        .then(() => {
          correspondenceData.value.related_correspondences =
            correspondenceData.value.related_correspondences.filter(
              c => c.id !== relatedId
            )
        })
    }

    return {
      addRelatedCorrespondence,
      removeRelatedCorrespondence,
    }
  },
}
</script>

<style lang="scss" scoped></style>
