<template>
  <div>
    <sign-local
      v-if="['file', 'media'].includes(keyType)"
      :keyType="keyType"
      @cancel="keyType = null"></sign-local>
    <sign-cloud
      v-if="keyType === 'cloud'"
      @cancel="keyType = null"></sign-cloud>
    <key-type-select v-if="!keyType" v-model="keyType"></key-type-select>
  </div>
</template>

<script lang="ts">
import SignLocal from './sign-local/SignLocal.vue'
import SignCloud from './sign-cloud/SignCloud.vue'
import KeyTypeSelect from './KeyTypeSelect.vue'
import { computed, PropType, ref, provide } from 'vue'
import { getErrorMessages } from 'best-modules/utils'
import { Dialog } from 'best-modules/plugins'
import {
  AuthorizationKey,
  isClientKey,
  dialogKey,
  fileToSignKey,
} from './injectionKeys'
import { getFile } from '@/utils/helpers'
import { KeyType, FileToSign } from './types'

export default {
  name: 'SignDocument',
  components: {
    SignLocal,
    SignCloud,
    KeyTypeSelect,
  },
  props: {
    dialog: {
      type: Object as PropType<Dialog<'SignDocument'>>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const keyType = ref<KeyType>(null)
    const isClient = computed(() => {
      return props.dialog.dialogData.entityType === 'client'
    })
    const Authorization = computed<string | null>(() => {
      if (props.dialog.dialogData.authToken) {
        return `Bearer ${props.dialog.dialogData.authToken}`
      } else {
        return null
      }
    })
    const fileToSign = ref<FileToSign[]>([])

    for (const document of props.dialog.dialogData.documents) {
      getFile({
        path: document.document[0].path,
        type: 'blob',
        token: Authorization.value,
      }).then(async (blob: Blob) => {
        fileToSign.value.push({
          file: new Uint8Array(await blob.arrayBuffer()),
          id: document.id,
          number: document.number,
          fileName: document.document[0].name,
        })
      })
    }

    provide(isClientKey, isClient)
    provide(AuthorizationKey, Authorization)
    provide(dialogKey, props.dialog)
    provide(fileToSignKey, fileToSign)

    return {
      keyType,
    }
  },
}
</script>

<style lang="scss" scoped>
.how-work {
  background: #514ef30a;
  padding: 12px 20px;
  border-radius: 8px;
  border-left: 4px solid #7e92a2;
  &-container {
    gap: 15px;
    display: inline-grid;
  }
}
</style>
