<template>
  <div class="d-flex align-center flex-column text-center cursor-default">
    <NotFoundPicture class="mb-3" />
    <span class="label d-block text-disabled">
      {{ text || 'Інформацію не знайдено' }}
    </span>
  </div>
</template>

<script lang="ts">
import NotFoundPicture from '@/assets/images/NotFoundPicture.vue'

export default {
  name: 'ContentNotFound',
  components: { NotFoundPicture },
  props: {
    text: { type: String },
  },
}
</script>

<style lang="scss" scoped></style>
