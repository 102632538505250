<template>
  <div>
    <Tree :tree="currentOrders" />
  </div>
</template>

<script>
import Tree from '@/components/tree/index.vue'
import { computed } from 'vue'
import { mapKeys } from 'lodash'

export default {
  components: {
    Tree,
  },
  props: {
    dialog: {
      type: Object,
    },
  },
  setup(props) {
    const prepareOrders = (orders, childrenKey) => {
      return orders.map(o => {
        o = mapKeys(o, (value, key) => {
          if (key === childrenKey) {
            return 'children'
          } else {
            return key
          }
        })
        if (o.children.length) {
          o.children = prepareOrders(o.children, childrenKey)
        }

        return o
      })
    }

    const currentOrders = computed(() => {
      return prepareOrders(
        props.dialog.dialogData.nonActualOrders,
        'not_actual_orders'
      )
    })

    return { currentOrders }
  },
}
</script>

<style lang="scss" scoped></style>
