<template>
  <div>
    <div v-if="items && items.length">
      <v-btn
        v-for="item in items"
        :key="item.id"
        class="link"
        variant="text"
        @click="navigateToItem(routeName, item.id)">
        {{ itemLabel(item) }}
      </v-btn>
    </div>
    <ContentNotFound v-else />
  </div>
</template>

<script>
import { navigateToItem } from '@/utils/helpers'
import ContentNotFound from '@/components/ContentNotFound.vue'

export default {
  name: 'ContractorContent',
  components: { ContentNotFound },
  props: {
    dialog: { type: Object },
  },
  computed: {
    items() {
      const action = this.dialog.dialogData?.action
      return action === 'documents'
        ? this.dialog.dialogData?.contractor?.documents
        : action === 'tasks'
        ? this.dialog.dialogData?.contractor?.tasks
        : []
    },
    routeName() {
      return this.dialog.dialogData?.action === 'documents'
        ? 'single-document'
        : 'single-task'
    },
  },
  methods: {
    navigateToItem,
    itemLabel(item) {
      return this.dialog.dialogData?.action === 'documents'
        ? item.name
        : item.topic
    },
  },
}
</script>

<style lang="scss" scoped></style>
