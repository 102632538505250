<template>
  <div>
    <v-data-table
      v-if="tasks.length"
      :disable-pagination="true"
      :headers="correspondenceTaskHeaders"
      :hide-default-footer="true"
      :hide-default-header="!tasks.length"
      :items="tasks"
      :items-per-page="-1"
      dense
      @dblclick:row="openRow">
      <template #bottom></template>
      <template #item.responsible="{ item }">
        {{ getUserName(item.author, { initials: true }) }}
        {{ ` (${item.author?.post || 'Посада відсутня'})` }}
      </template>
      <template #item.actions="{ item }">
        <TableActions
          :actions="[
            {
              label: 'Відкріпити задачу',
              action: () => removeTask(item.id),
              icon: 'mdi-delete-forever',
            },
          ]" />
      </template>
    </v-data-table>
    <ContentNotFound v-else />
  </div>
</template>

<script lang="ts">
import { axios } from '@/plugins'
import TableActions from '@/components/TableActions.vue'
import { urlTaskUpdate } from '@/utils/urls'
import { setSnackbar } from 'best-modules/plugins'
import { ref } from 'vue'
import { getUserName, navigateToItem } from '@/utils/helpers'
import { correspondenceTaskHeaders } from '@/utils/tableHeaders'
import ContentNotFound from '@/components/ContentNotFound.vue'

export default {
  name: 'Tasks',
  components: { ContentNotFound, TableActions },
  props: {
    dialog: { type: Object },
  },
  data: () => ({
    correspondenceTaskHeaders,
  }),
  methods: { getUserName },
  setup(props) {
    const tasks = ref(props.dialog.dialogData?.correspondence.tasks || [])

    const removeTask = taskId => {
      return axios
        .post(urlTaskUpdate(taskId), {
          entityId: null,
          entityTypeId: null,
        })
        .then(res => {
          setSnackbar({
            color: 'success',
            text: 'Задача успішно відкріплена',
          })
          const filteredTasks = tasks.value.filter(item => item.id !== taskId)
          tasks.value = filteredTasks
          return res
        })
    }
    const openRow = (e, row) => {
      navigateToItem('single-task', row.item.id)
    }
    return {
      removeTask,
      tasks,
      openRow,
    }
  },
}
</script>
