<template>
  <v-card>
    <v-card-text>
      <b-section-loader v-if="initLoading" />
      <div v-else>
        <Title title="Накази">
          <template #actions>
            <div class="d-flex align-center">
              <v-btn-group
                color="surface"
                density="compact"
                divided
                rounded="xs"
                variant="flat">
                <v-btn
                  class="mr-3"
                  color="primary"
                  rounded="lg"
                  @click="createOrderTask">
                  Створити доручення
                  <v-icon class="ml-2" color="white">mdi-plus</v-icon>
                </v-btn>
                <ActionMenu
                  :actions="[
                    {
                      icon: 'mdi-delete-forever',
                      label: 'Видалити наказ',
                      action: deleteOrder,
                      disabled: false,
                    },
                  ]"></ActionMenu>
              </v-btn-group>
            </div>
          </template>
        </Title>
        <div class="d-flex align-center justify-space-between">
          <v-tabs
            color="primary"
            hide-slider
            style="border-bottom: 1px solid #e0e4e7">
            <v-tab
              :to="{
                name: 'order-general-info',
                params: { id: $route.params.id },
              }"
              replace>
              Основне
            </v-tab>
            <v-tab
              :to="{ name: 'order-access', params: { id: $route.params.id } }"
              replace>
              Доступність
            </v-tab>
            <v-tab
              :to="{
                name: 'order-connections',
                params: { id: $route.params.id },
              }"
              replace>
              Зв'язки
            </v-tab>
            <v-tab
              :to="{ name: 'order-history', params: { id: $route.params.id } }"
              replace>
              Історія
            </v-tab>
            <v-tab
              :to="{ name: 'order-tasks', params: { id: $route.params.id } }"
              replace>
              Доручення
            </v-tab>
            <v-tab
              :to="{
                name: 'order-familiarization',
                params: { id: $route.params.id },
              }"
              replace>
              Ознайомлення
            </v-tab>
          </v-tabs>
          <div class="d-flex align-center gap-3">
            <v-menu v-if="!order.isActive" open-on-hover>
              <template #activator="{ props }">
                <v-btn
                  color="grey"
                  density="compact"
                  icon
                  v-bind="props"
                  variant="plain">
                  <v-icon>mdi-information-variant</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <div class="mb-2 d-flex align-center gap-2">
                    Актуальний наказ:
                    <span
                      v-if="order.actual_order"
                      class="link"
                      style="
                        display: inline-block;
                        max-width: 300px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      ">
                      {{ order.actual_order.name }}
                    </span>
                    <span v-else> --- </span>
                  </div>
                  <div>Причина відмови: {{ order.deactivateComment }}</div>
                </v-card-text>
              </v-card>
            </v-menu>
            <div
              class="d-flex align-center gap-2 order__btn"
              @click="activityHandler">
              <v-progress-circular
                v-if="$loading.isLoading('activateOrder')"
                :size="18"
                :width="2"
                indeterminate></v-progress-circular>
              <span
                v-else
                :class="order.isActive ? 'green' : 'red'"
                class="mr-1 order__rounded"></span>
              <span
                :class="order.isActive ? 'order__active' : 'order__deactivate'">
                {{ order.isActive ? 'Активний' : 'Неактивний' }}
              </span>
            </div>
          </div>
        </div>
        <Skeleton v-if="initLoading" />
        <RouterView
          v-else
          :order="order"
          @update:order="Object.assign(order, $event)" />
        <div v-show="$route.name !== 'order-access'" class="d-flex justify-end">
          <SubmitButtons
            :disabled="!order.$hasChanges"
            :loading="submitLoading || initLoading"
            cancelLabel="Відмінити"
            class="mt-3"
            compact
            submitLabel="Оновити"
            @cancel="cancel"
            @submit="submit" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue'
import SubmitButtons from '@/components/SubmitButtons.vue'
import { useOrder } from './order.js'
import Title from '@/components/Title.vue'
import { getFullName, navigateToItem } from '@/utils/helpers'
import { axios } from '@/plugins'
import { urlActivateOrder, urlOrderDelete } from '@/utils/urls'
import router from '@/router'
import { confirm } from '@/plugins/confirm/index.ts'
import { ref } from 'vue'
import { openDialog } from '@/dialog/index.ts'
import { handleAsync } from 'best-modules/plugins'
import { BSectionLoader } from 'best-modules/components'
import ActionMenu from '@/components/ActionMenu.vue'

export default {
  components: {
    ActionMenu,
    Title,
    Skeleton,
    SubmitButtons,
    BSectionLoader,
  },
  methods: { getFullName },
  setup() {
    const { order, submit, initLoading, cancel, submitLoading } = useOrder()

    const deleteOrder = () => {
      confirm({
        text: 'Ви впевненні що хочете видалити наказ на завжди?',
      }).then(() => {
        return axios.delete(urlOrderDelete(order.id)).then(() => {
          return router.push({ name: 'order-list' })
        })
      })
    }

    const orderActiveLoading = ref(false)

    const activityHandler = () => {
      if (order.isActive) {
        return openDeactivateOrderDialog()
      } else {
        return activateOrder()
      }
    }

    const activateOrder = () => {
      return handleAsync('activateOrder', () => {
        return axios.get(urlActivateOrder(order.id)).then(res => {
          order.$set(res.data)
        })
      })
    }

    const createOrderTask = () => {
      openDialog({
        name: 'CreateTask',
        dialogData: {
          taskData: {
            typeId: 3,
            entityTypeId: 3,
            entityId: order.id,
            name: order?.name,
          },
        },
        params: {},
      })
    }

    const openDeactivateOrderDialog = () => {
      openDialog({
        name: 'OrderDeactivate',
        dialogData: {
          orderId: order.id,
        },
        params: {
          cb(updatedOrder) {
            order.$set(updatedOrder)
          },
        },
      })
    }
    return {
      navigateToItem,
      deleteOrder,
      orderActiveLoading,
      activityHandler,
      order,
      submit,
      cancel,
      submitLoading,
      initLoading,
      createOrderTask,
    }
  },
}
</script>

<style lang="scss" scoped>
.order {
  &__btn {
    background: transparent;
    transition: 0.3s;
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #e1e1e1;
    }
  }

  &__active {
    color: #35ab8f;
    font-size: 14px;
    font-weight: 600;
  }

  &__deactivate {
    color: #ff8473;
    font-size: 14px;
    font-weight: 600;
  }

  &__rounded {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;

    &.green {
      background: #35ab8f;
    }

    &.red {
      background: #ff8473;
    }
  }
}
</style>
