<template>
  <ExpansionPanel
    :label="item.name"
    :style="{ marginLeft: `${props.level * 5}px` }"
    department>
    <template #default>
      <!-- supervisor -->
      <div v-if="item.supervisor">
        <ExpansionPanel :key="item.supervisor.id" collapsedEmpty department>
          <template #prepend-label>
            <div
              :class="{ selected: isSelected(item.supervisor.id) }"
              class="selectable-block"
              @click="toggleUser(item.supervisor.id)">
              <div class="d-flex flex-column">
                <div class="d-flex align-center">
                  <BaseImage
                    v-if="item?.supervisor?.photo"
                    :src="item.supervisor.photo"
                    rounded
                    width="30" />
                  <strong class="font-size-13 ml-2">
                    {{
                      getPersonName(item.supervisor, {
                        keyNames: { surname: 'secondName' },
                      })
                    }}
                  </strong>
                </div>
                <span
                  :class="{
                    'text-white': isSelected(item.supervisor.id),
                    'text-black': !isSelected(item.supervisor.id),
                  }"
                  class="mt-1">
                  {{ item.supervisor.post }}
                </span>
              </div>
              <v-icon v-if="isSelected(item.supervisor.id)" class="ml-2">
                mdi-check
              </v-icon>
            </div>
          </template>
        </ExpansionPanel>
      </div>

      <!-- employees -->
      <div v-if="item.employees?.length">
        <ExpansionPanel
          v-for="employee in item.employees"
          :key="employee.id"
          collapsedEmpty
          department>
          <template #prepend-label>
            <div
              :class="{ selected: isSelected(employee.id) }"
              class="selectable-block"
              @click="toggleUser(employee.id)">
              <div class="d-flex flex-column">
                <div class="d-flex align-center">
                  <BaseImage
                    v-if="employee?.photo"
                    :src="employee.photo"
                    rounded
                    width="30" />
                  <strong class="font-size-13 ml-2">
                    {{
                      getPersonName(employee, {
                        keyNames: { surname: 'secondName' },
                      })
                    }}
                  </strong>
                </div>
                <span
                  :class="{
                    'text-white': isSelected(employee.id),
                    'text-black': !isSelected(employee.id),
                  }"
                  class="mt-1">
                  {{ employee.post }}
                </span>
              </div>
              <v-icon v-if="isSelected(employee.id)" class="ml-2"
                >mdi-check
              </v-icon>
            </div>
          </template>
        </ExpansionPanel>
      </div>

      <div v-if="item.all_children?.length">
        <DepartmentTree
          v-for="child in item.all_children"
          :key="child.id"
          :dialog="dialog"
          :handle-user="handleUser"
          :item="child"
          :level="(props.level || 0) + 1"
          :selected-user-value="selectedUserValue" />
      </div>
    </template>
  </ExpansionPanel>
</template>

<script setup>
import ExpansionPanel from '@/components/expansion-panel/Expansion-panel.vue'
import { getPersonName } from 'best-modules/utils/helpers'

const props = defineProps({
  item: Object,
  dialog: Object,
  selectedUserValue: [Array, Number],
  handleUser: Function,
  level: {
    type: Number,
    default: 0,
  },
})

const isSelected = id => {
  return Array.isArray(props.selectedUserValue)
    ? props.selectedUserValue.includes(id)
    : props.selectedUserValue === id
}

const toggleUser = id => {
  props.handleUser(id)
}
</script>

<style scoped>
.selectable-block {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  transition: background 0.2s;
  cursor: pointer;
  width: 100%;
  font-size: 11px;
  justify-content: space-between;
  background: #b7dbff57;
}

.selected {
  background: #3d5771;
  color: white;
}
</style>
