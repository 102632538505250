import { RouterView } from 'vue-router'
import MainLayout from '@/layout/MainLayout.vue'
import HomePage from '@/pages/home/HomePage.vue'
import DocumentIndex from '@/pages/documents/Index.vue'
import DocumentList from '@/pages/documents/DocumentList.vue'
import DocumentSingle from '@/pages/documents/single-document/DocumentSingle.vue' // eslint-disable-line max-len
import RecentPage from '@/pages/recent/RecentPage.vue'
import OrderList from '@/pages/orders/Index.vue'
import OrderSingle from '@/pages/orders/single/Index.vue'
import OrderGeneralInfo from '@/pages/orders/single/tabs/GeneralInfo.vue'
import OrderAccess from '@/pages/orders/single/tabs/Access.vue'
import OrderConnections from '@/pages/orders/single/tabs/Connections.vue'
import OrderHistory from '@/pages/orders/single/tabs/History.vue'
import OrderTasks from '@/pages/orders/single/tabs/Tasks.vue'
import OrderFamiliarization from '@/pages/orders/single/tabs/Familiarization.vue' // eslint-disable-line max-len
import ContractorList from '@/pages/contractor/ContractorList.vue'
import ContractorSingle from '@/pages/contractor/single/ContractorSingle.vue'
import CompanyStructure from '@/pages/company-structure/company-structure/CompanyStructure.vue' // eslint-disable-line max-len
import UserSingle from '@/pages/company-structure/users/single/UserSingle.vue' // eslint-disable-line max-len
import UserList from '@/pages/company-structure/users/UserList.vue'
import CorrespondenceList from '@/pages/correspondence/CorrespondenceList.vue'
import CorrespondenceSingle from '@/pages/correspondence/single/CorrespondenceSingle.vue'
import TaskList from '@/pages/tasks/TaskList.vue'
import TaskSingle from '@/pages/tasks/TaskSingle.vue'
import UsersBirthdayList from '@/pages/company-structure/user-birthdays/UsersBirthdayList.vue'
import TemplateRoutingDocumentList from '@/pages/administration/template-routing/documents/TemplateRoutingDocumentList.vue'
import ControlList from '@/pages/tasks/ControlList.vue'
import ObserverList from '@/pages/tasks/ObserverList.vue'
import AuditList from '@/pages/audit/Index.vue'
import AuditSingle from '@/pages/audit/single/AuditSingle.vue'
import AbsenceIndex from '@/pages/absence/AbsenceIndex.vue'
import AbsenceDocumentIndex from '@/pages/absence/documents/AbsenceDocumentIndex.vue'
import AbsenceTaskIndex from '@/pages/absence/tasks/AbsenceTaskIndex.vue'

export default [
  {
    path: '/eds',
    name: 'eds',
    component: MainLayout,
    meta: {
      title: 'Головна',
      hide: true,
    },
    redirect: { name: 'home' },
    children: [
      {
        path: '/home',
        name: 'home',
        component: HomePage,
        meta: {
          title: 'Головна',
        },
      },
      {
        path: '/document-signature',
        name: 'document-signature-list',
        component: DocumentIndex,
        meta: {
          title: 'Підписання',
        },
        children: [
          {
            path: ':id',
            name: 'document-signature-single',
            component: DocumentSingle,
            meta: {
              title: 'Документ',
            },
          },
          {
            path: 'notes',
            name: 'document-signature-notes',
            component: DocumentList,
            meta: {
              title: 'Чернетки',
            },
          },
          {
            path: 'all',
            name: 'document-signature-all',
            component: DocumentList,
            meta: {
              title: 'Поточні',
            },
          },
          {
            path: 'signed',
            name: 'document-signature-signed',
            component: DocumentList,
            meta: {
              title: 'Підписані',
            },
          },
          {
            path: 'canceled',
            name: 'document-signature-canceled',
            component: DocumentList,
            meta: {
              title: 'Анульовані',
            },
          },
        ],
      },
      {
        path: '/document-agreement',
        name: 'document-agreement-list',
        component: DocumentIndex,
        meta: {
          title: 'Внутрішнє візування',
        },
        children: [
          {
            path: ':id',
            name: 'document-agreement-single',
            component: DocumentSingle,
            meta: {
              title: 'Документ',
            },
          },
          {
            path: 'new',
            name: 'document-agreement-new',
            component: DocumentList,
            meta: {
              title: 'Нові',
            },
          },
          {
            path: 'revision',
            name: 'document-agreement-revision',
            component: DocumentList,
            meta: {
              title: 'Доопрацювання',
            },
          },
          {
            path: 'me',
            name: 'document-agreement-me',
            component: DocumentList,
            meta: {
              title: 'Мені на візування',
            },
          },
          {
            path: 'agreed',
            name: 'document-agreement-agreed',
            component: DocumentList,
            meta: {
              title: 'Завізовані мною',
            },
          },
        ],
      },
      {
        path: '/recent',
        name: 'recent',
        component: RecentPage,
        meta: {
          title: 'Недавні документи',
        },
      },
      {
        path: '/orders',
        name: 'order-list',
        meta: {
          title: 'Накази',
        },
        children: [
          {
            path: '/order-list-all',
            name: 'order-list-all',
            component: OrderList,
            meta: {
              title: 'Всі накази',
            },
          },
          {
            path: '/document-familiarization',
            name: 'document-familiarization',
            component: RouterView,
            meta: {
              title: 'Ознайомлення',
            },
            redirect: { name: 'document-familiarization-all' },
            children: [
              {
                path: 'me',
                name: 'document-familiarization-me',
                component: OrderList,
                meta: {
                  title: 'Мені на ознайомлення',
                },
              },
              {
                path: 'familiarized',
                name: 'document-familiarization-familiarized',
                component: OrderList,
                meta: {
                  title: 'Ознайомлені',
                },
              },
            ],
          },
          {
            path: ':id',
            name: 'single-order',
            component: OrderSingle,
            meta: {
              title: 'Наказ',
              tabList: true,
            },
            redirect: { name: 'order-general-info' },
            children: [
              {
                path: 'general-info',
                name: 'order-general-info',
                component: OrderGeneralInfo,
                meta: {
                  title: 'Основне',
                  breadcrumbIdx: 2,
                },
              },
              {
                path: 'access',
                name: 'order-access',
                component: OrderAccess,
                meta: {
                  title: 'Доступність',
                  breadcrumbIdx: 2,
                },
              },
              {
                path: 'connections',
                name: 'order-connections',
                component: OrderConnections,
                meta: {
                  title: "Зв'язки",
                  breadcrumbIdx: 2,
                },
              },
              {
                path: 'history',
                name: 'order-history',
                component: OrderHistory,
                meta: {
                  title: 'Історія',
                  breadcrumbIdx: 2,
                },
              },
              {
                path: 'tasks',
                name: 'order-tasks',
                component: OrderTasks,
                meta: {
                  title: 'Історія',
                  breadcrumbIdx: 2,
                },
              },
              {
                path: 'familiarization',
                name: 'order-familiarization',
                component: OrderFamiliarization,
                meta: {
                  title: 'Ознайомлення',
                  breadcrumbIdx: 2,
                },
              },
            ],
          },
        ],
      },
      {
        path: '/contracts',
        name: 'contracts',
        component: DocumentList,
        meta: {
          title: 'Договори',
        },
      },
      {
        path: '/acts',
        name: 'acts',
        component: DocumentList,
        meta: {
          title: 'Акти',
        },
      },
      {
        path: '/protocols',
        name: 'protocols',
        component: DocumentList,
        meta: {
          title: 'Протоколи',
        },
      },
      {
        path: '/tasks',
        name: 'tasks',
        component: RouterView,
        meta: {
          title: 'Завдання',
        },
        redirect: { name: 'incoming-tasks' },
        children: [
          {
            path: 'incoming',
            name: 'incoming-tasks',
            component: TaskList,
            meta: {
              title: 'Вхідні завдання',
            },
          },
          {
            path: 'outgoing',
            name: 'outgoing-tasks',
            component: TaskList,
            meta: {
              title: 'Вихідні завдання',
            },
          },
          {
            path: 'control',
            name: 'control-tasks',
            component: ControlList,
            meta: {
              title: 'Контролюючі',
            },
          },
          {
            path: 'observer',
            name: 'observer-tasks',
            component: ObserverList,
            meta: {
              title: 'Наглядачі',
            },
          },
        ],
      },
      {
        path: '/task',
        name: 'task',
        component: RouterView,
        meta: {
          title: 'Завдання',
        },
        children: [
          {
            path: ':id',
            name: 'single-task',
            component: TaskSingle,
            meta: {
              title: 'Завдання',
            },
          },
        ],
      },
      {
        path: '/audit',
        name: 'audit-list',
        component: AuditList,
        meta: {
          title: 'Аудит',
        },
        children: [
          {
            path: ':id',
            name: 'audit-single',
            component: AuditSingle,
            meta: {
              title: 'Аудит',
            },
          },
        ],
      },
      {
        path: '/contractors',
        name: 'contractors',
        component: ContractorList,
        meta: {
          title: 'Контрагенти',
        },
      },
      {
        path: '/contractor/:id',
        name: 'single-contractor',
        component: ContractorSingle,
        meta: {
          title: 'Контрагент',
          tabList: true,
        },
      },
      {
        path: '/company-structure',
        name: 'company-structure',
        component: CompanyStructure,
        meta: {
          title: 'Структура компанії',
        },
      },
      {
        path: '/administration',
        name: 'administration',
        component: TemplateRoutingDocumentList,
        meta: {
          title: 'Адміністрування',
        },
      },
      {
        path: '/template-routing-document',
        name: 'template-routing-document',
        component: TemplateRoutingDocumentList,
        meta: {
          title: 'Маршрутизація документів',
        },
      },
      {
        path: '/users',
        name: 'users',
        component: UserList,
        meta: {
          title: 'Співробітники',
        },
      },
      {
        path: 'user/:id',
        name: 'single-user',
        component: UserSingle,
        meta: {
          title: 'Співробітник',
        },
      },
      {
        path: '/user-birthdays',
        name: 'user-birthdays',
        component: UsersBirthdayList,
        meta: {
          title: 'Дні народження',
        },
      },
      {
        path: '/correspondence',
        name: 'correspondence-list',
        component: CorrespondenceList,
        meta: {
          title: 'Кореспонденція',
        },
      },
      {
        path: 'correspondence/:id',
        name: 'correspondence-single',
        component: CorrespondenceSingle,
        meta: {
          title: 'Кореспонденція',
          tabList: true,
        },
      },
      {
        path: '/absence-user',
        name: 'absence-user',
        component: AbsenceIndex,
        meta: { title: 'Заміщення' },
        children: [
          {
            path: 'absence-document-agreement-me/:absenceId',
            name: 'absence-document-agreement-me-list',
            component: AbsenceDocumentIndex,
            meta: { title: 'На візуванні' },
            children: [
              {
                path: 'absence-document-agreement-single/:id',
                name: 'absence-document-agreement-single',
                component: DocumentSingle,
                meta: { title: 'Документ', tabList: true },
              },
            ],
          },
          {
            path: 'absence-document-signature-all/:absenceId',
            name: 'absence-document-signature-all-list',
            component: AbsenceDocumentIndex,
            meta: { title: 'На підписання' },
            children: [
              {
                path: 'absence-document-signature-single/:id',
                name: 'absence-document-signature-single',
                component: DocumentSingle,
                meta: { title: 'Заміщення | Документ', tabList: true },
              },
            ],
          },
          {
            path: 'absence-incoming-tasks/:absenceId',
            name: 'absence-incoming-tasks',
            component: AbsenceTaskIndex,
            meta: { title: 'Вхідні завдання' },
            children: [
              {
                path: 'absence-single-task/:id',
                name: 'absence-single-task',
                component: TaskSingle,
                meta: { title: 'Заміщення | Завдання', tabList: true },
              },
            ],
          },
          {
            path: 'absence-outgoing-tasks/:absenceId',
            name: 'absence-outgoing-tasks',
            component: AbsenceTaskIndex,
            meta: { title: 'Вхідні завдання' },
            children: [
              {
                path: 'absence-single-task/:id',
                name: 'absence-single-task',
                component: TaskSingle,
                meta: { title: 'Заміщення | Завдання', tabList: true },
              },
            ],
          },
        ],
      },
    ],
  },
]
