<template>
  <div>
    <DocumentPageTemplate :document="order.document">
      <template #header>
        <FilesUploader
          v-if="false"
          v-model="newFile"
          :disabled="initLoading || disabled"
          :multiple="false"
          :name-folder="order.number"
          :showFiles="false"
          confirmMsg
          main-folder="Приказы"
          @change="() => submit()" />
      </template>
      <template #info>
        <Skeleton v-if="initLoading" />
        <div v-else>
          <div v-if="currentFamiliarization" class="mb-3">
            <v-btn
              v-if="isFamiliarization"
              color="success"
              style="cursor: default; pointer-events: none; width: 100%">
              <v-icon class="mr-1">
                {{ 'mdi-check' }}
              </v-icon>
              <span>Ознайомлений</span>
            </v-btn>
            <v-btn
              v-else
              class="text-white"
              color="orange"
              style="width: 100%"
              @click="familiarizationAgree">
              <v-icon class="mr-1">
                {{ 'mdi-eye-check-outline' }}
              </v-icon>
              <span>Ознайомитись</span>
            </v-btn>
          </div>
          <InfoFields :fields="fields" class="mb-4"></InfoFields>
          <SimpleExpansionPanel label="Пов'язані накази">
            <template #content>
              <related-orders :order="order"></related-orders>
            </template>
          </SimpleExpansionPanel>
          <SimpleExpansionPanel label="Коментарі">
            <template #content>
              <Comments
                :comments="order.comments"
                :disabled="disabled"
                :entityId="order.id"
                :entityTypeId="3" />
            </template>
          </SimpleExpansionPanel>
          <SimpleExpansionPanel label="Примітки">
            <template #content>
              <Notes
                :disabled="disabled"
                :entityId="order.id"
                :entityTypeId="3"
                :notes="order.notes" />
            </template>
          </SimpleExpansionPanel>

          <v-btn
            class="mt-3 link"
            variant="text"
            @click="showFamiliarizationUsers">
            <v-icon class="mr-1">{{ 'mdi-eye-outline' }}</v-icon>
            <span>Учасники ознайомлення</span>
          </v-btn>
        </div>
      </template>
    </DocumentPageTemplate>
  </div>
</template>

<script>
import DocumentPageTemplate from '@/components/document-page-template/Index.vue'
import Skeleton from '@/components/Skeleton.vue'
import InfoFields from '@/components/InfoFields.vue'
import Comments from '@/components/Comments.vue'
import Notes from '@/components/Notes.vue'
import { useOrder } from './order.js'
import { computed } from 'vue'
import { useFamiliarization } from './familiarization.js'
import { navigateToItem } from '@/utils/helpers.js'
import { openDialog } from '@/dialog/index.ts'
import SimpleExpansionPanel from '@/components/expansion-panel/SimpleExpansionPanel.vue'
import RelatedOrders from './related-orders/RelatedOrders.vue'

export default {
  name: 'DocumentLayout',
  components: {
    DocumentPageTemplate,
    Skeleton,
    InfoFields,
    Comments,
    Notes,
    SimpleExpansionPanel,
    RelatedOrders,
  },
  setup() {
    const { order, initLoading, disabled, newFile, submit } = useOrder()

    const fields = computed(() => {
      return [
        {
          label: 'Номер',
          value: order.number,
        },
        {
          label: 'Дата',
          value: order.date,
        },
        {
          label: 'Назва',
          value: order.name,
        },
      ]
    })

    const { isFamiliarization, familiarizationAgree, currentFamiliarization } =
      useFamiliarization(order)

    const showFamiliarizationUsers = () => {
      openDialog({
        name: 'FamiliarizationUsers',
        dialogData: order.$object,
        params: {
          hideActionButtons: true,
        },
      })
    }

    const connectionOrders = computed(() => {
      return [order.actual_order, ...(order.related || [])].filter(Boolean)
    })

    return {
      order,
      initLoading,
      disabled,
      fields,
      newFile,
      submit,
      showFamiliarizationUsers,
      isFamiliarization,
      familiarizationAgree,
      currentFamiliarization,
      connectionOrders,
      navigateToItem,
    }
  },
}
</script>
