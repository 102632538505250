import { InjectionKey, ComputedRef, Ref } from 'vue'
import { Dialog } from 'best-modules/plugins'
import { FileToSign } from './types'

const isClientKey: InjectionKey<ComputedRef<boolean>> = Symbol('isClient')
const dialogKey: InjectionKey<Dialog<'SignDocument'>> = Symbol('dialog')
const AuthorizationKey: InjectionKey<ComputedRef<string | null>> =
  Symbol('Authorization')
const fileToSignKey: InjectionKey<Ref<FileToSign[]>> = Symbol('fileToSign')

export { isClientKey, AuthorizationKey, dialogKey, fileToSignKey }
