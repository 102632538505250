<template>
  <div class="h-75 cursor-default">
    <div v-if="!audit?.flaws?.length" class="h-100">
      <v-card class="h-100 shadow-1" rounded="lg">
        <v-card-text class="h-100">
          <div class="d-flex align-center justify-center flex-column h-100">
            <SearchIcon />
            <div class="text-center mt-5">
              <h2 class="mb-3">Факти недоліку відсутні</h2>
              <h4 v-if="audit.statusId === 1" class="label">
                Щоб додати факти недоліку, візьміть в роботу Аудит.
              </h4>
              <v-btn
                v-if="audit.statusId === 1"
                :loading="$loading.isLoading('takeToWork')"
                class="mt-5"
                density="comfortable"
                rounded="lg"
                width="140px"
                @click="takeToWork">
                Взяти в роботу
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <slot name="action-buttons"></slot>
    </div>
    <div v-else class="h-100">
      <div class="d-flex flex-column gap-2">
        <AuditExpansionPanel
          :audit="audit"
          :flaws="audit.flaws || []"
          @delete-flaw="onDeleteFlaw" />
      </div>
      <div class="pb-5">
        <slot name="action-buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import { auditKey, updateAuditKey } from './injectionKeys'
import SearchIcon from '@/assets/images/SearchIcon.vue'
import AuditExpansionPanel from '@/pages/audit/single/components/AuditExpansionPanel.vue'

export default {
  name: 'AuditFlawList',
  emits: ['take-to-work'],
  components: {
    AuditExpansionPanel,
    SearchIcon,
  },
  setup(_, { emit }) {
    const updateAudit = inject(updateAuditKey)
    const audit = computed({
      get: () => inject(auditKey),
      set: v => updateAudit(v),
    })

    const takeToWork = () => {
      return emit('take-to-work')
    }

    const onDeleteFlaw = flawId => {
      const updatedFlaws = audit.value.flaws.filter(flaw => flaw.id !== flawId)
      updateAudit({ ...audit.value, flaws: updatedFlaws })
    }

    return { audit, takeToWork, onDeleteFlaw }
  },
}
</script>

<style lang="scss" scoped></style>
