<template>
  <history-list :url="computedUrl"></history-list>
</template>

<script lang="ts">
import { computed } from 'vue'
import HistoryList from '@/components/history/HistoryList.vue'
import { urlOrderHistory } from '@/utils/urls'

export default {
  name: 'History',
  components: { HistoryList },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const computedUrl = computed(() => urlOrderHistory(props.order.id))

    return { computedUrl }
  },
}
</script>
