<template>
  <div :key="componentKey">
    <TaskList v-if="route.name === 'absence-incoming-tasks'" />
    <router-view v-else></router-view>
  </div>
</template>

<script lang="ts">
import { shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import TaskList from '@/pages/tasks/TaskList.vue'

export default {
  name: 'AbsenceTaskIndex',
  components: { TaskList },
  setup() {
    const route = useRoute()
    const componentKey = shallowRef(0)

    watch(
      () => route.params.absenceId,
      () => {
        componentKey.value++
      }
    )

    return {
      route,
      componentKey,
    }
  },
}
</script>
