<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 17 20"
    width="17"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19572 0C0.519877 0 0 0.571865 0 1.19572V18.2477C0 18.9235 0.571865 19.4434 1.19572 19.4434H15.8043C16.4801 19.4434 17 18.9235 17 18.2477V5.45872L11.5413 0H1.19572Z"
      fill="#8E92BC" />
    <path
      d="M16.9997 5.45872H12.7367C12.0609 5.45872 11.541 4.93884 11.541 4.263V0L16.9997 5.45872Z"
      fill="#767995" />
    <path
      d="M4.05518 7.27832H5.30288C6.18667 7.27832 6.39463 7.69422 6.39463 8.37006V9.87771C6.39463 10.5536 6.0827 10.9695 5.30288 10.9695H4.88698V13.6728H4.05518V7.27832ZM5.25089 10.1896C5.45885 10.1896 5.56282 10.0857 5.56282 9.87771V8.42205C5.56282 8.2141 5.45885 8.11012 5.25089 8.11012H4.88698V10.1896H5.25089Z"
      fill="white" />
    <path
      d="M9.09764 11.6973C9.04565 10.9175 9.04565 10.0857 9.04565 9.30584V7.27832H9.82546V13.6728H9.04565L7.9539 9.25386C8.00589 9.98168 8.00589 10.8135 8.00589 11.6453V13.6728H7.22607V7.27832H8.05788L9.09764 11.6973Z"
      fill="white" />
    <path
      d="M11.8534 10.0336H13.0492V12.581C13.0492 13.2569 12.7892 13.7248 11.8534 13.7248C10.9177 13.7248 10.6577 13.2569 10.6577 12.581V8.31805C10.6577 7.64221 10.9177 7.17432 11.8534 7.17432C12.7892 7.17432 13.0492 7.64221 13.0492 8.31805V9.09786H12.2173V8.31805C12.2173 8.1101 12.1134 7.95413 11.9054 7.95413C11.6455 7.95413 11.5935 8.1101 11.5935 8.31805V12.581C11.5935 12.841 11.6975 12.9969 11.9054 12.9969C12.1654 12.9969 12.2173 12.841 12.2173 12.581V10.8135H11.9054V10.0336H11.8534Z"
      fill="white" />
  </svg>
</template>

<style lang="scss" scoped></style>
