<template>
  <div>
    <v-tooltip bottom text="Експортувати список">
      <template #activator="{ props }">
        <v-btn
          :loading="$loading.isLoading('handleExport')"
          color="primary"
          rounded="lg"
          v-bind="props"
          variant="outlined"
          @click.stop="handleExport">
          <v-icon class="mr-1">mdi-printer</v-icon>
          {{ buttonText }}
        </v-btn>
      </template>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { download } from 'best-modules/utils'

export default {
  name: 'ExportPfForm',
  props: {
    url: { type: Function, required: true },
    buttonText: { type: String, required: true },
    exportFileName: { type: String, required: true },
  },
  setup(props) {
    const handleExport = () => {
      return handleAsync('handleExport', () => {
        return axios.get(props.url()).then(res => {
          if (res.data.message) {
            return setSnackbar({
              text: res.data.message,
              color: 'warning',
            })
          }
          return download(res.data.url, props.exportFileName)
        })
      })
    }

    return {
      handleExport,
    }
  },
}
</script>

<style scoped></style>
