<template>
  <div class="h-100 cursor-default">
    <v-card class="h-100 shadow-1" rounded="lg">
      <v-card-text>
        <div>
          <h2 class="mb-3">Деталі</h2>
          <div class="mb-3 font-weight-bold">
            <span v-tooltip="audit.name">{{ audit.name }}</span>
          </div>
          <div class="mb-2">
            <span class="label">Дата аудиту</span>
            <b-input-date
              v-model:end-date="audit.endAuditDate"
              v-model:start-date="audit.startAuditDate"
              :error-messages="
                getErrorMessages(v$.startAuditDate).concat(
                  getErrorMessages(v$.endAuditDate)
                )
              "
              :readonly="audit.statusId === 3"
              hide-details
              range></b-input-date>
          </div>
          <div class="mb-3">
            <span class="label">Планова дата аудиту</span>
            <b-input-date
              v-model:end-date="audit.endScheduledCheckDate"
              v-model:start-date="audit.startScheduledCheckDate"
              :error-messages="
                getErrorMessages(v$.startScheduledCheckDate).concat(
                  getErrorMessages(v$.endScheduledCheckDate)
                )
              "
              :readonly="audit.statusId === 3"
              hide-details
              range></b-input-date>
          </div>
          <div class="gap-2 d-flex flex-column">
            <div class="d-flex justify-space-between align-center">
              <span class="label">Тип</span>
              <span class="font-weight-bold">{{ audit?.type?.name }}</span>
            </div>
            <div class="d-flex justify-space-between align-center">
              <span class="label">Статус</span>
              <v-chip
                :color="
                  audit?.status && audit?.status?.id === 2
                    ? 'yellow-lighten-3'
                    : audit?.status?.id === 3
                    ? 'green'
                    : 'grey'
                "
                size="small"
                variant="flat">
                <template #default>
                  <span
                    :style="{
                      color:
                        audit?.status && audit?.status?.id === 2
                          ? '#CA8A04'
                          : '',
                    }">
                    {{ audit?.status?.name }}
                  </span>
                </template>
              </v-chip>
            </div>
            <div
              v-if="audit.statusId === 3"
              class="d-flex justify-space-between align-center">
              <span class="label">Оцінка внутрішнього контролю</span>
              <span class="font-weight-bold">{{
                audit?.internal_control_assessment?.name || '-'
              }}</span>
            </div>
            <div class="d-flex justify-space-between align-center">
              <span class="label">Рік</span>
              <span class="font-weight-bold">{{ audit.year }}</span>
            </div>
            <div class="d-flex justify-space-between align-center">
              <span class="label">Початок виконання</span>
              <span class="font-weight-bold">{{
                audit.startCheckDate || '-'
              }}</span>
            </div>
            <div class="d-flex justify-space-between align-center">
              <span class="label">Завершення виконання</span>
              <span class="font-weight-bold">{{
                audit.endCheckDate || '-'
              }}</span>
            </div>
          </div>
          <div v-if="audit.statusId === 2" class="mt-5">
            <v-btn
              color="red-lighten-1"
              density="comfortable"
              rounded="lg"
              @click="completeAudit">
              Завершити
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import { auditKey, updateAuditKey, v$Key } from './injectionKeys'
import { getErrorMessages } from 'best-modules/utils'
import { BInputDate } from 'best-modules/components'

export default {
  name: 'AuditSidebar',
  components: { BInputDate },
  methods: { getErrorMessages },
  emits: ['update:audit', 'completeAudit'],
  setup(_, { emit }) {
    const updateAudit = inject(updateAuditKey)

    const audit = computed({
      get: () => inject(auditKey),
      set: v => updateAudit(v),
    })

    const v$ = inject(v$Key)

    const completeAudit = () => {
      return emit('completeAudit')
    }

    return { audit, updateAudit, v$, completeAudit }
  },
}
</script>

<style lang="scss" scoped></style>
