<script lang="ts">

export default {
  name: "OrderIcon"
};
</script>

<template>
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0625 6.18018L11.6875 1.80518C11.5625 1.68018 11.4375 1.61768 11.25 1.61768H5C4.3125 1.61768 3.75 2.18018 3.75 2.86768V17.8677C3.75 18.5552 4.3125 19.1177 5 19.1177H15C15.6875 19.1177 16.25 18.5552 16.25 17.8677V6.61768C16.25 6.43018 16.1875 6.30518 16.0625 6.18018ZM11.25 3.11768L14.75 6.61768H11.25V3.11768ZM15 17.8677H5V2.86768H10V6.61768C10 7.30518 10.5625 7.86768 11.25 7.86768H15V17.8677Z"
      fill="#18181B" />
    <path d="M13.75 14.1177H6.25V15.3677H13.75V14.1177Z" fill="#18181B" />
    <path d="M13.75 10.3677H6.25V11.6177H13.75V10.3677Z" fill="#18181B" />
  </svg>
</template>

<style lang="scss" scoped>

</style>
