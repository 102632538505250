<template>
  <DocumentPageTemplate
    :key="updateComponent"
    :document="document.document"
    :fullWidth="fullWidth"
    :loading="$loading.isLoading('getDocument')"
    :zoomPercentage="zoomPercentage">
    <template #top-header>
      <div class="d-flex align-center gap-1">
        <v-btn-group density="compact" rounded="lg" variant="outlined">
          <v-tooltip text="Редагувати">
            <template #activator="{ props }">
              <v-btn
                size="x-small"
                v-bind="props"
                @click="openEditDocumentDialog">
                <v-icon color="primary" size="large">mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip
            v-if="documentAction === 'signature'"
            text="Завантажити протокол підписання">
            <template #activator="{ props }">
              <v-btn size="x-small" v-bind="props" @click="downloadProtocol()">
                <v-icon color="primary" size="large">mdi-file-sign</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip
            v-if="documentAction === 'signature'"
            text="Завантажити архів">
            <template #activator="{ props }">
              <v-btn size="x-small" v-bind="props" @click="downloadArchive()">
                <v-icon color="primary" size="large">mdi-folder-zip</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <!-- done -->
          <v-tooltip
            v-if="
              documentAction === 'signature' &&
              [2, 3].includes(document.status.id) &&
              !document.related_document_agreement
            "
            text="Відправити на візування">
            <template #activator="{ props }">
              <v-btn
                :loading="$loading.isLoading('sendToAgree')"
                size="x-small"
                v-bind="props"
                @click="sendToAgree">
                <v-icon color="primary" size="large">mdi-share</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip
            v-if="
              documentAction === 'agreement' &&
              document?.status?.id === 4 &&
              !document.related_document_signature
            "
            text="Відправити на підписання">
            <template #activator="{ props }">
              <v-btn
                :loading="$loading.isLoading('sendToSign')"
                size="x-small"
                v-bind="props"
                @click="sendToSign">
                <v-icon color="primary" size="large">mdi-share</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </v-btn-group>
        <FilesUploader
          v-model="newFile"
          :multiple="false"
          :name-folder="document.number"
          :readonly="
            !isAuthor ||
            (documentAction === 'agreement'
              ? ![1, 2].includes(document?.status?.id ?? 0)
              : (document?.status?.id ?? 0) !== 1)
          "
          :showFiles="false"
          confirmMsg
          main-folder="Документы"
          @change="updateDocument" />
      </div>
    </template>
    <template #header>
      <v-tooltip
        v-if="document && document.document && document.document[0]?.name"
        :text="document && document.document && document.document[0]?.name"
        bottom>
        <template #activator="{ props }">
          <div class="document-name">
            <span v-bind="props">{{
              (document && document.document && document.document[0]?.name) ||
              'Назва не вказана'
            }}</span>
          </div>
        </template>
      </v-tooltip>
      <div class="d-flex align-center document-width">
        <v-btn icon size="x-small" variant="text" @click="zoomOut">
          <v-icon>{{ 'mdi-minus' }}</v-icon>
        </v-btn>
        <div class="document-width__text">
          <span>{{ zoomPercentage }}%</span>
        </div>
        <v-btn icon size="x-small" variant="text" @click="zoomIn">
          <v-icon>{{ 'mdi-plus' }}</v-icon>
        </v-btn>
      </div>
      <div class="d-flex align-center">
        <v-btn icon size="small" variant="text" @click="setFullWidth">
          <v-icon v-if="!fullWidth" icon="best:width" size="large" />
          <v-icon v-else style="color: #b5b7c0; font-size: 24px"
            >{{ 'mdi-close' }}
          </v-icon>
        </v-btn>
        <v-btn icon size="small" variant="text" @click="refreshDocument">
          <v-icon icon="mdi-refresh" size="large" />
        </v-btn>
      </div>
    </template>
    <template #info>
      <div v-if="$loading.isLoading('getDocument')">
        <Skeleton />
      </div>
      <div v-else>
        <DocumentAgreed
          v-if="documentAction === 'agreement'"
          :document="document" />
        <GeneralInfo :document="document" class="mb-3">
          <template #append-1>
            <div class="mt-3">
              <div v-if="documentAction === 'signature'">
                <DocumentSign :document="document"></DocumentSign>
              </div>
            </div>
          </template>
        </GeneralInfo>
        <connected-document :document="document"></connected-document>
        <SimpleExpansionPanel v-if="isErm && document.other" label="Примітки">
          <template #content>
            <div class="fields-container pt-2 pb-2">
              <div class="data-field mt-0">
                <span class="title mr-2">Марка/Модель</span>
                <span class="value">
                  {{ document.other.mark }} {{ document.other.model }}
                </span>
              </div>
              <div class="data-field">
                <span class="title mr-2">Вартість</span>
                <span class="value"
                  >{{ getBeautyNum(document.other.leasingAmount) }} грн</span
                >
              </div>
              <div class="data-field">
                <span class="title mr-2">Аванс</span>
                <span class="value">{{ document.other.advance }} %</span>
              </div>
              <div class="data-field">
                <span class="title mr-2">Код розрахунку</span>
                <span class="value">{{ document.other.requestId }}</span>
              </div>
            </div>
          </template>
        </SimpleExpansionPanel>
        <SimpleExpansionPanel
          v-if="documentAction === 'agreement'"
          label="Пов'язані документи">
          <template #content>
            <div
              v-if="document.related_document_agreements?.length"
              class="pt-2 pb-2">
              <v-tooltip
                v-for="doc in document.related_document_agreements"
                :key="doc.id"
                :text="doc.name">
                <template #activator="{ props }">
                  <div
                    class="link text-uppercase text-truncate mb-2 pb-1 font-weight-bold"
                    style="font-size: 1rem"
                    v-bind="props"
                    @click="
                      navigateToItem('document-agreement-single', doc.id)
                    ">
                    {{ doc?.name }}
                  </div>
                </template>
              </v-tooltip>
            </div>
            <div v-else class="text-center">Список порожній</div>
          </template>
        </SimpleExpansionPanel>

        <SimpleExpansionPanel
          v-if="documentAction === 'agreement'"
          label="Додаткові документи">
          <template #content>
            <FilesUploader
              v-model="document.extraFiles"
              :disabled="disabled"
              :name-folder="document.number"
              class="mb-3"
              dropper
              dropper-variant="blue-label"
              label="Завантажити"
              main-folder="Документы"
              @change="updateDocument" />
            <div
              v-if="disabled && !document.extraFiles?.length"
              class="text-disabled text-center">
              Додаткові документи відсутні
            </div>
          </template>
        </SimpleExpansionPanel>

        <SimpleExpansionPanel
          v-if="documentAction === 'agreement'"
          label="Коментарі">
          <template #content>
            <Comments
              :comments="document.comments"
              :disabled="
                !(
                  isErm ||
                  isAuthor ||
                  document.participants?.map(a => a.userId).includes(userId)
                )
              "
              :entityId="document.id"
              :entityTypeId="2"
              class="pt-2 pb-2" />
          </template>
        </SimpleExpansionPanel>
        <!-- done -->
        <SimpleExpansionPanel
          v-if="documentAction === 'signature' && document.statusId !== 4"
          label="Доступ до документа">
          <template #content>
            <ClientAccess
              :document="document.$object"
              @create-access="createAccessHandler"
              @delete-access="deleteAccessHandler" />
          </template>
        </SimpleExpansionPanel>

        <v-btn
          v-if="documentAction === 'agreement'"
          style="padding: 0 !important"
          variant="text"
          @click="showDocumentHistory"
          >Історія погодження
        </v-btn>
        <v-btn
          v-if="documentAction === 'agreement'"
          style="padding: 0 !important"
          variant="text"
          @click="showAgreedHistory">
          Учасники погодження
        </v-btn>
      </div>
    </template>
  </DocumentPageTemplate>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue'
import Comments from '@/components/Comments.vue'
import GeneralInfo from './sections/GeneralInfo.vue'
import ClientAccess from './sections/ClientAccess.vue'
import DocumentAgreed from './sections/DocumentAgreed.vue' // eslint-disable-line max-len
import DocumentSign from './sections/DocumentSign.vue'
import DocumentPageTemplate from '@/components/document-page-template/Index.vue'
import { useDocumentSingle } from './documentSingle.js'
import { axios, useBreadcrumb, useRoute, useStore } from '@/plugins/index.js'
import {
  urlDocumentAgreementSingle,
  urlDocumentSendToAgree,
  urlDocumentSignatureArchive,
  urlDocumentSignatureProtocol,
  urlDocumentSignatureSingle,
} from '@/utils/urls.js'
import { computed, inject, provide, reactive, ref } from 'vue'
import { cachedObject, download, keepArray } from 'best-modules/utils'
import { getBeautyNum } from '@/utils/helpers.js'
import SimpleExpansionPanel from '@/components/expansion-panel/SimpleExpansionPanel.vue'
import FilesUploader from '@/components/filesUploader/FilesUploader.vue'
import { handleAsync } from 'best-modules/plugins'
import { useRouter } from 'vue-router'
import {
  documentAgreementSingleChannel,
  documentSignatureSingleChannel,
  socket,
} from '@/socket.js'
import { documentActionKey } from '@/pages/documents/injectionKeys'
import { openDialog } from '@/dialog'
import ConnectedDocument from './sections/ConnectedDocument.vue'

export default {
  components: {
    ConnectedDocument,
    FilesUploader,
    SimpleExpansionPanel,
    Skeleton,
    Comments,
    GeneralInfo,
    ClientAccess,
    DocumentSign,
    DocumentAgreed,
    DocumentPageTemplate,
  },
  methods: { download, keepArray },
  setup({ emit }) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const breadcrumb = useBreadcrumb()
    const documentAction = inject(documentActionKey)
    const absenceId = route?.params?.absenceId

    const zoomPercentage = ref(100)
    const fullWidth = ref(false)
    const updateComponent = ref(0)
    const document = cachedObject({})
    document.$setIgnore('user_electronic_signature')

    const userId = computed(() => {
      return store.state.user.userData?.id
    })

    const setFullWidth = () => {
      fullWidth.value = !fullWidth.value
      emit('updateFullWidth', fullWidth.value)
    }
    const zoomIn = () => {
      zoomPercentage.value += 10
      emit('zoomChanged', zoomPercentage.value)
    }

    const zoomOut = () => {
      zoomPercentage.value -= 10
      emit('zoomChanged', zoomPercentage.value)
    }

    const refreshDocument = () => {
      updateComponent.value += 1
    }

    const isAuthor = computed(() => document.authorId === userId.value)
    const isErm = computed(() => !!document.ermId)

    const state = reactive({
      isAuthor,
      isErm,
      userId,
    })

    provide('state', state)

    const getDocument = () => {
      return handleAsync('getDocument', () => {
        const getUrl = () => {
          switch (documentAction.value) {
            case 'signature':
              return urlDocumentSignatureSingle(
                route?.params?.id,
                route?.params?.absenceId
              )
            case 'agreement':
              return urlDocumentAgreementSingle(
                route?.params?.id,
                route?.params?.absenceId
              )
            default:
              console.error(
                '[getUrl] Invalid documentAction:',
                documentAction.value
              )
              throw new Error(
                '[DocumentSingle.vue] getDocument() - invalid documentAction'
              )
          }
        }

        const url = getUrl()
        return axios.get(url).then(res => {
          document.$set(res.data)
          breadcrumb.set([
            {
              title:
                documentAction.value === 'agreement'
                  ? 'Візування'
                  : 'Підписання',
              to: {
                name:
                  documentAction.value === 'agreement'
                    ? 'document-agreement-list'
                    : 'document-signature-list',
              },
              index: 0,
            },
            { title: document.name, index: 1 },
          ])

          return res.data
        })
      })
    }

    getDocument().then(() => {
      const getChannel = () => {
        switch (documentAction.value) {
          case 'agreement':
            return documentAgreementSingleChannel
          case 'signature':
            return documentSignatureSingleChannel
          default:
            throw new Error(
              '[DocumentSingle.vue] getChannel() - invalid documentAction'
            )
        }
      }
      socket.on(getChannel(), res => {
        if (res.id === document.id) {
          document.$set(res)
        }
      })
    })

    const createAccessHandler = access => {
      if (!Array.isArray(document.client_accesses)) {
        document.client_accesses = []
      }
      document.client_accesses.push(access)
    }
    const deleteAccessHandler = accessId => {
      document.client_accesses = document.client_accesses.filter(
        a => a.id !== accessId
      )
    }

    const downloadProtocol = () => {
      return axios
        .get(urlDocumentSignatureProtocol(document.id, absenceId))
        .then(res => {
          download(res.data.url, res.data.url.split('/').at(-1))
        })
    }

    const downloadArchive = () => {
      return axios
        .get(urlDocumentSignatureArchive(document.id, absenceId))
        .then(res => {
          download(res.data.url, res.data.url.split('/').at(-1))
        })
    }

    const sendToSign = () => {
      openDialog({
        name: 'DocumentSendToSign',
        action: 'create',
        dialogData: {
          documentId: document.id,
        },
      })
    }

    const sendToAgree = () => {
      return handleAsync('sendToAgree', () => {
        return axios
          .get(urlDocumentSendToAgree(document.id, absenceId))
          .then(res => {
            router.push({
              name: 'document-agreement-single',
              params: { id: res.data.id },
            })
          })
      })
    }

    return {
      document,
      getDocument,
      getBeautyNum,
      isErm,
      isAuthor,
      userId,
      zoomIn,
      zoomOut,
      zoomPercentage,
      fullWidth,
      setFullWidth,
      refreshDocument,
      updateComponent,
      createAccessHandler,
      deleteAccessHandler,
      downloadProtocol,
      downloadArchive,
      sendToSign,
      sendToAgree,
      documentAction,
      ...useDocumentSingle(document, state),
    }
  },
}
</script>

<style lang="scss" scoped></style>
