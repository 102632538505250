import { Task } from '@/utils/types/task'
import { computed, ref } from 'vue'
import { axios, useRoute, useStore } from '@/plugins'
import {
  urlTaskCreateControlUser,
  urlTaskCreateObserverUser,
  urlTaskDeleteControlUser,
  urlTaskDeleteObserverUser,
  urlTaskFinalComplete,
  urlTaskRevision,
  urlTaskUpdateDeadline,
  urlTaskUpdateExecutor,
} from '@/utils/urls'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { openDialog } from '@/dialog'

export function useSidebar(task: Task) {
  const store = useStore()
  const route = useRoute()
  const absenceId = route?.params?.absenceId
  const userId = computed(() => store.state.user.userData?.id)
  const isExecutor = computed(() =>
    [task.executorId, task.executor?.id].includes(userId.value)
  )
  const previousDeadline = ref(null)

  const taskType = computed(() => {
    return {
      execution: task.typeId === 1,
      familiarization: task.typeId === 2,
      errand: task.typeId === 3,
    }
  })

  const getStatusCard = computed(() => [
    {
      showIf: task.status?.id === 1,
      title: `${task.status?.name} з ${task.created_at}`,
      class: 'bg-yellow-lighten-1',
    },
    {
      showIf: task.status?.id === 2,
      title: `Завдання ${task.status?.name.toLowerCase()}`,
      class: 'bg-blue',
    },
    {
      showIf: task.status?.id === 3,
      title: `Завдання ${task.status?.name.toLowerCase()} ${
        task.completedDate
      }`,
      class: 'bg-green',
    },
    {
      showIf: task.status?.id === 5,
      title: task.status?.name,
      class: 'bg-yellow-lighten-1',
    },
  ])

  const visibleStatusCards = computed(() =>
    getStatusCard.value.filter(status => status.showIf)
  )

  const createObserverUser = id => {
    const req = {
      taskId: task.id,
      userId: id,
    }
    return axios.post(urlTaskCreateObserverUser(), req).then(res => {
      task.observers.push(res.data)
    })
  }

  const observersDialog = () => {
    openDialog({
      name: 'SelectDepartmentPerson',
      dialogData: {
        userIds: task.observers.map(o => o.userId),
        canBeEmpty: true,
      },
      params: {
        cb: ({ usersToCreate, usersToDelete }) => {
          handleAsync('updateObservers', async () => {
            usersToDelete.forEach(userId => {
              const user = task.observers.find(u => u.userId === userId)
              if (user) {
                deleteObserverUser(user.id)
              }
            })
            usersToCreate.forEach(createObserverUser)
          })
        },
      },
    })
  }

  const deleteObserverUser = (userId: number) => {
    return axios
      .delete(urlTaskDeleteObserverUser(userId, absenceId))
      .then(() => {
        task.observers = task.observers.filter(item => item.id !== userId)
      })
  }

  const createControlUser = id => {
    const req = {
      taskId: task.id,
      userId: id,
    }
    return axios.post(urlTaskCreateControlUser(), req).then(res => {
      task.control_users.push(res.data)
    })
  }

  const deleteControlUser = (userId: number) => {
    return axios
      .delete(urlTaskDeleteControlUser(userId, absenceId))
      .then(() => {
        task.control_users = task.control_users.filter(
          item => item.id !== userId
        )
      })
  }

  const controlUsersDialog = () => {
    openDialog({
      name: 'SelectDepartmentPerson',
      dialogData: {
        userIds: task.control_users.map(o => o.userId),
        canBeEmpty: true,
      },
      params: {
        cb: ({ usersToCreate, usersToDelete }) => {
          handleAsync('updateControlUsers', async () => {
            usersToDelete.forEach(userId => {
              const user = task.control_users.find(u => u.userId === userId)
              if (user) {
                deleteControlUser(user.id)
              }
            })
            usersToCreate.forEach(createControlUser)
          })
        },
      },
    })
  }

  const updateExecutor = (userId: number) => {
    if (!userId) return
    return handleAsync('updateExecutor', () => {
      return axios
        .post(urlTaskUpdateExecutor(task.id), {
          executorId: userId,
        })
        .then(res => {
          task.executor = res.data.executor
          task.executorId = res.data.executorId
        })
    })
  }

  const executorDialog = () => {
    openDialog({
      name: 'SelectDepartmentPerson',
      dialogData: {
        userIds: [task.executorId],
        isSingle: true,
      },
      params: {
        cb: ({ userIds }) => {
          updateExecutor(userIds[0])
        },
      },
    })
  }

  const updateDeadline = async (): Promise<void> => {
    if (task.deadline === previousDeadline.value) {
      return
    }

    previousDeadline.value = task.deadline

    return handleAsync('updateDeadline', async () => {
      if (task.deadline && task.deadline.length === 17) {
        await axios.post(urlTaskUpdateDeadline(task.id, absenceId), {
          deadline: task.deadline,
        })
      }
    })
  }

  const finalComplete = async (): Promise<void> => {
    return handleAsync('finalCompleteHandler', async () => {
      await axios.get(urlTaskFinalComplete(task.id, absenceId)).then(res => {
        Object.assign(task, res.data)
        setSnackbar({ text: 'Завдання перевірено і завершено' })
      })
    })
  }

  const revisionTask = async (): Promise<void> => {
    return handleAsync('revisionTaskHandler', async () => {
      await axios.get(urlTaskRevision(task.id, absenceId)).then(res => {
        Object.assign(task, res.data)
        setSnackbar({
          text: 'Завдання відправлено на доопрацювання виконавцю',
        })
      })
    })
  }

  return {
    updateExecutor,
    createControlUser,
    deleteObserverUser,
    createObserverUser,
    deleteControlUser,
    isExecutor,
    getStatusCard,
    visibleStatusCards,
    taskType,
    finalComplete,
    revisionTask,
    updateDeadline,
    observersDialog,
    controlUsersDialog,
    executorDialog,
  }
}
