<script lang="ts">
export default {
  name: 'Svg',
}
</script>

<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 17 20"
    width="17"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19572 0.536621C0.519877 0.536621 0 1.10849 0 1.73234V18.7843C0 19.4602 0.571865 19.98 1.19572 19.98H15.8043C16.4801 19.98 17 19.4602 17 18.7843V5.99534L11.5413 0.536621H1.19572Z"
      fill="#F8A965" />
    <path
      d="M16.9999 5.99534H12.7369C12.061 5.99534 11.5411 5.47546 11.5411 4.79962V0.536621L16.9999 5.99534Z"
      fill="#DE7A24" />
    <path
      d="M5.44621 14C5.00052 14 4.64807 13.8462 4.38884 13.5385C4.13416 13.2308 4.00455 12.8022 4 12.2527H4.85954C4.86409 12.5055 4.91639 12.6951 5.01644 12.8214C5.12104 12.9478 5.26657 13.011 5.45303 13.011C5.6395 13.011 5.78048 12.9533 5.87598 12.8379C5.97149 12.717 6.01924 12.5604 6.01924 12.3681C6.01924 12.1648 5.95557 11.9918 5.82823 11.8489C5.70089 11.7005 5.50533 11.5275 5.24156 11.3297C5.00052 11.1538 4.80269 10.9918 4.64807 10.8434C4.49344 10.6951 4.36155 10.511 4.2524 10.2912C4.1478 10.0659 4.0955 9.8022 4.0955 9.5C4.0955 9.21429 4.15008 8.95879 4.25923 8.73352C4.36837 8.50275 4.523 8.32418 4.72311 8.1978C4.92776 8.06593 5.16879 8 5.44621 8C5.87371 8 6.20797 8.14835 6.44901 8.44506C6.69459 8.74176 6.81966 9.14835 6.82421 9.66484H5.96467C5.96012 9.43956 5.91237 9.26923 5.82141 9.15385C5.735 9.03846 5.60993 8.98077 5.44621 8.98077C5.29159 8.98077 5.16879 9.03022 5.07784 9.12912C4.99143 9.22802 4.94822 9.35989 4.94822 9.52473C4.94822 9.71703 5.00962 9.88187 5.13241 10.0192C5.2552 10.1511 5.44621 10.3104 5.70544 10.4973C5.95557 10.6731 6.15795 10.8352 6.31257 10.9835C6.47175 11.1264 6.60818 11.3159 6.72188 11.5522C6.83558 11.783 6.89242 12.0577 6.89242 12.3764C6.89242 12.6841 6.83558 12.9615 6.72188 13.2088C6.60818 13.4505 6.44219 13.6429 6.22389 13.7857C6.0056 13.9286 5.74637 14 5.44621 14Z"
      fill="white" />
    <path
      d="M6.79576 8.11538H7.66895L8.16693 11.0495C8.23515 11.4615 8.30564 11.9863 8.37841 12.6236H8.40569C8.51029 11.8379 8.58306 11.3132 8.62399 11.0495L9.12198 8.11538H9.99516L8.84229 13.8846H7.94864L6.79576 8.11538Z"
      fill="white" />
    <path
      d="M11.547 13.9835C11.0876 13.9835 10.7284 13.8242 10.4691 13.5055C10.2145 13.1868 10.0871 12.7335 10.0871 12.1456V9.8544C10.0871 9.26099 10.2145 8.80769 10.4691 8.49451C10.7284 8.17582 11.0876 8.01648 11.547 8.01648C11.9881 8.01648 12.3315 8.17308 12.5771 8.48626C12.8226 8.79396 12.9545 9.24176 12.9727 9.82967H12.1336C12.1245 9.53846 12.0722 9.32692 11.9767 9.19506C11.8812 9.06319 11.738 8.99725 11.547 8.99725C11.1513 8.99725 10.9535 9.28297 10.9535 9.8544V12.1456C10.9535 12.717 11.1559 13.0027 11.5606 13.0027C11.9745 13.0027 12.1814 12.7225 12.1814 12.1621V11.6016H11.5197V10.7033H13V12.1621C12.9909 12.7445 12.859 13.1951 12.6043 13.5137C12.3497 13.8269 11.9972 13.9835 11.547 13.9835Z"
      fill="white" />
  </svg>
</template>

<style lang="scss" scoped></style>
