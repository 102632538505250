import { DataTableHeader } from 'best-modules/components'

const auditListHeaders: DataTableHeader[] = [
  {
    key: 'name',
    title: 'Назва',
    sortable: false,
  },
  {
    key: 'year',
    title: 'Рік',
    sortable: false,
  },
  {
    key: 'type.name',
    title: 'Тип',
    sortable: false,
  },
  {
    key: 'status',
    title: 'Статус',
    sortable: false,
  },
  {
    key: 'actions',
    title: 'Дії',
    sortable: false,
    align: 'end',
  },
]

const auditRecommendationListHeaders: DataTableHeader[] = [
  {
    key: 'task',
    title: 'Завдання',
    sortable: false,
  },
  {
    key: 'executor',
    title: 'Виконавець',
    sortable: false,
  },
  {
    key: 'status',
    title: 'Статус',
    sortable: false,
  },
  {
    key: 'actions',
    title: 'Дії',
    sortable: false,
    align: 'end',
  },
]
const auditRiskListHeaders: DataTableHeader[] = [
  {
    key: 'risk_type.name',
    title: 'Вид ризику',
  },
  {
    key: 'risk_level.name',
    title: 'Рівень ризику',
  },
  {
    key: 'actions',
    title: 'Дії',
    sortable: false,
    align: 'end',
  },
]

const contractorListHeaders: DataTableHeader[] = [
  {
    key: 'type.name',
    title: 'Тип',
    align: 'start',
  },
  { key: 'shortName', title: 'Ім`я', align: 'start' },
  { key: 'code', title: 'ЄДРПОУ/ІНН', align: 'start' },
]

const templateRoutingListHeaders: DataTableHeader[] = [
  {
    key: 'name',
    title: 'Назва',
  },
  {
    key: 'document_type',
    title: 'Тип документу',
  },
  {
    key: 'actions',
    title: 'Дії',
    sortable: false,
    align: 'end',
  },
]

export {
  auditListHeaders,
  auditRecommendationListHeaders,
  auditRiskListHeaders,
  contractorListHeaders,
  templateRoutingListHeaders,
}
