<template>
  <v-card>
    <v-card-text>
      <b-server-table
        :key="renderKey"
        :headers="headers"
        :request-func="getOrders"
        :show-filters="!isFamiliarization"
        :show-search="!isFamiliarization"
        @click:row="openRow">
        <template v-if="!isFamiliarization" #filters>
          <b-server-filter-item
            v-slot="{ props }"
            directory="departmentList"
            query-key="departmentId">
            <b-select
              clearable
              label="Департамент"
              placeholder="Оберіть зі списку"
              v-bind="props"></b-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            directory="orderTypes"
            query-key="typeId">
            <b-select
              clearable
              label="Тип"
              placeholder="Оберіть зі списку"
              v-bind="props"></b-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            directory="orderDirections"
            query-key="directionId">
            <b-select
              clearable
              label="Напрямок"
              placeholder="Оберіть зі списку"
              v-bind="props"></b-select>
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            directory="userList"
            query-key="issuedUserId">
            <b-select
              clearable
              label="Відповідальний"
              placeholder="Оберіть зі списку"
              v-bind="props"></b-select>
          </b-server-filter-item>
          <b-server-filter-item v-slot="{ props }" query-key="toDate">
            <b-input-date label="Дата від" v-bind="props"></b-input-date>
          </b-server-filter-item>
          <b-server-filter-item v-slot="{ props }" query-key="fromDate">
            <b-input-date label="Дата до" v-bind="props" />
          </b-server-filter-item>
          <b-server-filter-item
            v-slot="{ props }"
            class="d-flex align-end"
            query-key="isActive">
            <v-checkbox
              false-value="false"
              label="Активний"
              true-value="true"
              v-bind="props"></v-checkbox>
          </b-server-filter-item>
        </template>
        <template v-if="!isFamiliarization" #action-button>
          <div
            v-if="[ADMIN, CLERKSHIP].includes(user.roleId)"
            class="flex-grow-1 d-flex justify-end gap-3 flex-wrap">
            <v-btn rounded="lg" @click.stop="familiarizationReportDialog">
              <v-icon class="mr-1" style="color: #fff"
                >{{ 'mdi-cloud-print-outline' }}
              </v-icon>
              Сформувати звіт
            </v-btn>
            <v-btn rounded="lg" @click.stop="createOrder">
              <v-icon class="mr-1">mdi-plus-circle</v-icon>
              Створити наказ
            </v-btn>
          </div>
        </template>
        <template #item.issued_user="{ item }">
          <span v-if="item.issued_user">
            {{ getUserName(item.issued_user) }}
          </span>
          <span v-else class="text-disabled">Не вказано</span>
        </template>
        <template #item.isActive="{ item }">
          <span>{{ item.isActive ? 'Активний' : 'Неактивний' }}</span>
        </template>
        <template #item.name="{ item }">
          <v-tooltip :text="item.name" bottom>
            <template #activator="{ props }">
              <span
                style="
                  display: block;
                  width: 350px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                v-bind="props">
                {{ item.name }}
              </span>
            </template>
          </v-tooltip>
        </template>
        <template #bottom></template>
      </b-server-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { axios, useBreadcrumb, useRoute } from '@/plugins/index.js'
import { computed, ref } from 'vue'
import { getUserName, navigateToItem } from '@/utils/helpers.js'
import { urlOrderFamiliarization, urlOrderList } from '@/utils/urls.js'
import { openDialog } from '@/dialog/index.ts'
import { useStore } from 'vuex'
import { ADMIN, CLERKSHIP } from '@/utils/roles.ts'
import {
  BInputDate,
  BServerFilterItem,
  BServerTable,
} from 'best-modules/components'

export default {
  components: { BServerTable, BServerFilterItem, BInputDate },
  data: () => ({
    CLERKSHIP,
    ADMIN,
    headers: [
      {
        title: 'Номер',
        align: 'start',
        key: 'number',
        sortable: false,
        maxWidth: '100px',
      },
      { title: 'Статус', key: 'isActive', sortable: false, align: 'start' },
      { title: 'Тема', key: 'name', sortable: false, align: 'start' },
      {
        title: 'Хто підготовив',
        key: 'issued_user',
        sortable: false,
        align: 'start',
      },
    ],
  }),
  methods: { getUserName },
  setup() {
    const route = useRoute()
    const store = useStore()
    const renderKey = ref(1)
    const breadcrumb = useBreadcrumb()
    breadcrumb.set([], { type: 'replace' })

    const user = computed(() => store.state.user.userData || {})

    const openRow = event => {
      navigateToItem('single-order', event.id)
    }

    const isFamiliarization = computed(() => {
      return [
        'document-familiarization-me',
        'document-familiarization-familiarized',
      ].includes(route.name)
    })

    const url = computed(() => {
      switch (route.name) {
        case 'document-familiarization-me':
          return query =>
            urlOrderFamiliarization({ isFamiliarization: false, ...query })
        case 'document-familiarization-familiarized':
          return query =>
            urlOrderFamiliarization({ isFamiliarization: true, ...query })
        case 'order-list':
          return urlOrderList
        case 'order-list-all':
          return urlOrderList
        default:
          throw new Error(
            '[OrderList.vue] url: ComputedRef - unknown route name'
          )
      }
    })

    const getOrders = query => {
      return axios.get(url.value(query)).then(res => res.data)
    }

    const familiarizationReportDialog = () => {
      openDialog({
        name: 'FamiliarizationReport',
        action: 'create',
      })
    }

    const createOrder = () => {
      openDialog({
        name: 'Order',
        action: 'create',
        params: {
          cb: order => {
            navigateToItem('single-order', order.id)
          },
          submitLabel: 'Створити',
        },
      })
    }

    return {
      renderKey,
      getOrders,
      navigateToItem,
      familiarizationReportDialog,
      openRow,
      user,
      isFamiliarization,
      createOrder,
    }
  },
}
</script>

<style lang="scss" scoped></style>
