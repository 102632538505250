<template>
  <div>
    <v-card class="h-100 shadow-1" rounded="lg">
      <v-card-title class="mt-1 mb-2" style="height: 45px">
        <v-chip
          :color="correspondence.status?.id === 1 ? 'red' : 'green'"
          size="small">
          <div class="d-flex gap-1">
            <b>Статус:</b>
            <span>{{ correspondence.status?.name || '---' }}</span>
            <span v-if="correspondence.deliveryDate">
              ({{ correspondence.deliveryDate }})
            </span>
          </div>
        </v-chip>
      </v-card-title>
      <v-card-text v-show="correspondence.statusId === 1" class="pb-0">
        <v-btn-group
          class="w-100"
          density="compact"
          rounded="lg"
          variant="outlined">
          <v-btn
            class="w-100"
            color="primary"
            density="comfortable"
            rounded="lg"
            size="x-small"
            @click="delivered">
            <v-icon class="mr-3">mdi-package-variant-closed-check</v-icon>
            {{ correspondence?.status?.name }}
          </v-btn>
        </v-btn-group>
      </v-card-text>
      <v-card-text class="d-flex flex-column gap-2">
        <div class="d-flex align-center justify-space-between w-100">
          <span class="label d-block w-45 mb-0">Тип кореспонденції</span>
          <div class="w-55">
            <b-select
              v-model="correspondenceData.typeId"
              :error-messages="getErrorMessages(v$.typeId)"
              :items="
                $directory.get('correspondenceTypes', correspondence.type)
              "
              :loading="$loading.isLoading('correspondenceTypes')"
              :readonly="true"
              hide-details
              placeholder="Оберіть зі списку"
              @blur="v$.typeId.$touch()"></b-select>
          </div>
        </div>
        <div class="d-flex align-center justify-space-between w-100">
          <span class="label d-block w-45 mb-0">Тип документу</span>
          <div class="w-55">
            <b-select
              v-model="correspondenceData.documentTypeId"
              :error-messages="getErrorMessages(v$.documentTypeId)"
              :items="
                $directory.get(
                  'correspondenceDocumentTypes',
                  correspondence.document_type
                )
              "
              :loading="$loading.isLoading('correspondenceDocumentTypes')"
              hide-details
              placeholder="Оберіть зі списку"
              @blur="v$.documentTypeId.$touch()"
              @focus="$directory.fill('correspondenceDocumentTypes')"
              @update:model-value="
                () => {
                  $directory.fill('correspondenceDeliveryTypes')
                  correspondenceData.documentPriorityTypeId = null
                  correspondenceData.documentDeliveryTypeId = 2
                }
              "></b-select>
          </div>
        </div>
        <div class="d-flex align-center justify-space-between w-100">
          <span class="label d-block w-45 mb-0">Номер</span>
          <div class="w-55">
            <b-input
              v-model="correspondenceData.registrationNumber"
              :error-messages="getErrorMessages(v$.registrationNumber)"
              hide-details
              mask="#######"
              placeholder="Введіть номер"
              @blur="v$.registrationNumber.$touch()">
            </b-input>
          </div>
        </div>
        <div class="d-flex align-center justify-space-between w-100">
          <span class="label d-block w-45 mb-0">Дата реєстрації</span>
          <div class="w-55">
            <base-date-picker
              v-model="correspondenceData.registrationDate"
              :error-messages="getErrorMessages(v$.registrationDate)"
              hide-details
              timePicker
              @blur="v$.registrationDate.$touch()"></base-date-picker>
          </div>
        </div>
        <div
          v-if="[1, 4].includes(correspondence.documentTypeId)"
          class="d-flex align-center justify-space-between w-100">
          <span class="label d-block w-45 mb-0">
            {{
              correspondence.typeId === 1
                ? 'Тип доставлення'
                : 'Тип відправлення'
            }}
          </span>
          <div class="w-55">
            <b-select
              v-model="correspondenceData.documentDeliveryTypeId"
              :error-messages="getErrorMessages(v$.documentDeliveryTypeId)"
              :items="
                $directory.get(
                  'correspondenceDeliveryTypes',
                  correspondence.document_delivery_type
                )
              "
              :loading="$loading.isLoading('correspondenceDeliveryTypes')"
              hide-details
              placeholder="Оберіть зі списку"
              @blur="v$.documentDeliveryTypeId.$touch()"
              @focus="
                $directory.fill('correspondenceDeliveryTypes')
              "></b-select>
          </div>
        </div>
        <div
          v-show="correspondence.documentTypeId === 1"
          class="d-flex align-center justify-space-between w-100">
          <span class="label d-block w-45 mb-0">Приорітет</span>
          <div class="w-55">
            <b-select
              v-model="correspondenceData.documentPriorityTypeId"
              :error-messages="getErrorMessages(v$.documentPriorityTypeId)"
              :items="
                $directory.get(
                  'correspondencePriorityTypes',
                  correspondence.document_priority_type
                )
              "
              :loading="$loading.isLoading('correspondencePriorityTypes')"
              hide-details
              placeholder="Оберіть зі списку"
              @blur="v$.documentPriorityTypeId.$touch()"
              @focus="
                $directory.fill('correspondencePriorityTypes')
              "></b-select>
          </div>
        </div>
        <div>
          <v-checkbox
            v-model="correspondenceData.isSecretFinancial"
            color="green"
            hide-details>
            <template #label>
              <span class="label mb-0">Таємниця фінансових послуг</span>
            </template>
          </v-checkbox>
        </div>
        <div class="d-flex align-center justify-space-between">
          <span class="label mb-0 text-disabled">Дата створення</span>
          <span class="label mb-0 text-disabled" style="font-size: 14px">
            {{ correspondence.created_at }}
          </span>
        </div>
        <div class="d-flex align-center justify-space-between">
          <span class="label mb-0 text-disabled">Дата оновлення</span>
          <span class="label mb-0 text-disabled" style="font-size: 14px">
            {{ correspondence.updated_at }}
          </span>
        </div>
        <div class="d-flex align-center justify-space-between">
          <span class="label mb-0 text-disabled">Відповідальний</span>
          <span class="label mb-0 text-disabled" style="font-size: 14px">
            {{ getUserName(correspondence.responsible, { initials: true }) }}
          </span>
        </div>
        <slot v-if="$slots['action-buttons']" name="action-buttons"></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { getErrorMessages } from 'best-modules/utils'
import { computed } from 'vue'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { getUserName } from '@/utils/helpers'
import { openDialog } from '@/dialog'
import { correspondence } from '@/pages/correspondence/single/correspondence'

export default {
  name: 'CorrespondenceSidebar',
  components: { BaseDatePicker },
  emits: ['update:correspondence'],
  props: {
    correspondence: { type: Object, required: true },
    v$: { type: Object },
  },
  methods: { getUserName, getErrorMessages },
  setup(props, { emit }) {
    const correspondenceData = computed({
      get() {
        return props.correspondence
      },
      set(v) {
        emit('update:correspondence', v)
      },
    })

    const delivered = () => {
      openDialog({
        name: 'CorrespondenceDeliver',
        dialogData: {
          correspondenceId: correspondence.id,
        },
        params: {
          cb: res => {
            correspondence.$set(res)
          },
        },
      })
    }

    return { correspondenceData, delivered }
  },
}
</script>

<style lang="scss" scoped></style>
