<template>
  <v-card-title v-if="!noCard" class="mt-1 mb-2">
    <div class="d-flex align-center justify-space-between">
      <h4>{{ label }}</h4>
      <div>
        <v-btn-group density="compact" rounded="lg" variant="outlined">
          <v-btn
            :disabled="disabled"
            :loading="loading"
            :readonly="readonly"
            color="primary"
            density="comfortable"
            rounded="lg"
            size="x-small"
            @click="handleSelect">
            <attachment-icon class="mr-1" />
            <span v-if="label">{{ label }}</span>
          </v-btn>
        </v-btn-group>
      </div>
    </div>
  </v-card-title>
  <v-card-text v-if="$slots.files && !noCard">
    <slot name="files"></slot>
  </v-card-text>

  <div v-if="noCard">
    <div class="d-flex align-center justify-space-between">
      <v-btn-group density="compact" rounded="lg" variant="outlined">
        <v-btn
          :disabled="disabled"
          :loading="loading"
          :readonly="readonly"
          color="primary"
          density="comfortable"
          rounded="lg"
          size="x-small"
          @click="handleSelect">
          <attachment-icon class="mr-1" />
          <span v-if="label">{{ label }}</span>
        </v-btn>
      </v-btn-group>
      <slot v-if="$slots['append-action']" name="append-action"></slot>
    </div>
    <slot name="files"></slot>
  </div>
</template>

<script lang="ts">
import { selectFile } from '@/components/filesUploader/selectFile'
import { PropType } from 'vue'
import { SelectFileOptions } from '@/components/filesUploader/selectFile/types'
import AttachmentIcon from '@/assets/images/icons/attachment-icon.vue'

export default {
  name: 'FileList',
  components: { AttachmentIcon },
  emits: ['select'],
  props: {
    label: { type: [String, Boolean] },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    noCard: { type: Boolean },
    loading: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Object as PropType<SelectFileOptions>,
    },
  },
  setup(props, { emit }) {
    const handleSelect = () => {
      if (!props.loading) {
        selectFile(props.selectOptions).then(result => {
          emit('select', result)
        })
      } else return
    }

    return {
      handleSelect,
    }
  },
}
</script>

<style lang="scss" scoped></style>
