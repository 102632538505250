<script lang="ts">
export default {
  name: 'NotificationBellIcon',
}
</script>

<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.857 15.082C14.7202 14.8614 16.5509 14.4217 18.311 13.772C16.8204 12.1208 15.9967 9.9745 16 7.75V7C16 5.4087 15.3678 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 9.99999 1C8.40869 1 6.88257 1.63214 5.75735 2.75736C4.63213 3.88258 3.99999 5.4087 3.99999 7V7.75C4.00301 9.97463 3.17898 12.121 1.68799 13.772C3.42099 14.412 5.24799 14.857 7.14299 15.082M12.857 15.082C10.959 15.3071 9.041 15.3071 7.14299 15.082M12.857 15.082C13.0011 15.5319 13.0369 16.0094 12.9616 16.4757C12.8862 16.942 12.7018 17.384 12.4234 17.7656C12.1449 18.1472 11.7803 18.4576 11.3592 18.6716C10.9381 18.8856 10.4724 18.9972 9.99999 18.9972C9.52761 18.9972 9.06191 18.8856 8.6408 18.6716C8.21968 18.4576 7.85506 18.1472 7.57661 17.7656C7.29816 17.384 7.11375 16.942 7.0384 16.4757C6.96305 16.0094 6.99889 15.5319 7.14299 15.082"
      stroke="#323232"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5" />
  </svg>
</template>

<style lang="scss" scoped></style>
