<template>
  <div>
    <v-menu @update:model-value="$store.commit('confirmNotificationQueue')">
      <template #activator="{ props }">
        <v-btn
          density="compact"
          size="small"
          stacked
          style="background: transparent"
          v-bind="props"
          variant="text">
          <v-badge
            :content="unreadNotificationsLength"
            :model-value="!!unreadNotificationsLength"
            color="success"
            size="small">
            <notification-bell-icon style="color: #202020" />
          </v-badge>
        </v-btn>
      </template>

      <notification-list></notification-list>
    </v-menu>
  </div>
</template>

<script>
import NotificationList from './NotificationList.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import NotificationBellIcon from '@/assets/images/icons/notification-bell-icon.vue'

export default {
  name: 'Notifications',
  components: { NotificationBellIcon, NotificationList },
  setup() {
    const store = useStore()
    store.dispatch('getNotifications').then(() => {
      store.dispatch('listenNotificationEvents')
    })
    const notifications = computed(
      () => store.state.notifications.notificationList
    )
    const unreadNotificationsLength = computed(() => {
      return notifications.value.filter(n => !n.isRead).length
    })

    return { notifications, unreadNotificationsLength }
  },
}
</script>
