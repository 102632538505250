<script lang="ts">

export default {
  name: "AuditIcon"
};
</script>

<template>
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.375 17.3552L12.7563 15.7364L11.875 16.6177L14.375 19.1177L18.75 14.7427L17.8687 13.8614L14.375 17.3552Z"
      fill="#18181B" />
    <path
      d="M6.875 5.99268C7.22018 5.99268 7.5 5.71285 7.5 5.36768C7.5 5.0225 7.22018 4.74268 6.875 4.74268C6.52982 4.74268 6.25 5.0225 6.25 5.36768C6.25 5.71285 6.52982 5.99268 6.875 5.99268Z"
      fill="#18181B" />
    <path
      d="M6.875 10.9927C7.22018 10.9927 7.5 10.7129 7.5 10.3677C7.5 10.0225 7.22018 9.74268 6.875 9.74268C6.52982 9.74268 6.25 10.0225 6.25 10.3677C6.25 10.7129 6.52982 10.9927 6.875 10.9927Z"
      fill="#18181B" />
    <path
      d="M6.875 15.9927C7.22018 15.9927 7.5 15.7129 7.5 15.3677C7.5 15.0225 7.22018 14.7427 6.875 14.7427C6.52982 14.7427 6.25 15.0225 6.25 15.3677C6.25 15.7129 6.52982 15.9927 6.875 15.9927Z"
      fill="#18181B" />
    <path
      d="M15 2.24268H5C4.66848 2.24268 4.35054 2.37437 4.11612 2.60879C3.8817 2.84321 3.75 3.16116 3.75 3.49268V17.2427C3.75 17.5742 3.8817 17.8921 4.11612 18.1266C4.35054 18.361 4.66848 18.4927 5 18.4927H10V17.2427H5V13.4927H16.25V3.49268C16.25 3.16116 16.1183 2.84321 15.8839 2.60879C15.6495 2.37437 15.3315 2.24268 15 2.24268ZM15 12.2427H5V8.49268H15V12.2427ZM15 7.24268H5V3.49268H15V7.24268Z"
      fill="#18181B" />
  </svg>
</template>

<style lang="scss" scoped>

</style>
