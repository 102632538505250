<template>
  <svg
    fill="none"
    height="23"
    viewBox="0 0 22 23"
    width="22"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 21.125C9.09636 21.125 7.23546 20.5605 5.65264 19.5029C4.06982 18.4453 2.83616 16.9421 2.10766 15.1833C1.37917 13.4246 1.18856 11.4893 1.55995 9.62226C1.93133 7.75519 2.84802 6.04018 4.1941 4.6941C5.54018 3.34802 7.25519 2.43133 9.12226 2.05995C10.9893 1.68856 12.9246 1.87917 14.6833 2.60766C16.4421 3.33616 17.9453 4.56982 19.0029 6.15264C20.0605 7.73546 20.625 9.59636 20.625 11.5C20.625 14.0527 19.6109 16.5009 17.8059 18.3059C16.0009 20.1109 13.5527 21.125 11 21.125ZM11 3.25C9.36831 3.25 7.77326 3.73386 6.41655 4.64038C5.05984 5.5469 4.00242 6.83538 3.378 8.34287C2.75358 9.85036 2.5902 11.5092 2.90853 13.1095C3.22685 14.7098 4.01259 16.1799 5.16637 17.3336C6.32016 18.4874 7.79017 19.2732 9.39051 19.5915C10.9909 19.9098 12.6497 19.7464 14.1571 19.122C15.6646 18.4976 16.9531 17.4402 17.8596 16.0835C18.7662 14.7268 19.25 13.1317 19.25 11.5C19.25 9.31197 18.3808 7.21355 16.8336 5.66637C15.2865 4.1192 13.188 3.25 11 3.25Z"
      fill="#0D99FF" />
    <path
      d="M14.1556 15.625L10.3125 11.7819V5.3125H11.6875V11.2113L15.125 14.6556L14.1556 15.625Z"
      fill="#0D99FF" />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'TimeIcon',
}
</script>

<style lang="scss" scoped></style>
