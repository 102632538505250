<script lang="ts">
export default {
  name: 'DotsIcon',
  props: {
    color: { type: String, default: '#8E92BC' },
  },
}
</script>

<template>
  <svg
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="color"
      d="M4 9.5C4.55228 9.5 5 9.05228 5 8.5C5 7.94772 4.55228 7.5 4 7.5C3.44772 7.5 3 7.94772 3 8.5C3 9.05228 3.44772 9.5 4 9.5Z" />
    <path
      :fill="color"
      d="M8 9.5C8.55228 9.5 9 9.05228 9 8.5C9 7.94772 8.55228 7.5 8 7.5C7.44772 7.5 7 7.94772 7 8.5C7 9.05228 7.44772 9.5 8 9.5Z" />
    <path
      :fill="color"
      d="M12 9.5C12.5523 9.5 13 9.05228 13 8.5C13 7.94772 12.5523 7.5 12 7.5C11.4477 7.5 11 7.94772 11 8.5C11 9.05228 11.4477 9.5 12 9.5Z" />
  </svg>
</template>

<style lang="scss" scoped></style>
