<template>
  <div class="d-flex justify-end mt-5 flex-column align-end">
    <v-pagination
      v-model="page"
      :length="length"
      density="comfortable"
      rounded="4"
      size="small"
      total-visible="4">
      <template #prev="props">
        <v-btn
          color="#E7EFF4"
          elevation="0"
          icon
          rounded="lg"
          size="x-small"
          style="box-shadow: none !important"
          v-bind="props">
          <v-icon color="#8E92BC !important">{{ 'mdi-chevron-left' }}</v-icon>
        </v-btn>
      </template>
      <template #next="props">
        <v-btn
          color="#E7EFF4"
          elevation="0"
          icon
          rounded="lg"
          size="x-small"
          style="box-shadow: none !important"
          v-bind="props">
          <v-icon color="#8E92BC !important">{{ 'mdi-chevron-right' }}</v-icon>
        </v-btn>
      </template>
      <template #item="{ page, isActive, props }">
        <v-btn
          :style="{
            background: !isActive ? '#e7eff4 !important' : '#0D99FF !important',
            color: !isActive ? '#18181B !important' : 'white !important',
            boxShadow: 'none !important',
          }"
          elevation="0"
          icon
          rounded="lg"
          size="x-small"
          v-bind="props">
          {{ page }}
        </v-btn>
      </template>
    </v-pagination>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  emits: ['update:model-value'],
  props: {
    modelValue: { type: Number },
    length: { type: Number },
    total: { type: Number },
  },
  setup(props, { emit }) {
    const page = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        emit('update:model-value', v)
      },
    })

    return { page }
  },
}
</script>
