<template>
  <v-card class="mt-3 shadow-1" rounded="lg">
    <v-card-title class="mt-1 mb-2">
      <div class="d-flex align-center justify-space-between">
        <h4>{{ typeText }}</h4>
        <v-btn
          :disabled="
            correspondence?.type?.id !== 1 &&
            correspondence?.users &&
            correspondence?.users?.length >= 1
          "
          color="primary"
          density="comfortable"
          icon
          rounded="lg"
          size="small"
          @click="openHandlerDialog">
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <div v-if="correspondence?.users && correspondence?.users?.length">
        <CustomItem
          v-for="user in correspondence.users"
          :key="user.id"
          :item="user"
          :length="correspondence.users?.length"
          hidePost>
          <template #actions>
            <v-btn
              icon
              size="x-small"
              variant="text"
              @click="handleRemoveUser(user.id)">
              <v-icon icon="mdi-delete-forever" size="large" />
            </v-btn>
          </template>
        </CustomItem>
      </div>
      <ContentNotFound v-else />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { computed } from 'vue'
import CustomItem from '@/dialog/components/templateGroup/CustomItem.vue'
import axios from '@/plugins/axios'
import { urlCorrespondenceDeleteUser } from '@/utils/urls'
import { openDialog } from '@/dialog'
import ContentNotFound from '@/components/ContentNotFound.vue'
import { setSnackbar } from 'best-modules/plugins'

export default {
  name: 'PerformerUsers',
  emits: ['update:correspondence'],
  components: { ContentNotFound, CustomItem },
  props: {
    correspondence: { type: Object, required: true },
    typeId: { type: Number },
  },
  setup(props, { emit }) {
    const typeText = computed(() => {
      return props.typeId === 2 ? 'Готував виконавець' : 'Одержувач'
    })
    const correspondenceData = computed({
      get() {
        return props.correspondence
      },
      set(v) {
        emit('update:correspondence', v)
      },
    })

    const openHandlerDialog = () => {
      if (!correspondenceData?.value?.departmentId) {
        return setSnackbar({
          text: `Оберіть спочатку ${typeText.value.toLowerCase()}`,
          color: 'warning',
        })
      }
      openDialog({
        name: 'PerformerUsers',
        dialogData: {
          title: typeText,
          departmentId: correspondenceData?.value?.departmentId,
        },
        params: {
          cb: users => correspondenceData.value.users.push(users),
        },
      })
    }

    // const handleAccessAddUser = () => {
    //   if (!selectedUser.value) {
    //     return setSnackbar({
    //       text: 'Спочатку оберіть виконавця та одержувача',
    //       color: 'error',
    //     })
    //   }
    //   handleCreateTaskUser(selectedUser.value)
    // }

    // const handleCreateTaskUser = user => {
    //   const obj = {
    //     correspondenceId: +route.params.id,
    //     userId: user?.id,
    //   }
    //   if (obj.userId && obj.correspondenceId) {
    //     return axios.post(urlCorrespondenceCreateUser(), obj).then(res => {
    //       correspondenceData.value.users.push(res.data)
    //       // selectedUser.value = null
    //       return res
    //     })
    //   }
    // }

    const handleRemoveUser = userId => {
      return axios.delete(urlCorrespondenceDeleteUser(userId)).then(() => {
        correspondenceData.value.users = correspondenceData.value.users.filter(
          c => c.id !== userId
        )
      })
    }

    return {
      typeText,
      openHandlerDialog,
      handleRemoveUser,
    }
  },
}
</script>

<style lang="scss" scoped></style>
