<script lang="ts">
export default {
  name: 'Xlsx',
}
</script>

<template>
  <svg
    fill="none"
    height="21"
    viewBox="0 0 18 21"
    width="18"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.31307 0C0.601824 0 0.0547112 0.601823 0.0547112 1.25836L0 19.2036C0 19.9149 0.547112 20.5167 1.25836 20.5167H16.6869C17.3982 20.5167 17.9453 19.9696 17.9453 19.2584L18 5.79939L12.2006 0H1.31307Z"
      fill="#4ADE80" />
    <path
      d="M17.9455 5.74468H13.4592C12.7479 5.74468 12.2008 5.19757 12.2008 4.48632V0L17.9455 5.74468Z"
      fill="#22C55E" />
    <path
      d="M5.84748 13.8846L5.29987 11.8819L4.74674 13.8846H4L4.91821 10.9011L4.05531 8.11538H4.79652L5.29987 9.94506L5.7977 8.11538H6.54443L5.67601 10.9011L6.59422 13.8846H5.84748Z"
      fill="white" />
    <path
      d="M8.89644 12.9203V13.8846H7.00471V8.11538H7.68507V12.9203H8.89644Z"
      fill="white" />
    <path
      d="M10.1325 14C9.7711 14 9.48531 13.8462 9.27512 13.5385C9.06862 13.2308 8.96352 12.8022 8.95983 12.2527H9.65679C9.66047 12.5055 9.70288 12.6951 9.78401 12.8214C9.86882 12.9478 9.98683 13.011 10.138 13.011C10.2892 13.011 10.4035 12.9533 10.481 12.8379C10.5584 12.717 10.5971 12.5604 10.5971 12.3681C10.5971 12.1648 10.5455 11.9918 10.4422 11.8489C10.339 11.7005 10.1804 11.5275 9.96654 11.3297C9.7711 11.1538 9.61069 10.9918 9.48531 10.8434C9.35994 10.6951 9.253 10.511 9.16449 10.2912C9.07968 10.0659 9.03727 9.8022 9.03727 9.5C9.03727 9.21429 9.08152 8.95879 9.17003 8.73352C9.25853 8.50275 9.38391 8.32418 9.54616 8.1978C9.7121 8.06593 9.90754 8 10.1325 8C10.4791 8 10.7502 8.14835 10.9456 8.44506C11.1447 8.74176 11.2461 9.14835 11.2498 9.66484H10.5529C10.5492 9.43956 10.5105 9.26923 10.4367 9.15385C10.3666 9.03846 10.2652 8.98077 10.1325 8.98077C10.0071 8.98077 9.90754 9.03022 9.83379 9.12912C9.76373 9.22802 9.72869 9.35989 9.72869 9.52473C9.72869 9.71703 9.77848 9.88187 9.87804 10.0192C9.97761 10.1511 10.1325 10.3104 10.3427 10.4973C10.5455 10.6731 10.7096 10.8352 10.835 10.9835C10.964 11.1264 11.0747 11.3159 11.1669 11.5522C11.259 11.783 11.3051 12.0577 11.3051 12.3764C11.3051 12.6841 11.259 12.9615 11.1669 13.2088C11.0747 13.4505 10.9401 13.6429 10.7631 13.7857C10.5861 13.9286 10.3759 14 10.1325 14Z"
      fill="white" />
    <path
      d="M13.2533 13.8846L12.7057 11.8819L12.1525 13.8846H11.4058L12.324 10.9011L11.4611 8.11538H12.2023L12.7057 9.94506L13.2035 8.11538H13.9502L13.0818 10.9011L14 13.8846H13.2533Z"
      fill="white" />
  </svg>
</template>

<style lang="scss" scoped></style>
