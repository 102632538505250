<template>
  <div>
    <div v-if="canSign" class="mb-2">
      <v-btn class="w-100" @click="signDocument"> Підписати </v-btn>
    </div>
    <div v-if="canCancel" class="mb-2">
      <v-btn class="w-100" color="error" @click="cancelDocument">
        Анулювати
      </v-btn>
    </div>
    <v-divider v-if="canSign || canCancel" class="mb-2"></v-divider>

    <SignatureStatus :document="document" />
  </div>
</template>

<script lang="ts">
import SignatureStatus from '@/components/signature/SignatureStatus.vue'
import { openDialog } from '@/dialog'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'DocumentSign',
  components: { SignatureStatus },
  emits: ['set-signature'],
  props: {
    document: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore()
    const userId = computed(() => store.state.user.userData?.id)

    // done
    const canSign = computed(() => {
      return (
        [1, 2].includes(props.document.statusId) &&
        props.document.users?.find(u => u.userId === userId.value)?.isSignature
      )
    })

    // done
    const canCancel = computed(() => {
      return (
        [1, 2].includes(props.document.statusId) &&
        props.document.authorId === userId.value
      )
    })

    const signDocument = () => {
      return openDialog({
        name: 'SignDocument',
        dialogData: {
          documents: [props.document.$object],
          entityType: 'user',
        },
        params: {
          hideActionButtons: true,
          cb: doc => {
            props.document.$set(doc.at(-1))
            // emit('set-signature', signatures)
          },
        },
      })
    }

    const cancelDocument = () => {
      return openDialog({
        name: 'DocumentCancel',
        dialogData: {
          documentId: props.document.id,
        },
        params: {
          cb: doc => {
            props.document.$set(doc)
          },
        },
      })
    }

    return { canCancel, cancelDocument, signDocument, canSign }
  },
}
</script>

<style scoped lang="scss"></style>
