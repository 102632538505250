<template>
  <div class="mb-3">
    <div v-if="canAgree">
      <v-btn
        v-if="isAuthor || document.status.id !== 2"
        class="w-100 mb-3"
        color="green"
        rounded="lg"
        @click="handleAgree">
        {{ isAuthor ? 'Відправити на погодження' : 'Погодити' }}
      </v-btn>
      <v-btn
        v-if="!isAuthor"
        class="cancelAgreedBtn"
        @click="openCancelAgreedDialog('revision')">
        Відправити на доопрацювання
      </v-btn>
    </div>
    <div v-else-if="document.status.id === 3">
      <v-checkbox
        :false-value="false"
        :model-value="true"
        :true-value="true"
        color="#35AB8F"
        hide-details
        readonly>
        <template #label>
          <span class="checkbox-label">
            {{ isAuthor ? 'Відправлено на погодження' : 'Погоджено' }}
          </span>
        </template>
      </v-checkbox>
      <v-btn
        v-if="isAuthor && !hasAgreedUsers"
        class="cancelAgreedBtn"
        @click="openCancelAgreedDialog('break')">
        Зупинити погодження
      </v-btn>
    </div>
  </div>
</template>

<script>
import { useDocumentAgree } from '../documentAgree.js'
import { computed } from 'vue'
import { useStore } from '@/plugins'

export default {
  props: {
    document: { type: Object, required: true },
  },
  setup(props) {
    const store = useStore()
    const isAuthorAgreed = computed(() => {
      return props.document?.authorId === store.state.user.userData?.id
    })
    const curRevisionValue = computed(() => {
      return !(!props.document.isAuthor && props.document.agreement?.isRevision)
    })
    return {
      ...useDocumentAgree(props.document),
      curRevisionValue,
      isAuthorAgreed,
    }
  },
}
</script>

<style lang="scss" scoped>
.cancelAgreedBtn {
  background: #ff8473 !important;
  border-radius: 4px;
  letter-spacing: -0.26px;
  font-size: 14px;
  width: 100%;
}
</style>
