<template>
  <div>
    <div v-for="s in clientSignatures" :key="s ? s.id : 1">
      <signature
        :contractor="s.contractor"
        :signature="s.signature"></signature>

      <v-divider class="mt-2 mb-2"></v-divider>
    </div>

    <div v-for="(s, idx) in userSignatures" :key="s ? s.id : 1">
      <signature
        :contractor="s.contractor"
        :signature="s.signature"></signature>

      <v-divider
        v-if="idx + 1 < userSignatures.length"
        class="mt-2 mb-2"></v-divider>
    </div>
  </div>
</template>

<script lang="ts">
import Signature from './Signature.vue'
import { computed } from 'vue'
import { getUserName } from '@/utils/helpers.js'
import { keepArray } from 'best-modules/utils'

export default {
  name: 'SignatureStatus',
  components: { Signature },
  props: {
    document: {
      type: Object,
    },
  },
  setup(props) {
    const userSignatures = computed(() => {
      const signatures = []
      if (props.document.users?.length) {
        props.document.users.forEach(u => {
          if (u.isSignature) {
            const contractor = {
              name: 'ТОВ "БЕСТ ЛІЗИНГ"',
              code: '33880354',
              signer: getUserName(u.user),
            }
            // signatures for current user
            const currentSignatures = keepArray(
              props.document.participants?.filter(s => {
                return s.entityTypeId === 6 && s.entityId === u.userId
              })
            ).map(s => {
              return {
                signature: s,
                contractor,
              }
            })
            if (currentSignatures.length) {
              signatures.push(...currentSignatures)
            } else {
              signatures.push({
                signature: null,
                contractor,
              })
            }
          }
        })
      }
      return signatures
    })
    const clientSignatures = computed(() => {
      if (
        !props.document?.contractors ||
        !Array.isArray(props.document.contractors)
      ) {
        return []
      }

      const signatures = []

      if (Array.isArray(props.document?.contractors)) {
        props.document.contractors.forEach(c => {
          const contractor = {
            name: c.contractor?.name || '',
            code: c.contractor?.code || '',
          }

          const currentSignatures = keepArray(
            props.document?.participants?.filter(
              s => s.entityTypeId === 5 && s.entityId === c.contractorId
            ) || []
          ).map(s => ({
            signature: s,
            contractor,
          }))

          if (currentSignatures.length) {
            signatures.push(...currentSignatures)
          } else {
            signatures.push({
              signature: null,
              contractor,
            })
          }
        })
      }

      return signatures
    })

    return { userSignatures, clientSignatures }
  },
}
</script>

<style lang="scss" scoped></style>
