<script lang="ts">
export default {
  name: 'AbsenceUserIndex',
}
</script>

<template>
  <div>
    <RouterView />
  </div>
</template>

<style lang="scss" scoped></style>
