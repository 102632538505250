<template>
  <div>
    <div>
      <div class="fields-container">
        <div class="d-flex flex-wrap gap-3 align-start">
          <b-input
            v-model="orderData.number"
            :error-messages="getErrorMessages(v$.number)"
            hide-details
            label="Номер наказу"
            placeholder="Введіть номер"
            style="flex-grow: 1; min-width: 16rem"
            @blur="v$.number.$touch()" />
          <BaseDatePicker
            v-model="orderData.date"
            :error-messages="getErrorMessages(v$.date)"
            class="flex-grow-1"
            hide-details
            label="Дата наказу"
            placeholder="Оберіть дату"
            style="min-width: 16rem"
            @blur="v$.date.$touch()" />
        </div>
        <b-server-autocomplete
          v-model="orderData.issuedUserId"
          :item-title="
            u =>
              `${
                u.secondName
                  ? getPersonName(u, { keyNames: { surname: 'secondName' } })
                  : u?.name
              } ${!u.isActive && u?.id ? '(Звільнений)' : ''}`
          "
          :search-min-length="3"
          :url="urlAllUsers()"
          has-empty
          hide-details
          item-value="id"
          label="Підготовив"
          placeholder="Введіть мін. 3 символи">
        </b-server-autocomplete>
        <div class="d-flex flex-wrap gap-3 align-start">
          <b-select
            v-model="orderData.typeId"
            :error-messages="getErrorMessages(v$.typeId)"
            :items="$directory.get('orderTypes')"
            :loading="$loading.isLoading('orderTypes')"
            class="flex-grow-1"
            hide-details
            item-title="name"
            item-value="id"
            label="Тип"
            placeholder="Оберіть зі списку"
            style="min-width: 16rem"
            @blur="v$.typeId.$touch()" />
          <b-select
            v-model="orderData.directionId"
            :error-messages="getErrorMessages(v$.directionId)"
            :items="$directory.get('orderDirections')"
            :loading="$loading.isLoading('orderDirections')"
            class="flex-grow-1"
            hide-details
            item-title="name"
            item-value="id"
            label="Напрямок"
            placeholder="Оберіть зі списку"
            style="min-width: 16rem"
            @blur="v$.directionId.$touch()" />
        </div>
        <b-select
          v-model="orderData.departmentId"
          :error-messages="getErrorMessages(v$.departmentId)"
          :items="$directory.get('departmentList')"
          :loading="$loading.isLoading('departmentList')"
          hide-details
          item-title="name"
          item-value="id"
          label="Департамент"
          placeholder="Оберіть зі списку"
          @blur="v$.departmentId.$touch()" />
        <FilesUploader
          v-model="orderData.document"
          :accept="['.pdf']"
          :v$="v$.document"
          dropper
          label="Файли наказу"
          main-folder="Приказы"
          multiple
          name-folder="template"
          placeholder="Оберіть дату"
          type="files" />
        <BaseTextarea
          v-model="orderData.description"
          :error-messages="getErrorMessages(v$.description)"
          hide-details
          label="Опис наказу"
          placeholder="Введіть текст"
          rows="2"
          @blur="v$.description.$touch()" />
      </div>
    </div>
  </div>
</template>

<script>
import { axios, minLength, required, useStore, useVuelidate } from '@/plugins'
import { computed, reactive } from 'vue'
import { urlAllUsers, urlCreateOrder } from '@/utils/urls.js'
import { getErrorMessages } from '@/utils/helpers'
import FilesUploader from '@/components/filesUploader/FilesUploader.vue'
import BaseDatePicker from '@/components/inputs/BaseDatePicker.vue'
import { setSnackbar } from 'best-modules/plugins'
import { cloneDeep } from 'lodash'
import { fillDirectory } from '@/directory/index.ts'
import { getPersonName } from 'best-modules/utils/helpers'
import { BServerAutocomplete } from 'best-modules/components/index'

export default {
  components: {
    BaseDatePicker,
    FilesUploader,
    BServerAutocomplete,
  },
  methods: { urlAllUsers, getPersonName, getErrorMessages },
  async setup() {
    const store = useStore()
    const userId = computed(() => store.state.user.userData?.id)

    await fillDirectory(['departmentList', 'orderTypes', 'orderDirections'])

    const orderData = reactive({
      number: null,
      date: null,
      document: [],
      description: null,
      issuedUserId: null,
      authorId: userId.value,
      departmentId: null,
      directionId: null,
      typeId: null,
    })

    const rules = computed(() => {
      return {
        number: { required },
        date: { required },
        document: { required, minLength: minLength(1) },
        description: { required },
        authorId: { required },
        typeId: { required },
        departmentId: { required },
        directionId: { required },
      }
    })
    const v$ = useVuelidate(rules, orderData)

    const submit = async () => {
      v$.value.$validate()
      if (!v$.value.$error) {
        const req = cloneDeep(orderData)

        const dateComponents = req.date.split('.')

        const formattedDate = `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`

        req.date = formattedDate + ' 00:00:00'

        return axios.post(urlCreateOrder(), req).then(res => {
          setSnackbar({ text: 'Наказ успішно створено' })

          return res
        })
      }
    }

    return {
      v$,
      orderData,
      submit,
    }
  },
}
</script>

<style lang="scss" scoped></style>
