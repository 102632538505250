<template>
  <svg
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="color"
      d="M10 7.5H14C14.2651 7.4997 14.5193 7.39424 14.7068 7.20677C14.8942 7.0193 14.9997 6.76512 15 6.5V2.5C14.9997 2.23488 14.8942 1.9807 14.7068 1.79323C14.5193 1.60576 14.2651 1.5003 14 1.5H10C9.73488 1.5003 9.4807 1.60576 9.29323 1.79323C9.10576 1.9807 9.0003 2.23488 9 2.5H4.5C4.23488 2.5003 3.9807 2.60576 3.79323 2.79323C3.60576 2.9807 3.5003 3.23488 3.5 3.5V5.5H2C1.73488 5.5003 1.4807 5.60576 1.29323 5.79323C1.10576 5.9807 1.0003 6.23488 1 6.5V10.5C1.0003 10.7651 1.10576 11.0193 1.29323 11.2068C1.4807 11.3942 1.73488 11.4997 2 11.5H3V13.5C3.0003 13.7651 3.10576 14.0193 3.29323 14.2068C3.4807 14.3942 3.73488 14.4997 4 14.5H9C9.0003 14.7651 9.10576 15.0193 9.29323 15.2068C9.4807 15.3942 9.73488 15.4997 10 15.5H14C14.2651 15.4997 14.5193 15.3942 14.7068 15.2068C14.8942 15.0193 14.9997 14.7651 15 14.5V10.5C14.9997 10.2349 14.8942 9.9807 14.7068 9.79323C14.5193 9.60576 14.2651 9.5003 14 9.5H10C9.73488 9.5003 9.4807 9.60576 9.29323 9.79323C9.10576 9.9807 9.0003 10.2349 9 10.5V13.5H4V11.5H6C6.26512 11.4997 6.5193 11.3942 6.70677 11.2068C6.89424 11.0193 6.9997 10.7651 7 10.5V6.5C6.9997 6.23488 6.89424 5.9807 6.70677 5.79323C6.5193 5.60576 6.26512 5.5003 6 5.5H4.5V3.5H9V6.5C9.0003 6.76512 9.10576 7.0193 9.29323 7.20677C9.4807 7.39424 9.73488 7.4997 10 7.5ZM10 14.5V12.5H14V14.5H10ZM14 10.5V11.5H10V10.5H14ZM2 10.5V8.5H6V10.5H2ZM6.00025 7.5H2V6.5H6L6.00025 7.5ZM10 6.5V4.5H14V6.5H10ZM14 2.5V3.5H10V2.5H14Z" />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'ConnectIcon',
  props: {
    color: { type: String, default: '#8E92BC' },
  },
}
</script>

<style lang="scss" scoped></style>
