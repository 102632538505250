<script lang="ts" setup>

</script>

<template>
  <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.25 9.11768H15V10.3677H16.25C16.7471 10.3682 17.2237 10.5659 17.5752 10.9175C17.9267 11.269 18.1245 11.7456 18.125 12.2427V14.7427H19.375V12.2427C19.374 11.4142 19.0445 10.6199 18.4586 10.034C17.8728 9.44818 17.0785 9.11864 16.25 9.11768Z"
      fill="#18181B" />
    <path
      d="M15 2.86768C15.3708 2.86768 15.7334 2.97764 16.0417 3.18367C16.35 3.3897 16.5904 3.68253 16.7323 4.02514C16.8742 4.36776 16.9113 4.74476 16.839 5.10847C16.7666 5.47218 16.588 5.80628 16.3258 6.0685C16.0636 6.33072 15.7295 6.5093 15.3658 6.58165C15.0021 6.654 14.6251 6.61686 14.2825 6.47495C13.9399 6.33304 13.647 6.09271 13.441 5.78437C13.235 5.47603 13.125 5.11352 13.125 4.74268C13.125 4.24539 13.3225 3.76848 13.6742 3.41685C14.0258 3.06522 14.5027 2.86768 15 2.86768ZM15 1.61768C14.3819 1.61768 13.7777 1.80095 13.2638 2.14433C12.7499 2.48771 12.3494 2.97577 12.1129 3.54679C11.8764 4.11781 11.8145 4.74614 11.935 5.35233C12.0556 5.95852 12.3533 6.51535 12.7903 6.95238C13.2273 7.38942 13.7842 7.68705 14.3903 7.80763C14.9965 7.92821 15.6249 7.86632 16.1959 7.6298C16.7669 7.39328 17.255 6.99274 17.5983 6.47883C17.9417 5.96493 18.125 5.36074 18.125 4.74268C18.125 3.91387 17.7958 3.11902 17.2097 2.53297C16.6237 1.94692 15.8288 1.61768 15 1.61768Z"
      fill="#18181B" />
    <path
      d="M14.375 19.1177H13.125V17.8677C13.1245 17.3706 12.9267 16.894 12.5752 16.5425C12.2237 16.1909 11.7471 15.9932 11.25 15.9927H8.75C8.25289 15.9932 7.77629 16.1909 7.42478 16.5425C7.07327 16.894 6.87555 17.3706 6.875 17.8677V19.1177H5.625V17.8677C5.62598 17.0392 5.95553 16.2449 6.54137 15.659C7.12721 15.0732 7.9215 14.7437 8.75 14.7427H11.25C12.0785 14.7437 12.8728 15.0732 13.4586 15.659C14.0445 16.2449 14.374 17.0392 14.375 17.8677V19.1177Z"
      fill="#18181B" />
    <path
      d="M10 8.49268C10.3708 8.49268 10.7334 8.60264 11.0417 8.80867C11.35 9.0147 11.5904 9.30753 11.7323 9.65014C11.8742 9.99276 11.9113 10.3698 11.839 10.7335C11.7666 11.0972 11.588 11.4313 11.3258 11.6935C11.0636 11.9557 10.7295 12.1343 10.3658 12.2066C10.0021 12.279 9.62508 12.2419 9.28247 12.0999C8.93986 11.958 8.64702 11.7177 8.44099 11.4094C8.23497 11.101 8.125 10.7385 8.125 10.3677C8.125 9.87039 8.32254 9.39348 8.67417 9.04185C9.02581 8.69022 9.50272 8.49268 10 8.49268ZM10 7.24268C9.38193 7.24268 8.77775 7.42595 8.26384 7.76933C7.74994 8.11271 7.3494 8.60077 7.11288 9.17179C6.87635 9.74281 6.81447 10.3711 6.93505 10.9773C7.05562 11.5835 7.35325 12.1403 7.79029 12.5774C8.22733 13.0144 8.78415 13.3121 9.39034 13.4326C9.99653 13.5532 10.6249 13.4913 11.1959 13.2548C11.7669 13.0183 12.255 12.6177 12.5983 12.1038C12.9417 11.5899 13.125 10.9857 13.125 10.3677C13.125 9.53887 12.7958 8.74402 12.2097 8.15797C11.6237 7.57192 10.8288 7.24268 10 7.24268Z"
      fill="#18181B" />
    <path
      d="M5 9.11768H3.75C2.9215 9.11865 2.12721 9.4482 1.54137 10.034C0.955529 10.6199 0.625976 11.4142 0.625 12.2427V14.7427H1.875V12.2427C1.87555 11.7456 2.07327 11.269 2.42478 10.9175C2.77629 10.5659 3.25289 10.3682 3.75 10.3677H5V9.11768Z"
      fill="#18181B" />
    <path
      d="M5 2.86768C5.37084 2.86768 5.73335 2.97764 6.04169 3.18367C6.35004 3.3897 6.59036 3.68253 6.73227 4.02514C6.87419 4.36776 6.91132 4.74476 6.83897 5.10847C6.76663 5.47218 6.58805 5.80628 6.32583 6.0685C6.0636 6.33072 5.72951 6.5093 5.36579 6.58165C5.00208 6.654 4.62508 6.61686 4.28247 6.47495C3.93986 6.33304 3.64702 6.09271 3.44099 5.78437C3.23497 5.47603 3.125 5.11352 3.125 4.74268C3.125 4.24539 3.32254 3.76848 3.67417 3.41685C4.02581 3.06522 4.50272 2.86768 5 2.86768ZM5 1.61768C4.38193 1.61768 3.77775 1.80095 3.26384 2.14433C2.74994 2.48771 2.3494 2.97577 2.11288 3.54679C1.87635 4.11781 1.81447 4.74614 1.93505 5.35233C2.05562 5.95852 2.35325 6.51535 2.79029 6.95238C3.22733 7.38942 3.78415 7.68705 4.39034 7.80763C4.99653 7.92821 5.62487 7.86632 6.19589 7.6298C6.7669 7.39328 7.25496 6.99274 7.59834 6.47883C7.94172 5.96493 8.125 5.36074 8.125 4.74268C8.125 3.91387 7.79576 3.11902 7.20971 2.53297C6.62366 1.94692 5.8288 1.61768 5 1.61768Z"
      fill="#18181B" />
  </svg>
</template>

<style lang="scss" scoped>

</style>
