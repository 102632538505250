<template>
  <div class="d-flex justify-end">
    <v-btn
      :loading="$loading.isLoading('taskPrintedForm')"
      rounded="lg"
      @click.stop="downloadPrintedForm">
      <v-icon class="mr-1">mdi-printer</v-icon>
      Експорт
    </v-btn>
  </div>
</template>

<script lang="ts">
import { urlTaskPrintedForm } from '@/utils/urls'
import { handleAsync } from 'best-modules/plugins'
import axios from '@/plugins/axios'
import { download } from 'best-modules/utils'
import { useRoute } from 'vue-router'

export default {
  name: 'PrintedForm',
  setup() {
    const route = useRoute()
    const getType = () => {
      switch (route.name) {
        case 'incoming-tasks':
          return 'incoming'
        case 'outgoing-tasks':
          return 'outgoing'
        case 'control-tasks':
          return 'control'
        case 'observer-tasks':
          return 'observer'
        default:
          throw new Error('Task printed form - invalid route.name')
      }
    }
    const downloadPrintedForm = () => {
      return handleAsync('taskPrintedForm', async () => {
        const { data } = await axios.get(urlTaskPrintedForm(getType()))

        return download(data.url, 'report_task')
      })
    }

    return { downloadPrintedForm }
  },
}
</script>

<style lang="scss" scoped></style>
