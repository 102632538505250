<template>
  <v-menu>
    <template #activator="{ isActive, props }">
      <v-btn size="x-small" v-bind="props">
        <DotsIcon v-if="icon" color="black" />
        <div v-else>
          Дії
          <v-icon>
            {{ isActive ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </div>
      </v-btn>
    </template>

    <v-list elevation="2" rounded="xs">
      <v-list-item
        v-for="(item, idx) in actions"
        :key="idx"
        :disabled="item.disabled"
        style="cursor: pointer"
        @click="item.action">
        <div class="d-flex align-center">
          <v-icon v-if="item.icon" class="mr-3" style="font-size: 15px"
            >{{ item.icon }}
          </v-icon>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import DotsIcon from '@/assets/images/icons/dots-icon.vue'

export default {
  name: 'ActionMenu',
  components: { DotsIcon },
  props: {
    actions: { type: Array },
    icon: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped></style>
