<template>
  <b-section-loader
    :loading="$loading.isLoading(['getDepartmentPersonRequest', 'userList'])">
    <div class="d-flex flex-column gap-1">
      <b-server-autocomplete
        v-model="searchValue"
        :search-min-length="3"
        :url="urlAllUsers()"
        append-inner-icon="mdi-magnify"
        hide-details
        placeholder="Пошук (введіть мін. 3 символи)"
        @update:model-value="pushUser" />

      <div v-if="selectedUsers.length" class="d-flex flex-column gap-2 my-2">
        <div
          v-for="item in $directory
            .get('userList')
            .filter(u => selectedUsers.includes(u.id as number))"
          :key="item.id"
          class="d-flex align-center gap-2">
          <BaseImage
            v-if="item?.photo || item?.user?.photo"
            :src="item.photo || item.user.photo"
            rounded
            width="30" />
          <v-avatar v-else class="cursor-pointer" color="primary" size="small">
            <v-icon icon="mdi-account-circle" />
          </v-avatar>
          <h5>
            {{
              getPersonName(item.user || item, {
                keyNames: { surname: 'secondName' },
              })
            }}
          </h5>
          <v-btn
            v-if="!dialog.dialogData.isSingle"
            density="comfortable"
            icon
            size="x-small"
            variant="text"
            @click="removeUser(item.id as number)">
            <v-icon color="primary" size="large">mdi-delete-forever</v-icon>
          </v-btn>
        </div>
      </div>

      <DepartmentTree
        v-for="item in treeItems"
        :key="item.id"
        :dialog="dialog"
        :handle-user="handleUser"
        :item="item"
        :level="0"
        :selected-user-value="selectedUserValue" />
    </div>
  </b-section-loader>
</template>

<script lang="ts">
import axios from '@/plugins/axios'
import { urlAllUsers, urlCompanyStructure } from '@/utils/urls'
import { computed, ref } from 'vue'
import { getPersonName } from 'best-modules/utils/helpers'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { BSectionLoader, BServerAutocomplete } from 'best-modules/components'
import { requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { fillDirectory, getDirectory } from '@/directory'
import { keepArray } from 'best-modules/utils'
import DepartmentTree from '@/components/task/DepartmentTree.vue'

export default {
  name: 'SelectDepartmentPerson',
  components: {
    DepartmentTree,
    BSectionLoader,
    BServerAutocomplete,
  },
  props: {
    dialog: { type: Object },
  },
  methods: { urlAllUsers, getPersonName },
  setup(props) {
    fillDirectory('userList')
    const searchValue = ref(null)
    const departmentPersonList = ref([])
    const usersToCreate = []
    const usersToDelete = []
    const selectedUsers = ref<number[]>(
      keepArray(props.dialog.dialogData.userIds)
    )

    const selectedUserValue = computed(() => {
      if (props.dialog.dialogData.isSingle) {
        return selectedUsers.value[0] || null
      } else {
        return selectedUsers.value
      }
    })

    const removeUser = (userId: number) => {
      selectedUsers.value = selectedUsers.value.filter(id => id !== userId)
      if (usersToCreate.includes(userId)) {
        const index = usersToCreate.findIndex(id => id === userId)
        usersToCreate.splice(index, 1)
      } else {
        usersToDelete.push(userId)
      }
    }

    const pushUser = (userId: number) => {
      if (props.dialog.dialogData.isSingle) {
        selectedUsers.value.forEach(id => {
          if (id !== userId) {
            removeUser(id)
          }
        })
      }
      selectedUsers.value.push(userId)
      if (usersToDelete.includes(userId)) {
        const index = usersToDelete.findIndex(id => id === userId)
        usersToDelete.splice(index, 1)
      } else {
        usersToCreate.push(userId)
      }
    }

    const handleUser = (userId: number) => {
      if (selectedUsers.value.includes(userId)) {
        removeUser(userId)
      } else {
        pushUser(userId)
      }
    }

    const getDepartmentPersonRequest = async () => {
      return handleAsync('getDepartmentPersonRequest', () => {
        return axios.get(urlCompanyStructure()).then(res => {
          departmentPersonList.value = res.data
        })
      })
    }

    const treeItems = computed(() => departmentPersonList.value)
    getDepartmentPersonRequest()

    const rules = {
      selectedUsers: {
        required: requiredIf(
          computed(() => !props.dialog.dialogData.canBeEmpty)
        ),
      },
    }

    const v$ = useVuelidate(rules, { selectedUsers })

    const submit = () => {
      v$.value.$validate()
      if (v$.value.$invalid) {
        return setSnackbar({ text: 'Користувача не обрано', color: 'error' })
      } else {
        return Promise.resolve({
          usersToCreate,
          usersToDelete,
          userIds: selectedUsers.value,
          users: getDirectory('userList').filter(u =>
            selectedUsers.value.includes(u.id as number)
          ),
        })
      }
    }

    return {
      getDepartmentPersonRequest,
      departmentPersonList,
      selectedUserValue,
      selectedUsers,
      treeItems,
      removeUser,
      handleUser,
      v$,
      submit,
      searchValue,
      pushUser,
    }
  },
}
</script>
