<template>
  <div>
    <v-card class="mt-3 shadow-1" rounded="lg">
      <v-card-title class="mt-1 mb-2">
        <h4>Засновники</h4>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="founders.length"
          :disable-pagination="true"
          :headers="contractorFounderListHeaders"
          :hide-default-header="!founders.length"
          :items="founders"
          :items-per-page="-1"
          hide-default-footer>
          <!-- eslint-disable-next-line -->
          <template #item.location="{ item }">
            <v-menu left offset-x>
              <template #activator="{ props }">
                <v-btn
                  color="transparent"
                  density="comfortable"
                  icon
                  size="small"
                  v-bind="props">
                  <v-icon color="secondary"
                    >mdi-map-marker-multiple-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-card class="cursor-default">
                <v-card-text>
                  <div class="d-flex align-center gap-1">
                    <span class="label mb-0">Країна резиденства:</span>
                    <span class="label mb-0">
                      {{ item.residentCountry?.name || '---' }}
                    </span>
                  </div>
                  <div class="d-flex align-center gap-1">
                    <span class="label mb-0">Країна реєстрації:</span>
                    <span class="label mb-0">
                      {{ item.registrationCountry?.name || '---' }}
                    </span>
                  </div>
                  <div class="d-flex align-center gap-1 mt-2">
                    <span class="label mb-0">Адреса:</span>
                    <span class="label mb-0">{{ item.location || '---' }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.amount="{ item }">
            <span>{{ rankSpacing(item.amount, { decimal: 2 }) }} грн</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.percent="{ item }">
            <span>
              {{
                rankSpacing(item.amountPercent, {
                  decimal: 6,
                  forceDecimal: true,
                })
              }}
              %
            </span>
          </template>
        </v-data-table>
        <content-not-found v-else></content-not-found>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import ContentNotFound from '@/components/ContentNotFound.vue'
import { rankSpacing } from 'best-modules/utils'
import { contractorFounderListHeaders } from '@/utils/tableHeaders'

export default {
  name: 'Founders',
  components: { ContentNotFound },
  props: {
    founders: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      contractorFounderListHeaders,
    }
  },
  methods: { rankSpacing },
}
</script>

<style lang="scss" scoped></style>
