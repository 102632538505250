<template>
  <div>
    <v-card class="mt-3 shadow-1" rounded="lg">
      <v-card-title class="mt-1 mb-2">
        <h4>Директор</h4>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="director"
          :disable-pagination="true"
          :headers="contractorDirectorListHeaders"
          :hide-default-header="!director"
          :items="[director]"
          :items-per-page="-1"
          hide-default-footer>
          <!-- eslint-disable-next-line -->
          <template #item.contact="{ item }">
            {{ getPersonName(item) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.address="{ item }">
            <v-menu left offset-x>
              <template #activator="{ props }">
                <v-btn
                  color="transparent"
                  density="comfortable"
                  icon
                  size="small"
                  v-bind="props">
                  <v-icon color="secondary"
                    >mdi-map-marker-multiple-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-card class="cursor-default">
                <v-card-text>
                  <div
                    v-for="(address, idx) in item.addresses || []"
                    :key="idx"
                    class="d-flex align-center gap-1">
                    <span class="label mb-0">{{ address?.type?.name }}</span>
                    <span class="label mb-0">
                      {{ getAddressStr(address) }}
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-data-table>
        <content-not-found v-else></content-not-found>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import ContentNotFound from '@/components/ContentNotFound.vue'
import { contractorDirectorListHeaders } from '@/utils/tableHeaders'
import { getPersonName } from 'best-modules/utils/helpers'
import { getAddressStr } from '@/utils/helpers'

export default {
  name: 'Director',
  components: { ContentNotFound },
  props: {
    director: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contractorDirectorListHeaders,
    }
  },
  methods: { getAddressStr, getPersonName },
}
</script>

<style lang="scss" scoped></style>
